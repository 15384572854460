import { IdType, UUIDType } from "@common/types/apiTypes";

const BASE_PATH = "/api/rhapsody";

export const rhapsodyUrls = {
  customer: {
    update: (customerId: IdType) => `${BASE_PATH}/customer/${customerId}/`,
  },
  ediProvider: {
    getAllowableInboundEDIActions: () =>
      "/api/edi-provider/allowable-inbound-actions/",
    triggerEdiGeneration: () => "/api/edi-provider/generate-edi/",
  },
  invoices: {
    compare: (id: IdType) =>
      `${BASE_PATH}/invoices/${id}/bill-explanation/compare`,
    factors: (id: IdType) =>
      `${BASE_PATH}/invoices/${id}/bill-explanation/factors`,
    retrieve: (id: IdType) => `${BASE_PATH}/invoices/${id}`,
    temperature: (id: IdType) =>
      `${BASE_PATH}/invoices/${id}/bill-explanation/temperature`,
    usage: (id: IdType) => `${BASE_PATH}/invoices/${id}/bill-explanation/usage`,
  },
  offerSnapshots: {
    list: () => `${BASE_PATH}/offer-snapshot/`,
    retrieve: (id: IdType) => `${BASE_PATH}/offer-snapshot/${id}/`,
  },
  productContractClaims: {
    BASE: `${BASE_PATH}/product-contract-claims` as const,
    downloadClaim: (productContractClaimId: UUIDType) =>
      `${rhapsodyUrls.productContractClaims.BASE}/${productContractClaimId}/download-claim/`,
    list: () => rhapsodyUrls.productContractClaims.BASE,
  },
  productContracts: {
    cancel: () => `${rhapsodyUrls.productContracts.list()}cancel/`,
    create: () => rhapsodyUrls.productContracts.list(),
    list: () => `${BASE_PATH}/product-contracts/`,
  },
  products: {
    availableForPremise: () => rhapsodyUrls.products.list(),
    list: () => `${BASE_PATH}/products/`,
  },
  prospects: {
    create: () => `${BASE_PATH}/prospects/`,
    creditScoreEvaluation: () =>
      `${BASE_PATH}/prospects/credit_score_evaluation/`,
    list: () => `${BASE_PATH}/prospects/`,
    premiseMatches: (prospectId: IdType) =>
      `${BASE_PATH}/prospects/${prospectId}/premise-matches`,
    retrieve: (id: IdType | UUIDType) => `${BASE_PATH}/prospects/${id}/`,
    update: (id: IdType) => rhapsodyUrls.prospects.retrieve(id),
  },
};
