import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { H2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { AddPremiseWizardForm } from "@ops/components/AddPremiseWizard/AddPremiseWizardForm";
import { AddPremiseWizardComponentName } from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import { BoCampaignSlugSelect } from "@ops/components/BoCampaignSlugSelect/BoCampaignSlugSelect";
import {
  EmptyOfferMessage,
  SelectContainer,
  SelectLabel,
} from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import { OfferCardRadioGroup } from "@ops/components/OfferCardRadioGroup/OfferCardRadioGroup";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import React, { PropsWithChildren, useState } from "react";
import styled from "styled-components";

const CenteredRow = styled(FormRow)`
  text-align: center;
`;
const Container = styled(BoFlexBox)`
  flex-direction: column;
  justify-content: center;
`;

interface OfferFormContainerProps {}

const OfferFormContainer = ({
  children,
}: PropsWithChildren<OfferFormContainerProps>) => {
  return (
    <Container>
      <CenteredRow>
        <H2>Choose a new plan</H2>
      </CenteredRow>
      {children}
    </Container>
  );
};

export const OfferForm = () => {
  const {
    wizardState: { dunsNumber, offerSnapshotId },
    updateWizardState,
    setActiveComponentName,
  } = useAddPremiseWizardContext();

  const [rcid, setRcid] = useState<CampaignSlugs>(CampaignSlugs.AddPremise);

  const flash = useRhFlash();

  const [selectedOfferSnapshotId, setSelectedOfferSnapshotId] =
    useState<IdType>(offerSnapshotId);

  const handleOverrideSlug = (slug: CampaignSlugs | null) => {
    setRcid(slug ?? CampaignSlugs.AddPremise);
  };

  if (!dunsNumber) {
    flash.error(
      "Unable to fetch offers. DUNS number is not found on new service address."
    );
    return <OfferFormContainer />;
  }

  const handleOfferSnapshotSelect = (offerSnapshot: OfferSnapshot) => {
    setSelectedOfferSnapshotId(offerSnapshot.id);
  };

  const onSubmit = () => {
    if (!selectedOfferSnapshotId) {
      // this should never happen. The "Next" button is disabled unless an offerSnapshot is selected
      flash.error("An offerSnapshot must be selected in order to proceed.");
      return;
    }

    updateWizardState({
      offerSnapshotId: selectedOfferSnapshotId,
    });
    setActiveComponentName(AddPremiseWizardComponentName.startDateForm);
  };

  return (
    <OfferFormContainer>
      <AddPremiseWizardForm
        onSubmit={onSubmit}
        hasValidationErrors={!selectedOfferSnapshotId}
      >
        <AccessControl>
          <SelectContainer>
            <SelectLabel id="override_campaign_slug">
              Override Campaign Slug
            </SelectLabel>
            <BoCampaignSlugSelect
              ariaLabelledby="override_campaign_slug"
              onChange={handleOverrideSlug}
              value={rcid}
            />
          </SelectContainer>
        </AccessControl>
        {dunsNumber ? (
          <OfferCardRadioGroup
            initialSelectedOfferSnapshotId={selectedOfferSnapshotId}
            onOfferSnapshotSelect={handleOfferSnapshotSelect}
            offerSnapshotListSearchParams={{
              dunsNumber,
              rcid,
            }}
          />
        ) : (
          <EmptyOfferMessage>No plans available.</EmptyOfferMessage>
        )}
      </AddPremiseWizardForm>
    </OfferFormContainer>
  );
};
