import {
  BoOptionsType,
  BoSelectInput,
  BoSelectOptionType,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { OfferSnapshotOrderOptions } from "@ops-models/types/offerSnapshotTypes";
import {
  SelectLabel,
  StyledContainer,
} from "@ops/components/OfferSnapshotFiltering/OfferSnapshotSorting/OfferSnapshotSorting.styled";
import React from "react";

interface BoSortOffersSelectProps {
  onChange: (orderOption: OfferSnapshotOrderOptions) => void;
  value?: string | null;
}

const SORT_OPTIONS = Object.entries(OfferSnapshotOrderOptions).map((entry) => {
  const [key, value] = entry;

  return {
    label: key,
    value,
  };
}) satisfies BoOptionsType;

export const OfferSnapshotSorting = ({
  value,
  onChange,
}: BoSortOffersSelectProps) => {
  const handleChange = (option: BoSelectOptionType | null) => {
    if (option && option?.value) {
      onChange(option.value as OfferSnapshotOrderOptions);
    } else {
      onChange(OfferSnapshotOrderOptions["Price: Low to High"]);
    }
  };

  return (
    <StyledContainer>
      <SelectLabel id="sortby_offers">Sort by</SelectLabel>
      <BoSelectInput
        ariaLabelledby="sortby_offers"
        value={value}
        options={SORT_OPTIONS}
        onChange={handleChange}
      />
    </StyledContainer>
  );
};
