import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface MailingAddressUpdatedData extends EventLogBaseData {
  fromAddress: string;
  toAddress: string;
}

export class MailingAddressUpdated extends EventLog {
  public readonly fromAddress: string;
  public readonly toAddress: string;

  constructor({
    fromAddress,
    toAddress,
    ...baseEventData
  }: MailingAddressUpdatedData) {
    super(baseEventData);

    this.fromAddress = fromAddress;
    this.toAddress = toAddress;
  }
  override get body() {
    return `${this.name} - Old Address: ${this.fromAddress} - New Address: ${this.toAddress}`;
  }
}
