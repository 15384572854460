import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ThermostatConnectedData extends EventLogBaseData {
  planOverridesDeviceCredit: string;
  rhythmDeviceId: string;
}

export class ThermostatConnected extends EventLog {
  public readonly planOverridesDeviceCredit: string;
  public readonly rhythmDeviceId: string;

  constructor({
    planOverridesDeviceCredit,
    rhythmDeviceId,
    ...baseEventData
  }: ThermostatConnectedData) {
    super(baseEventData);
    this.planOverridesDeviceCredit = planOverridesDeviceCredit;
    this.rhythmDeviceId = rhythmDeviceId;
  }

  override get body() {
    return `${this.name}
    Rhythm Device ID: ${this.rhythmDeviceId}
    Plan Credit Eligible: ${this.planOverridesDeviceCredit}`;
  }
}
