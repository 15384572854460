import { fampApi } from "@common/api/fampApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isAfterDate, isRequired } from "@common/forms/validators";
import { UtilityType } from "@common/types/ediTypes";
import { CreateFampMessageResponseType } from "@common/types/fampTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import {
  BoSelectOptionType,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Body1, H1 } from "@ops-design-system/components/Typography/Typography";
import {
  DatesBox,
  DatesTitleContainer,
  FampCreateMessageFormContainer,
  FormFieldBox,
} from "@ops/components/FampCreateMessageForm/FampCreateMessageForm.styled";
import React from "react";
import { Form } from "react-final-form";

interface FampCreateMessageFormProps {
  onCreateFampMessageSuccess(response: CreateFampMessageResponseType): void;
  utilities: UtilityType[];
}

interface CreateBatchValuesForm {
  endDate: string;
  startDate: string;
  utility: BoSelectValue;
}

const createBatchFormValidator =
  generateValidationErrorCollector<CreateBatchValuesForm>({
    endDate: [
      (endDate: string, { startDate }: CreateBatchValuesForm) => {
        if (endDate) {
          return isAfterDate(endDate, startDate);
        }
      },
    ],
    startDate: [isRequired],
    utility: [isRequired],
  });

export const FampCreateMessageForm = ({
  onCreateFampMessageSuccess,
  utilities,
}: FampCreateMessageFormProps) => {
  const flash = useRhFlash();

  const onSubmit = async (data: CreateBatchValuesForm) => {
    const formData = {
      endDate: data.endDate,
      startDate: data.startDate,
      utility: String(data.utility),
    };
    const [error, response] = await handleAjaxCall(
      fampApi.createMessage(formData)
    );

    if (error) {
      flash.error(
        "Error creating message. Most likely due to overlapping days on existing messages"
      );
    }
    if (response) {
      onCreateFampMessageSuccess(response);
    }
  };

  const utilityOptions: BoSelectOptionType[] = utilities.map((utility) => ({
    label: utility.name,
    value: utility.id as unknown as string,
  }));

  utilityOptions.unshift({ label: "", value: "" });

  return (
    <Form<CreateBatchValuesForm>
      onSubmit={onSubmit}
      validate={createBatchFormValidator}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FampCreateMessageFormContainer>
            <FormFieldBox>
              <H1>Batch Details</H1>
            </FormFieldBox>
            <FormFieldBox>
              <BoSelectField
                name="utility"
                label={<Body1>TDSP</Body1>}
                options={utilityOptions}
              />
            </FormFieldBox>
            <FormFieldBox>
              <DatesTitleContainer>
                <Body1>Invoice Due Date - Range</Body1>
              </DatesTitleContainer>
              <DatesBox>
                <BoDateField name="startDate" label="Start Date" />
                <BoDateField name="endDate" label="End Date" />
              </DatesBox>
            </FormFieldBox>
            <BoButton type="submit" disabled={invalid}>
              Submit
            </BoButton>
          </FampCreateMessageFormContainer>
        </form>
      )}
    />
  );
};
