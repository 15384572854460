import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { useState } from "react";

interface ContractFormStateArgs {
  defaultSlug: CampaignSlugs;
}

export const useContractFormState = ({
  defaultSlug,
}: ContractFormStateArgs) => {
  const [selectedOfferSnapshot, setSelectedOfferSnapshot] =
    useState<OfferSnapshot | null>(null);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false);
  const [rcid, setRcid] = useState<CampaignSlugs>(defaultSlug);

  const handleOverrideSlug = (slug: CampaignSlugs | null) => {
    setRcid(slug ?? CampaignSlugs.InboundTelemarketingRenewal);
  };

  const handleOfferSnapshotSelected = (offerSnapshot: OfferSnapshot) => {
    setSelectedOfferSnapshot(offerSnapshot);
  };

  const handleToggleTermsCheckbox = () => {
    setAgreedToTerms((prevAgreedToTerms) => !prevAgreedToTerms);
  };

  const reset = () => {
    setSelectedOfferSnapshot(null);
    setAgreedToTerms(false);
    setRcid(defaultSlug);
  };

  return {
    agreedToTerms,
    handleOfferSnapshotSelected,
    handleOverrideSlug,
    handleToggleTermsCheckbox,
    rcid,
    reset,
    selectedOfferSnapshot,
  };
};
