import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as XIcon } from "@ops-design-system/icons/XIcon.svg";
import { OfferSnapshotSearchOptions } from "@ops-models/types/offerSnapshotTypes";
import {
  planTypeMapping,
  termLengthMapping,
  tiersMapping,
} from "@ops/components/OfferSnapshotFiltering/offerSnapshotFiltering.constants";
import {
  StyledFilterLabel,
  StyledFilters,
} from "@ops/components/OfferSnapshotFiltering/OfferSnapshotFiltering.styled";
import React from "react";

interface BoFilterButtonsProps {
  filters: OfferSnapshotSearchOptions;
  setFilters: (values: OfferSnapshotSearchOptions) => void;
}

interface ButtonContentProps {
  name: string;
}
const ButtonContent = (props: ButtonContentProps) => {
  const { name } = props;

  return (
    <StyledFilterLabel>
      <XIcon color="primary" aria-hidden />
      <Body1>{name}</Body1>
    </StyledFilterLabel>
  );
};

export const ActiveFilters = ({
  filters,
  setFilters,
}: BoFilterButtonsProps) => {
  return (
    <StyledFilters>
      {filters.offerLabels
        ? filters.offerLabels?.map((offerLabel, index) => {
            return (
              <BoButton
                variant="secondary"
                key={offerLabel}
                onClick={() =>
                  setFilters({
                    ...filters,
                    offerLabels: filters.offerLabels?.filter(
                      (val) => val !== offerLabel
                    ),
                  })
                }
              >
                <ButtonContent name={tiersMapping[offerLabel]} />
              </BoButton>
            );
          })
        : null}

      {filters.termMonths
        ? filters.termMonths?.map((termMonth, index) => {
            return (
              <BoButton
                variant="secondary"
                key={termMonth}
                onClick={() =>
                  setFilters({
                    ...filters,
                    termMonths: filters.termMonths?.filter(
                      (val) => val !== termMonth
                    ),
                  })
                }
              >
                <ButtonContent name={termLengthMapping[termMonth]} />
              </BoButton>
            );
          })
        : null}

      {filters.planType
        ? filters.planType?.map((plan, index) => {
            return (
              <BoButton
                variant="secondary"
                key={plan}
                onClick={() =>
                  setFilters({
                    ...filters,
                    planType: filters.planType?.filter((val) => val !== plan),
                  })
                }
              >
                <ButtonContent name={planTypeMapping[plan]} />
              </BoButton>
            );
          })
        : null}
    </StyledFilters>
  );
};
