import { EM_DASH } from "@common/constants/characters.constant";
import { CustomerStatus } from "@common/enums/customer.enum";
import { StatusIndicatorLevel } from "@common/types/statusIndicatorTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { ProspectValidationReport } from "@ops-models/models/ProspectValidationReport.model";
import { StatusIndicator } from "@ops/components/StatusIndicator/StatusIndicator";
import { ValidationReportCriteriaList } from "@ops/components/SupportLogs/ValidationReport/ValidationReportCriteriaList";
import {
  AmountStyle,
  LayoutContainer,
  PastDueStyle,
  PremiseStatus,
  PremiseStatusContainer,
  ValidationReportColumn,
} from "@ops/components/SupportLogs/ValidationReport/ValidationReportEntry.styled";
import { premiseCustomerPath } from "@ops/routes/routePaths";
import React from "react";

interface ValidationLogProps {
  premiseMatch: ProspectValidationReport;
}

const openCustomerInNewTab = (premiseMatch: ProspectValidationReport) => {
  const path = premiseCustomerPath(
    premiseMatch.customer.id,
    premiseMatch.premise.id
  );

  window.open(path, "_blank");
};

const customerStatusType = {
  [CustomerStatus.ACTIVE]: StatusIndicatorLevel.SUCCESS,
  [CustomerStatus.INACTIVE]: StatusIndicatorLevel.ALERT,
  [CustomerStatus.PENDING]: StatusIndicatorLevel.WARN,
};

export function ValidationReportEntry({
  premiseMatch,
}: ValidationLogProps): JSX.Element {
  const serviceAddress = premiseMatch.premise?.serviceAddress
    ? `${premiseMatch.premise.serviceAddress.deliveryLine1} ${premiseMatch.premise.serviceAddress.lastLine}`
    : EM_DASH;

  const esiId = stringOrEmDash(premiseMatch.premise?.esiId);
  const serviceEndDate = stringOrEmDash(premiseMatch.premise?.serviceEndDate);
  const billDue = stringOrEmDash(
    formatCurrency(premiseMatch.premise?.billDue, 2)
  );
  const pastDue = stringOrEmDash(
    formatCurrency(premiseMatch.premise?.pastDueBalance, 2)
  );

  return (
    <LayoutContainer>
      <PremiseStatusContainer
        $status={premiseMatch.premiseMatchCollisionStatusLevel}
      >
        <PremiseStatus $status={premiseMatch.premiseMatchCollisionStatusLevel}>
          {premiseMatch.premiseMatchCollisionStatusLevel}
        </PremiseStatus>
        Existing Customer Found
      </PremiseStatusContainer>
      <BoGrid $variant={GridVariants.Grid1_1}>
        <ValidationReportColumn>
          <BoInfoLabel>Customer ID</BoInfoLabel>
          <BoInfoValue>{premiseMatch.customer.id}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoButton
            variant="secondary"
            size="extraSmall"
            onClick={() => openCustomerInNewTab(premiseMatch)}
          >
            View Account
          </BoButton>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Billing account number</BoInfoLabel>
          <BoInfoValue>
            {stringOrEmDash(premiseMatch.premise.billingAccountNumber)}
          </BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Premise ID</BoInfoLabel>
          <BoInfoValue>{stringOrEmDash(premiseMatch.premise.id)}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Customer Name</BoInfoLabel>
          <BoInfoValue>
            {`${premiseMatch.customer.firstName} ${premiseMatch.customer.lastName}`}
          </BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>DOB</BoInfoLabel>
          <BoInfoValue>{premiseMatch.customer.dateOfBirth}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Email Address</BoInfoLabel>
          <BoInfoValue>{premiseMatch.customer.email}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Phone Number</BoInfoLabel>
          <BoInfoValue>{premiseMatch.customer.phone}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>ESIID</BoInfoLabel>
          <BoInfoValue>{esiId}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Status</BoInfoLabel>
          <BoInfoValue>
            <StatusIndicator
              status={
                customerStatusType[
                  premiseMatch.premise.status as CustomerStatus
                ]
              }
            >
              {premiseMatch.premise.status}
            </StatusIndicator>
          </BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Service Address</BoInfoLabel>
          <BoInfoValue>{serviceAddress}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Service End Date</BoInfoLabel>
          <BoInfoValue>{serviceEndDate}</BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Bill Due</BoInfoLabel>
          <BoInfoValue>
            <AmountStyle>{billDue}</AmountStyle>
          </BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Past Due</BoInfoLabel>
          <BoInfoValue>
            <AmountStyle>
              <PastDueStyle
                $pastDue={Number(premiseMatch.premise.pastDueBalance) > 0}
              >
                {pastDue}
              </PastDueStyle>
            </AmountStyle>
          </BoInfoValue>
        </ValidationReportColumn>
        <ValidationReportColumn>
          <BoInfoLabel>Enrollment Validation Errors</BoInfoLabel>
          <BoInfoValue>
            <ValidationReportCriteriaList
              validationErrorList={premiseMatch.matchCriteria}
              validationStatus={premiseMatch.premiseMatchCollisionStatusLevel}
            />
          </BoInfoValue>
        </ValidationReportColumn>
      </BoGrid>
    </LayoutContainer>
  );
}
