import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { grey } from "@ops-design-system/styles/palette.colors";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React from "react";
import styled from "styled-components";

const StyledLabel = styled(Body3).attrs({ as: "div" })`
  color: ${grey["900"]};
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: 2px;
`;
const StyledInlineLabel = styled(StyledLabel).attrs({ as: "span" })``;

const StyledValue = styled(Body2).attrs({ as: "div" })`
  color: ${grey["600"]};
`;

const StyledInlineValue = styled(StyledValue).attrs({ as: "span" })``;

const Container = styled(BoCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1.5)};
`;

export const CustomerInfoSideBar = () => {
  const { premise } = useTransferServiceWizardContext();

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offersnapshotId),
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <Container>
        <BoCircularProgress />
      </Container>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <Container>
        <Body2>Error fetching offer details</Body2>
      </Container>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  return (
    <Container>
      <div>
        <StyledLabel>Current Address</StyledLabel>
        <StyledValue>
          <div>{premise.serviceAddress?.line1}</div>
          <div>{premise.serviceAddress?.line2}</div>
        </StyledValue>
      </div>
      <div>
        <StyledLabel>Current Plan</StyledLabel>
        <StyledValue>{offerSnapshot.title}</StyledValue>
        <StyledValue>
          {offerSnapshot.isTimeOfUse ? (
            <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
          ) : (
            formatEnergyRate(offerSnapshot.energyRate())
          )}
        </StyledValue>
        <StyledValue>{`${offerSnapshot.termMonths} months,${
          offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
        } Rate`}</StyledValue>
      </div>
      <div>
        <div>
          <StyledInlineLabel>Start Date: </StyledInlineLabel>
          <StyledInlineValue>
            {stringOrEmDash(
              formatMonthDayYear(premise.currentOrder?.startDate)
            )}
          </StyledInlineValue>
        </div>
        <div>
          <StyledInlineLabel>End Date: </StyledInlineLabel>
          <StyledInlineValue>
            {stringOrEmDash(
              formatMonthDayYear(premise.currentOrder?.contractEndDate)
            )}
          </StyledInlineValue>
        </div>
      </div>
      <div>
        <div>
          <StyledInlineLabel>Paperless: </StyledInlineLabel>
          <StyledInlineValue>{onOff(premise.isPaperless)}</StyledInlineValue>
        </div>
        <div>
          <StyledInlineLabel>Autopay: </StyledInlineLabel>
          <StyledInlineValue>{onOff(premise.autopay)}</StyledInlineValue>
        </div>
      </div>
    </Container>
  );
};
