import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DisconnectNoticeCreationData extends EventLogBaseData {
  disconnectionDate: string;
  pastDueBalance: string;
}

export class DisconnectNoticeCreation extends EventLog {
  public disconnectionDate: string;
  public pastDueBalance: string;

  constructor({
    pastDueBalance,
    disconnectionDate,
    ...baseEventData
  }: DisconnectNoticeCreationData) {
    super(baseEventData);
    this.pastDueBalance = pastDueBalance;
    this.disconnectionDate = disconnectionDate;
  }

  override get body() {
    return `DNP Letter Sent - Amount ${formatCurrency(
      this.pastDueBalance
    )} - Expiration Date ${formatMonthDayYear(this.disconnectionDate)}`;
  }
}
