import { fampApi } from "@common/api/fampApi";
import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { RHYTHM_DUNS } from "@common/constants/other.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { UtilityType } from "@common/types/ediTypes";
import {
  CreateFampMessageResponseType,
  FampPaymentMethod,
} from "@common/types/fampTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoSelectValue } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import {
  Body1,
  H1,
  H3,
} from "@ops-design-system/components/Typography/Typography";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Form } from "react-final-form";

import {
  FampSubmitDetailsContainer,
  SubmitDetailsBox,
  SubmitDetailsDateBox,
  SubmitDetailsSection,
} from "./FampSubmitDetails.styled";

interface FampSubmitDetailsFormProps {
  fampMessage: CreateFampMessageResponseType;
  utilities: UtilityType[];
}

interface SubmitDetailsForm {
  intendedSettlementDate: string;
  paymentMethod: BoSelectValue;
}

const createBatchFormValidator =
  generateValidationErrorCollector<SubmitDetailsForm>({
    intendedSettlementDate: [isRequired],
    paymentMethod: [isRequired],
  });

const csvDownload = async (id: number) => {
  const blob = await fampApi.download(id);
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");

  link.href = url;
  const fileName = `FampMessageINVLs${id}_${dayjs().format(
    ISO8601_DATE_FORMAT
  )}.csv`;

  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const FampSubmitDetails = ({
  fampMessage,
  utilities,
}: FampSubmitDetailsFormProps) => {
  const [didSucceed, setDidSucceed] = useState<boolean>(false);
  const [didFail, setDidFail] = useState<boolean>(false);
  const [isGeneratingFamp, setIsGeneratingFamp] = useState<boolean>(false);

  const utility = utilities.find((u) => {
    return u.id === fampMessage.utility;
  });

  if (!utility) {
    return null;
  }

  async function submitFamp(data: SubmitDetailsForm) {
    setIsGeneratingFamp(true);
    const formData = {
      intendedSettlementDate: data.intendedSettlementDate,
      paymentMethod: data.paymentMethod as FampPaymentMethod,
    };
    const [error] = await handleAjaxCall(
      fampApi.generateAndSend(fampMessage.id, formData)
    );

    setIsGeneratingFamp(false);

    if (error) {
      setDidSucceed(false);
      setDidFail(true);
    } else {
      setDidSucceed(true);
      setDidFail(false);
    }
  }

  if (isGeneratingFamp) {
    return <H1>Submitting FAMP...</H1>;
  }

  if (didFail) {
    return (
      <H1>
        Whoops! Something went wrong. Please reach out to the Engineering team
      </H1>
    );
  } else if (didSucceed) {
    return <H1>SUCCESS! FAMP Was successfully sent</H1>;
  }

  return (
    <FampSubmitDetailsContainer>
      <SubmitDetailsSection>
        <H1>Famp Transaction Details</H1>
        <BoButton
          color="primary"
          size="medium"
          onClick={() => csvDownload(fampMessage.id)}
        >
          Download
        </BoButton>
      </SubmitDetailsSection>
      <SubmitDetailsSection>
        <div>
          <H3>Invoice Due Date - Range</H3>
          <Body1>
            {fampMessage.startDate} - {fampMessage.endDate}
          </Body1>
        </div>
        <div>
          <H3>Sender DUNS</H3>
          <Body1>{RHYTHM_DUNS}</Body1>
        </div>
        <div>
          <H3>Receiver DUNS</H3>
          <Body1>{fampMessage.utilityDuns}</Body1>
        </div>
        <div />
        <div>
          <H3>Sender Name</H3>
          <Body1>RHYTHM ENERGY</Body1>
        </div>
        <div>
          <H3>Receiver Name</H3>
          <Body1>{utility.name}</Body1>
        </div>
      </SubmitDetailsSection>
      <SubmitDetailsSection>
        <H1>FAMP Financial Details</H1>
      </SubmitDetailsSection>
      <SubmitDetailsSection>
        <div>
          <H3>Count of Invoices</H3>
          <Body1>{fampMessage.totalInvoices}</Body1>
        </div>
        <div>
          <H3>Distribution Amount</H3>
          <Body1>{formatCurrency(fampMessage.totalDistributionAmount)}</Body1>
        </div>
        <div>
          <H3>Trace Number</H3>
          <Body1>{fampMessage.traceNumber}</Body1>
        </div>
      </SubmitDetailsSection>
      <SubmitDetailsSection>
        <H1>FAMP Payment Details</H1>
      </SubmitDetailsSection>
      <Form<SubmitDetailsForm>
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={submitFamp}
        validate={createBatchFormValidator}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit}>
            <SubmitDetailsBox>
              <BoSelectField
                name="paymentMethod"
                label={<Body1>Payment Method</Body1>}
                ariaLabel="Payment Method"
                options={[
                  { label: "", value: FampPaymentMethod.NONE },
                  { label: "ACH", value: FampPaymentMethod.ACH },
                  { label: "FWT", value: FampPaymentMethod.FWT },
                ]}
              />
            </SubmitDetailsBox>
            <SubmitDetailsDateBox>
              <BoDateField
                name="intendedSettlementDate"
                label="Payment Settlement Date"
              />
            </SubmitDetailsDateBox>
            <BoButton type="submit" disabled={invalid}>
              Confirm Details + Submit Famp
            </BoButton>
          </form>
        )}
      />
    </FampSubmitDetailsContainer>
  );
};
