import {
  BoTextAreaContainer,
  StyledBoTextArea,
  StyledTextAreaError,
} from "@ops-design-system/components/BoTextArea/BoTextArea.styled";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import React, { FC } from "react";

interface BoTextAreaProps {
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  hasErrors?: boolean;
  name: string;
  placeholder?: string;
  requiredIndicator?: boolean;
}

export const BoTextArea: FC<React.PropsWithChildren<BoTextAreaProps>> = ({
  children,
  name,
  placeholder,
  errorMessage,
  hasErrors,
  requiredIndicator,
  ...rest
}) => {
  const id = `${name}-textarea`;

  return (
    <BoTextAreaContainer>
      <BoLabel htmlFor={id} requiredIndicator={requiredIndicator}>
        {children}
      </BoLabel>
      <StyledBoTextArea
        id={id}
        name={name}
        placeholder={placeholder}
        $hasErrors={hasErrors}
        {...rest}
      />
      {errorMessage && (
        <StyledTextAreaError>{errorMessage}</StyledTextAreaError>
      )}
    </BoTextAreaContainer>
  );
};
