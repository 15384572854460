import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CancelRebillInvoiceDeletedData extends EventLogBaseData {
  cancelRebillMatchId: string;
  invoiceId: string;
  invoiceNumber: string;
}

export class CancelRebillInvoiceDeleted extends EventLog {
  public readonly cancelRebillMatchId: string;
  public readonly invoiceId: string;
  public readonly invoiceNumber: string;

  constructor({
    cancelRebillMatchId,
    invoiceId,
    invoiceNumber,
    ...baseEventData
  }: CancelRebillInvoiceDeletedData) {
    super(baseEventData);

    this.cancelRebillMatchId = cancelRebillMatchId;
    this.invoiceId = invoiceId;
    this.invoiceNumber = invoiceNumber;
  }

  override get body() {
    return `${this.name} - Invoice Number: ${this.invoiceNumber} - Invoice ID: ${this.invoiceId} - Cancel Rebill Match ID: ${this.cancelRebillMatchId}`;
  }
}
