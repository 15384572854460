import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { KwhUsageSummaryType } from "@ops-models/types/usageTypes";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseKwhUsageOptions {
  enabled: boolean;
  premiseId: IdType;
}

export const usePremiseKwhUsageSummary = (
  options: UsePremiseKwhUsageOptions
) => {
  const { premiseId, enabled } = options;

  const query = useQuery<KwhUsageSummaryType, RhApiError>({
    enabled,
    queryFn: () => rhapsodyPremiseApi.kwhUsageSummary(premiseId),
    queryKey: PremiseKeys.kwhUsageSummary(premiseId),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });

  return query;
};
