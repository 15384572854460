import { createRhContext } from "@common/utils/contextHelpers";
import { ProspectType } from "@ops-models/types/prospectTypes";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { getTabFromLocation } from "@ops/utils/getTabFromLocation";
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface EnrollmentFlowStateProviderProps {
  prospect: ProspectType;
}

export interface EnrollmentFlowStateProviderContext {
  currentTabComponent: TabType;
  prospect: ProspectType;
  setCurrentTabComponent: (component: TabType) => void;
  updateProspectState: (payload: Partial<ProspectType>) => void;
}

export const EnrollmentFlowStateContext =
  createRhContext<EnrollmentFlowStateProviderContext>();

export const EnrollmentFlowStateProvider = ({
  children,
  prospect,
}: PropsWithChildren<EnrollmentFlowStateProviderProps>) => {
  const location = useLocation();
  const initHash = getTabFromLocation<TabType>(location);
  const [currentTabComponent, setTabComponent] = useState<TabType>(
    initHash || TabType.marketing
  );
  const [prospectState, setProspectState] = useState<ProspectType>(prospect);

  const setCurrentTabComponent = (component: TabType) => {
    setTabComponent(component);
  };
  const updateProspectState = useCallback(
    (payload: Partial<ProspectType>) => {
      setProspectState({ ...prospectState, ...payload });
    },
    [prospectState]
  );

  const actions: EnrollmentFlowStateProviderContext = useMemo(() => {
    return {
      currentTabComponent,
      prospect: prospectState,
      setCurrentTabComponent,
      updateProspectState,
    };
  }, [currentTabComponent, prospectState, updateProspectState]);

  return (
    <EnrollmentFlowStateContext.Provider value={actions}>
      {children}
    </EnrollmentFlowStateContext.Provider>
  );
};
