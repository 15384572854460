import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CreditCardExpiringSoonData extends EventLogBaseData {
  creditCardMaskNumber: string;
  daysUntilExpired: string;
}

export class CreditCardExpiringSoon extends EventLog {
  public readonly creditCardMaskNumber: string;
  public readonly daysUntilExpired: string;

  constructor({
    creditCardMaskNumber,
    daysUntilExpired,
    ...baseEventData
  }: CreditCardExpiringSoonData) {
    super(baseEventData);

    this.creditCardMaskNumber = creditCardMaskNumber;
    this.daysUntilExpired = daysUntilExpired;
  }

  override get body() {
    return `${this.name} - Default Credit Card: ${this.creditCardMaskNumber} expires in ${this.daysUntilExpired} days`;
  }
}
