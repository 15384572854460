import { IdType } from "@common/types/apiTypes";
import { selectPremiseState } from "@ops-redux/selectors/premise.selectors";
import { premiseFetch } from "@ops-redux/slices/premiseSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePremise = (premiseId: IdType | null) => {
  const { premise, error, status } = useSelector(selectPremiseState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (premiseId && premise?.id !== premiseId && !status.isPending) {
      dispatch(premiseFetch(premiseId));
    }
  }, [premiseId]);

  return {
    error,
    premise,
    requestMonitor: status,
  };
};
