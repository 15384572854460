import { api } from "@common/api/api";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidPhoneNumber } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { RhApiError } from "@common/types/errorTypes";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoPhoneField } from "@ops-design-system/components/BoPhoneField/BoPhoneField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { customerRefetch } from "@ops-redux/slices/customerSlice";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface EditPhoneProps {
  customer: Customer;
}

interface EditPhoneFormValues {
  phone: string;
  secondaryPhone: string | null;
}

const editPhoneFormValidators =
  generateValidationErrorCollector<EditPhoneFormValues>({
    phone: [isValidPhoneNumber, isRequired],
    secondaryPhone: [isValidPhoneNumber],
  });

const PhoneDetailsContainer = styled.div`
  ${editDialogContentsCss}
`;

export const EditPhone = ({
  customer: { id, phone, secondaryPhone },
}: EditPhoneProps) => {
  const { open, close, isOpen } = useModalState();

  const [{ requestMonitor }, { setFailure, setPending, setSuccess }] =
    useAjaxState<boolean>();

  const dispatch = useDispatch();
  const flash = useRhFlash();

  const handleSubmit = (formValues: EditPhoneFormValues) => {
    setPending();

    api.customers
      .update(id, {
        phone: formValues.phone,
        secondaryPhone: formValues.secondaryPhone || null,
      })
      .then(() => {
        setSuccess(true);
        dispatch(customerRefetch(id));
        flash.success("Customer phone number successfully updated.");
        close();
      })
      .catch((error: RhApiError) => {
        setFailure();
        flash.error(error.data.errors.map(({ detail }) => detail).join(", "));
      });
  };

  return (
    <>
      <EditLink data-testid="phonePencil" onClick={open} />

      <BoModal open={isOpen} onClose={close}>
        <Form<EditPhoneFormValues>
          onSubmit={handleSubmit}
          initialValues={{ phone, secondaryPhone }}
          validate={editPhoneFormValidators}
          render={({
            handleSubmit: handleFormSubmit,
            dirty,
            hasValidationErrors,
          }) => (
            <form noValidate onSubmit={handleFormSubmit}>
              <BoDialogHeader>Edit Phone</BoDialogHeader>
              <BoDialogBody>
                <PhoneDetailsContainer>
                  <Subtitle2 as="div">
                    Customer&apos;s current phone number:
                  </Subtitle2>
                  <Subtitle2 $fontWeight="SemiBold" as="div">
                    Primary: {formatPhoneNumber(phone)}
                    <br />
                    Secondary: {formatPhoneNumber(secondaryPhone)}
                  </Subtitle2>
                </PhoneDetailsContainer>
                <FormFlexRow>
                  <BoPhoneField name="phone" autoFocus>
                    Primary Number
                  </BoPhoneField>
                </FormFlexRow>

                <FormFlexRow>
                  <BoPhoneField name="secondaryPhone">
                    Secondary Number
                  </BoPhoneField>
                </FormFlexRow>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmDisabled={!dirty || hasValidationErrors}
                confirmLoading={requestMonitor.isPending}
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Cancel"
                onCancel={close}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
