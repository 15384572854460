import { EM_DASH } from "@common/constants/characters.constant";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@ops/components/LegalDocumentLinks/LegalDocumentLinks";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import { ConfirmationCardType } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import React from "react";
import styled from "styled-components";

const DocumentEntry = styled.div`
  & > a {
    font-size: 10px;
    margin-bottom: 4px;
  }
`;

interface ConfirmationPlanCardProps extends ConfirmationCardType {}

export const ConfirmationPlanCard = ({
  prospect,
  errors,
}: ConfirmationPlanCardProps) => {
  let rhythmEnergyRate = EM_DASH;

  if (prospect.offerSnapshot?.energyRate) {
    rhythmEnergyRate = `${
      prospect.offerSnapshot?.isMonthToMonth ? "Variable" : "Fixed"
    } ${EM_DASH} ${formatEnergyRate(prospect.offerSnapshot.energyRate, 5)}
`;
  }

  let buybackRate = EM_DASH;

  if (
    prospect.offerSnapshot?.solarEligible &&
    prospect.offerSnapshot?.solarCreditKwhRate
  ) {
    buybackRate = formatEnergyRate(
      prospect.offerSnapshot.solarCreditKwhRate,
      5
    );
  }

  return (
    <ConfirmationSection label="Plan" error={Boolean(errors)}>
      <BoGrid $variant={GridVariants.Grid4_1}>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Plan</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.planTitle)}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Term</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.term)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Rhythm Energy Rate</BoInfoLabel>
            <BoInfoValue>
              {prospect.offersnapshotId &&
              prospect.offerSnapshot?.isTimeOfUse ? (
                <TOUEnergyBreakdownModals
                  offerSnapshotId={prospect.offersnapshotId}
                />
              ) : (
                rhythmEnergyRate
              )}
            </BoInfoValue>
          </BoInfoContainer>
          {prospect.offerSnapshot?.solarEligible &&
          !prospect.offerSnapshot?.isTimeOfUse ? (
            <BoInfoContainer $error={errors?.offersnapshotId}>
              <BoInfoLabel>Buyback Energy Rate</BoInfoLabel>
              <BoInfoValue>{buybackRate}</BoInfoValue>
            </BoInfoContainer>
          ) : null}
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Avg Price (2000 kWh)</BoInfoLabel>
            <BoInfoValue>
              {stringOrEmDash(
                prospect.offerSnapshot?.price2000Kwh
                  ? formatDollarsToCents(prospect.offerSnapshot?.price2000Kwh)
                  : null
              )}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>OfferSnapshot Promo Code</BoInfoLabel>
            <BoInfoValue>
              {stringOrEmDash(prospect.offerSnapshot?.promoCode)}
            </BoInfoValue>
          </BoInfoContainer>

          <BoInfoContainer>
            <BoInfoLabel>Referral Code</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.referralCode)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoValue>
              {prospect.offersnapshotId && (
                <>
                  <DocumentEntry>
                    <EFLLink offerId={prospect.offersnapshotId}>
                      Electricity Facts Label
                    </EFLLink>
                  </DocumentEntry>
                  <DocumentEntry>
                    <TOSLink
                      tosUrl={
                        prospect.offerSnapshot?.rhythmTosLink("en") || "#"
                      }
                    >
                      Terms of Service
                    </TOSLink>
                  </DocumentEntry>
                  <DocumentEntry>
                    <YRACLink>Your Rights as a Customer</YRACLink>
                  </DocumentEntry>
                </>
              )}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
      </BoGrid>
    </ConfirmationSection>
  );
};
