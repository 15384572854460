import { CustomerStatus } from "@common/enums/customer.enum";
import { Address } from "@common/models/Address.model";
import { EDIMessage } from "@common/models/EDIMessage.model";
import { Meter } from "@common/models/Meter.model";
import { Order } from "@common/models/Order.model";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  AddressFormType,
  CustomerSourceType,
  CustomerType,
  EnrollmentType,
} from "@common/types/customerTypes";
import {
  SupportedPortalLanguage,
  englishLanguageCode,
} from "@common/types/languageTypes";
import { OrderStatus, OrderType } from "@common/types/orderTypes";
import { PremiseListItemType, PremiseType } from "@common/types/premiseTypes";

export class Customer {
  public activePremiseId: IdType;
  public applyEtfPoints?: boolean;
  public dateOfBirth!: string;
  public ediMessages: EDIMessage[];
  public email!: string;
  public esiIds!: string[];
  public etfAmount!: string;
  public etfCreditStatus!: string;
  public firstName!: string;
  public fullName!: string;
  public id: IdType = "";
  public isEmployee: boolean = false;
  public languagePreference: SupportedPortalLanguage;
  public lastName!: string;
  public phone: string;
  public secondaryPhone: string | null;
  public premiseIds?: IdType[];
  public premises: PremiseListItemType[];
  public status: CustomerStatus;
  public referralToFriendCode!: string;
  public source!: string;
  public zendeskUrl?: string;
  public ironhideId?: IdType;

  // Premise Fields migrated in Portal
  public activePremise: PremiseType;
  public activePremiseAddress: Address;
  public autopay!: boolean;
  public averagedBillingMonthlyCharge?: number;
  public averagedBilling!: boolean;
  public billingAccountId!: string;
  public billingAccountNumber!: string;
  public billingPaymentMethodId!: string;
  public confirmedStartDate?: string;
  public currentOrder: Order;
  public enrollmentStatus!: string;
  public enrollmentType!: EnrollmentType;
  public lifeSupport!: boolean;
  public mailingAddress?: AddressFormType;
  public mailingAddressSameAsServiceAddress!: boolean;
  public meter: Meter;
  public orders: OrderType[];
  public pickedBillingDueDay!: number | null;
  public serviceEndDate!: string;
  public serviceStartDate!: string;

  public featureFlagUserId: string | null;
  public uuid: UUIDType;

  public constructor({
    activePremise,
    activePremiseId,
    premiseIds,
    activePremiseAddress,
    ediMessages,
    languagePreference,
    mailingAddress,
    meter,
    orders,
    premises,
    status,
    featureFlagUserId,
    phone,
    secondaryPhone,
    ...rest
  }: CustomerType) {
    Object.assign(this, rest);
    this.activePremise = activePremise;
    this.languagePreference = languagePreference || englishLanguageCode;
    this.ediMessages = (ediMessages || []).map((msg) => new EDIMessage(msg));
    this.orders = orders || [];
    this.meter = new Meter(meter || {});
    this.activePremiseAddress = new Address(activePremiseAddress);
    this.status = status;
    this.mailingAddress = mailingAddress;
    this.phone = phone;
    this.secondaryPhone = secondaryPhone;
    this.currentOrder = new Order(this.nonExpiredOrders || ({} as OrderType));

    this.mailingAddressSameAsServiceAddress = !this.mailingAddress;

    this.activePremiseId = activePremiseId;
    this.premiseIds = premiseIds;
    this.premises = premises;
    this.featureFlagUserId = featureFlagUserId;
    this.uuid = rest.uuid;
  }

  public get nonExpiredOrders() {
    return this.orders.find((order) => order.status !== OrderStatus.Expired);
  }

  public get isFromIronhide(): boolean {
    return Boolean(this.source === CustomerSourceType.IRONHIDE);
  }
}
