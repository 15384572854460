import { useNotificationFetchOptions } from "@common/hooks/useNotificationFetchOptions";
import { noop } from "@common/utils/genericFunctions";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { H3 } from "@ops-design-system/components/Typography/Typography";
import { selectCustomerState } from "@ops-redux/selectors/customer.selectors";
import { AccountNotificationRow } from "@ops/components/ContactInfoCard/EditCustomerNotifications/AccountNotificationRow";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-bottom: 16px;
  }

  &:first-child {
    margin-bottom: 32px;
  }
`;

export const AccountNotificationSwitches = () => {
  const { customer } = useSelector(selectCustomerState);
  const flash = useRhFlash();

  const { notificationOptions, unsubscribedChannelIds, requestMonitor } =
    useNotificationFetchOptions(customer?.id);

  if (requestMonitor.isWaiting) {
    return <BoCircularProgress />;
  }

  if (
    requestMonitor.didFail ||
    !notificationOptions ||
    !customer ||
    !unsubscribedChannelIds
  ) {
    flash.error(
      "We are having issues getting your notification preferences. Please try again."
    );

    return null;
  }

  const initialValues = notificationOptions.reduce((result, option) => {
    const { messageId } = option;

    // eslint-disable-next-line no-param-reassign
    result[`messageId${messageId}`] =
      unsubscribedChannelIds.indexOf(messageId) === -1;
    return result;
  }, {} as { [key: string]: boolean });

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={() =>
        ["email", "sms"].map((commMedium) => (
          <Container key={commMedium}>
            <H3>
              {commMedium === "email" ? "Email me when:" : "Text me when:"}
            </H3>
            {notificationOptions.map(
              (option) =>
                option.commMedium === commMedium && (
                  <AccountNotificationRow
                    key={option.messageId}
                    option={option}
                    customerId={customer.id}
                  />
                )
            )}
          </Container>
        ))
      }
    />
  );
};
