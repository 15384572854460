import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { StatusIndicator } from "@common/models/StatusIndicator.model";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { selectPremiseStatusIndicatorsState } from "@ops-redux/selectors/premiseStatusIndicators.selectors";
import { premiseStatusIndicatorsFetch } from "@ops-redux/slices/premiseStatusIndicatorsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePremiseStatusIndicators = (
  premiseId: IdType | null
): {
  error: RhApiError | null;
  premiseStatusIndicators: StatusIndicator[] | null;
  requestMonitor: RequestMonitor;
} => {
  const { premiseStatusIndicators, error, status } = useSelector(
    selectPremiseStatusIndicatorsState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      premiseId &&
      premiseStatusIndicators === null &&
      !status.isPending &&
      !error
    ) {
      dispatch(premiseStatusIndicatorsFetch(premiseId));
    }
  }, [premiseId]);

  return {
    error,
    premiseStatusIndicators,
    requestMonitor: status,
  };
};
