import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { PaymentExtensionType } from "@common/types/billingTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as Extension } from "@ops-design-system/icons/Extension.svg";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { EditPaymentExtensionForm } from "@ops/components/PaymentExtensionCard/EditPaymentExtension/EditPaymentExtensionForm";
import { EditPaymentExtensionSuccess } from "@ops/components/PaymentExtensionCard/EditPaymentExtension/EditPaymentExtensionSuccess";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

type EditPaymentExtensionContentType =
  | "editPaymentExtensionForm"
  | "editPaymentExtensionSuccess";

interface EditPaymentExtensionProps {
  currentPaymentExtension: PaymentExtensionType;
  pastDueBalance: number;
  premise: Premise;
}

export const EditPaymentExtension = ({
  pastDueBalance,
  premise,
  currentPaymentExtension,
}: EditPaymentExtensionProps) => {
  const { open: openModal, close: closeModal, isOpen } = useModalState();
  const [newPaymentExtension, setNewPaymentExtension] =
    useState<PaymentExtensionType>(currentPaymentExtension);
  const [activeContent, setActiveContent] =
    useState<EditPaymentExtensionContentType>("editPaymentExtensionForm");
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setActiveContent("editPaymentExtensionForm");
    closeModal();
    dispatch(premiseRefetch(premise.id));
  };

  const handleSuccess = (paymentExtension: PaymentExtensionType) => {
    setNewPaymentExtension(paymentExtension);
    setActiveContent("editPaymentExtensionSuccess");
  };

  const editPaymentExtensionContents: Record<
    EditPaymentExtensionContentType,
    () => JSX.Element
  > = {
    editPaymentExtensionForm: () => (
      <EditPaymentExtensionForm
        onClose={handleCloseModal}
        onSuccess={(paymentExtension: PaymentExtensionType) => {
          handleSuccess(paymentExtension);
        }}
        pastDueBalance={pastDueBalance}
        premise={premise}
        currentPaymentExtension={currentPaymentExtension}
      />
    ),
    editPaymentExtensionSuccess: () => (
      <EditPaymentExtensionSuccess
        onClose={handleCloseModal}
        currentPaymentExtension={newPaymentExtension}
      />
    ),
  };

  return (
    <>
      <BoButton
        fullWidth
        icon={Extension}
        variant="secondary"
        size="extraSmall"
        onClick={openModal}
      >
        Edit Payment Extension
      </BoButton>
      <BoModal open={isOpen} onClose={handleCloseModal}>
        {editPaymentExtensionContents[activeContent]()}
      </BoModal>
    </>
  );
};
