import { useAjaxState } from "@common/hooks/useAjaxState";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { convertCamelCaseToSentenceCase } from "@common/utils/stringFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-api/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import {
  TabType,
  prospectEditorTabs,
} from "@ops/components/ProspectEditorTabs/configuration";
import { ProspectQueryKeys } from "@ops/constants/queryKeys.constants";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

enum Navigate {
  NEXT = 1,
  BACK = -1,
  STAY = 0,
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;

const ButtonContainer = styled.div`
  padding-right: ${rhOpsSpacingPx(2)};
`;

export const SaveButtons = () => {
  const queryClient = useQueryClient();
  const { featureFlagClient } = useFeatureFlagClient();

  const [navigate, setNavigate] = useState<Navigate>(Navigate.STAY);
  const { setCurrentTabComponent, currentTabComponent, prospect } =
    useEnrollmentFlowContext();

  const [{ requestMonitor }, { setPending, setFailure, setSuccess }] =
    useAjaxState();
  const flash = useRhFlash();
  const firstUpdate = useRef(true);

  const { opsProductAddOnsEnrollment: productAddonsEnrollmentFlag } =
    featureFlagClient.getFlags([["opsProductAddOnsEnrollment", false]]);

  const handleNavigation = () => {
    if (navigate === Navigate.STAY) {
      return;
    }
    const currentTabIndex = prospectEditorTabs.indexOf(currentTabComponent);
    let navigateTo = prospectEditorTabs[currentTabIndex + navigate];

    if (
      navigateTo === TabType.productAddons &&
      !productAddonsEnrollmentFlag.value
    ) {
      // If the feature flag for product-addons enrollment is OFF,
      // and the user is trying to navigate to the product-addons tab,
      // then navigate by one more tab
      navigateTo = prospectEditorTabs[currentTabIndex + navigate + navigate];
    }

    setNavigate(Navigate.STAY);
    setCurrentTabComponent(navigateTo);
  };

  const updateProspect = () => {
    setPending();

    return rhapsodyProspectApi
      .update(prospect.id, prospect)
      .then(() => {
        flash.success(
          `Successfully saved ${convertCamelCaseToSentenceCase(
            currentTabComponent
          )}`
        );
        setSuccess();
      })
      .catch(() => {
        flash.error(
          "There was an error saving the prospect. Please try again."
        );
        setFailure();
      })
      .finally(() => {
        queryClient.invalidateQueries({
          queryKey: [
            ...ProspectQueryKeys.PROSPECT_PREMISE_MATCHES,
            prospect.id,
          ],
        });
      });
  };

  useEffect(() => {
    // catch component mount and don't update prospect
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    updateProspect().then(handleNavigation);
  }, [prospect]);

  const submitContentForm = () => {
    document
      .getElementById(currentTabComponent)
      ?.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
  };
  const handleSaveAndNavigate = (queueNavigate: Navigate) => {
    setNavigate(queueNavigate);

    submitContentForm();
  };

  return (
    <Container>
      {currentTabComponent !== prospectEditorTabs[0] && (
        <ButtonContainer>
          <BoButton
            variant="secondary"
            loading={requestMonitor.isPending}
            onClick={() => handleSaveAndNavigate(Navigate.BACK)}
          >
            Save & Back
          </BoButton>
        </ButtonContainer>
      )}
      <ButtonContainer>
        <BoButton
          variant="secondary"
          loading={requestMonitor.isPending}
          onClick={() => handleSaveAndNavigate(Navigate.STAY)}
        >
          Save
        </BoButton>
      </ButtonContainer>
      {currentTabComponent !==
        prospectEditorTabs[prospectEditorTabs.length - 1] && (
        <ButtonContainer>
          <BoButton
            variant="secondary"
            loading={requestMonitor.isPending}
            onClick={() => handleSaveAndNavigate(Navigate.NEXT)}
          >
            Save & Next
          </BoButton>
        </ButtonContainer>
      )}
    </Container>
  );
};
