import { ProspectValidationReport } from "@ops-models/models/ProspectValidationReport.model";
import { StyledEmptyText } from "@ops/components/SupportLogs/SupportLogs.styled";
import { ValidationReportEntry } from "@ops/components/SupportLogs/ValidationReport/ValidationReportEntry";
import React from "react";
import styled from "styled-components";

interface ValidationLogProps {
  nosRequired: boolean;
  premiseMatches: ProspectValidationReport[];
}

const StyledValidationLogEntryContainer = styled.div``;

export function ValidationReportEntryList({
  premiseMatches,
  nosRequired,
}: ValidationLogProps): JSX.Element {
  const filteredPremiseMatches = premiseMatches.filter(
    (premiseMatch) =>
      premiseMatch.premiseMatchCollisionStatusLevel !== "Warning"
  );

  const sortedPremiseMatches = filteredPremiseMatches.sort((a, b) => {
    if (a.premise.pastDueBalance === b.premise.pastDueBalance) {
      return a.premise.billDue > b.premise.billDue ? -1 : 1;
    }
    return a.premise.pastDueBalance > b.premise.pastDueBalance ? -1 : 1;
  });

  const noMatchOutput = nosRequired ? (
    <div>
      <StyledEmptyText>
        A New Occupancy Statement (NOS) will be required.
      </StyledEmptyText>
    </div>
  ) : (
    <div>
      <StyledEmptyText>No Validation Issues</StyledEmptyText>
    </div>
  );

  return (
    <StyledValidationLogEntryContainer>
      <div>
        {sortedPremiseMatches.length ? (
          <>
            {sortedPremiseMatches.map((premiseMatch) => (
              <ValidationReportEntry
                premiseMatch={premiseMatch}
                key={premiseMatch.premise.id}
              />
            ))}
          </>
        ) : (
          noMatchOutput
        )}
      </div>
    </StyledValidationLogEntryContainer>
  );
}
