import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { UsageType } from "@common/types/usageTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@ops/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseKwhUsageDayOptions {
  enabled: boolean;
  premiseId: IdType;
  targetDate: string;
}

export const usePremiseKwhUsageDay = (
  options: UsePremiseKwhUsageDayOptions
) => {
  const { premiseId, enabled, targetDate } = options;

  const query = useQuery<UsageType[], RhApiError>({
    enabled,
    queryFn: () => rhapsodyPremiseApi.kwhUsageDay(premiseId, targetDate),
    queryKey: PremiseKeys.kwhUsageDay(premiseId, targetDate),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });

  return query;
};
