import { MONTH_NAME_DAY_YEAR } from "@common/constants/date.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { StatusIndicatorUpdateType } from "@common/types/statusIndicatorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { statusIndicatorsApi } from "@ops-api/api/statusIndicatorsApi";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  Body1,
  Body2,
} from "@ops-design-system/components/Typography/Typography";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { premiseStatusIndicatorsFetch } from "@ops-redux/slices/premiseStatusIndicatorsSlice";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { PremiseIndicatorsWizardComponentName } from "@ops/components/PremiseIndicatorsWizard/PremiseIndicatorsWizardHelpers";
import { useIndicatorsWizardContext } from "@ops/hooks/useIndicatorsWizardContext";
import dayjs from "dayjs";
import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const StatusIndicatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 700px;
`;

const ExpirationDateInnerValue = styled(Body2).attrs({ as: "span" })`
  color: ${darkPurple["500"]};
`;

const ExpirationDateText = styled(Body2).attrs({ as: "div" })`
  color: ${grey["500"]};
`;

const ExpirationDateContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const IndicatorLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${rhOpsSpacingPx(1.5)};
`;

const StatusIndicator = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`;

const IndicatorRequireExpDate = [
  "Collection Exempt",
  "Contract Termination Letter",
  "Moratorium",
  "Pledge",
  "Promise to pay",
  "Bill Dispute",
  "Pledge Pending",
  "Returned Mail",
  "Bill Hold",
  "DAP",
];

const IndicatorRequiresActivationDate = ["SBB Suspended"];

export const EditPremiseIndicatorsModal = () => {
  const {
    premiseId,
    closeModal,
    wizardState,
    updateWizardState,
    setActiveComponentName,
  } = useIndicatorsWizardContext();

  const { checkboxStatusIndicator, sortedStatusIndicatorsIds } = wizardState;
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const [
    { requestMonitor: submissionRequest },
    { setPending: setSubmitting, setSuccess: setSubmissionComplete },
  ] = useAjaxState();

  const handleUpdateIndicators = () => {
    setSubmitting();

    const selectedIndicators = Object.entries(checkboxStatusIndicator)
      .filter(([id, value]) => value.checked && !id.match(/derived-/))
      .map(([id, value]) => {
        const selectedIndicator: StatusIndicatorUpdateType = {
          id,
        };

        if (value.expirationDate !== null) {
          selectedIndicator.expirationDate = value.expirationDate;
        }
        if (value.activationDate !== null) {
          selectedIndicator.activationDate = value.activationDate;
        }

        return selectedIndicator;
      });

    statusIndicatorsApi.premiseAssignedStatusIndicators
      .update(premiseId, selectedIndicators)
      .then(() => {
        flash.success("Successfully updated indicators");
        dispatch(premiseStatusIndicatorsFetch(premiseId));
      })
      .catch((error: RhApiError) => {
        flash.error(
          error.data.errors.map((err) => err.detail || err.code).join(", ")
        );
      })
      .finally(() => {
        setSubmissionComplete();
        dispatch(premiseRefetch(premiseId));
        closeModal();
      });
  };

  const setIndicatorIdToEditExpirationDate = (id: string) => {
    updateWizardState({ indicatorIdToEditExpirationDate: id });
  };

  const openSetExpirationDateModal = (id: string) => {
    setIndicatorIdToEditExpirationDate(id);
    setActiveComponentName(
      PremiseIndicatorsWizardComponentName.setExpirationDate
    );
  };

  const setIndicatorIdToEditActivationDate = (id: string) => {
    updateWizardState({ indicatorIdToEditActivationDate: id });
  };

  const openSetActivationDateModal = (id: string) => {
    setIndicatorIdToEditActivationDate(id);
    setActiveComponentName(
      PremiseIndicatorsWizardComponentName.setActivationDate
    );
  };

  const updateCheckboxWizardState = (id: string, checked: boolean) => {
    // Update indicator checked attr and expiration date
    updateWizardState({
      ...wizardState,
      checkboxStatusIndicator: {
        ...checkboxStatusIndicator,
        [id]: {
          ...checkboxStatusIndicator[id],
          checked,
          expirationDate: null,
        },
      },
    });
  };

  const handleCheck = (id: string) => (ev: ChangeEvent<HTMLInputElement>) => {
    const { checked } = ev.currentTarget;

    if (checked) {
      if (IndicatorRequireExpDate.includes(checkboxStatusIndicator[id].name)) {
        openSetExpirationDateModal(id);
      } else if (
        IndicatorRequiresActivationDate.includes(
          checkboxStatusIndicator[id].name
        )
      ) {
        openSetActivationDateModal(id);
      } else {
        updateCheckboxWizardState(id, checked);
      }
    } else {
      updateCheckboxWizardState(id, checked);
    }
  };

  if (Object.entries(checkboxStatusIndicator).length === 0) {
    return null;
  }

  return (
    <>
      <BoDialogHeader>Indicators</BoDialogHeader>
      <BoDialogBody>
        <StatusIndicatorsContainer>
          {sortedStatusIndicatorsIds.map((id) => {
            return (
              <StatusIndicator key={id}>
                <BoCheckboxInput
                  id={`checkbox-${id}`}
                  onChange={handleCheck(id)}
                  checked={checkboxStatusIndicator[id].checked}
                />
                <IndicatorLabel>
                  <Body1 as="label" htmlFor={`checkbox-${id}`}>
                    {checkboxStatusIndicator[id].name}
                  </Body1>

                  {checkboxStatusIndicator[id].expirationDate && (
                    <ExpirationDateContainer>
                      <ExpirationDateText data-testid={`expiration_date_${id}`}>
                        Expiring on{" "}
                        <ExpirationDateInnerValue>
                          {dayjs(
                            checkboxStatusIndicator[id].expirationDate
                          ).format(MONTH_NAME_DAY_YEAR)}
                        </ExpirationDateInnerValue>
                      </ExpirationDateText>
                      <EditLink
                        onClick={() => {
                          openSetExpirationDateModal(id);
                        }}
                      />
                    </ExpirationDateContainer>
                  )}

                  {checkboxStatusIndicator[id].activationDate && (
                    <ExpirationDateContainer>
                      <ExpirationDateText data-testid={`activation_date_${id}`}>
                        Activated on{" "}
                        <ExpirationDateInnerValue>
                          {dayjs(
                            checkboxStatusIndicator[id].activationDate
                          ).format(MONTH_NAME_DAY_YEAR)}
                        </ExpirationDateInnerValue>
                      </ExpirationDateText>
                      <EditLink
                        onClick={() => {
                          openSetActivationDateModal(id);
                        }}
                      />
                    </ExpirationDateContainer>
                  )}
                </IndicatorLabel>
              </StatusIndicator>
            );
          })}
        </StatusIndicatorsContainer>
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Update"
        cancelBtnText="Cancel"
        onConfirm={handleUpdateIndicators}
        onCancel={closeModal}
        confirmLoading={submissionRequest.isPending}
      />
    </>
  );
};
