import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface SimplisafeKeyAssignedData extends EventLogBaseData {
  email: string;
  offerLabel: string;
}

export class SimplisafeKeyAssigned extends EventLog {
  public readonly email: string;
  public readonly offerLabel: string;

  constructor({
    email,
    offerLabel,
    ...baseEventData
  }: SimplisafeKeyAssignedData) {
    super(baseEventData);

    this.email = email;
    this.offerLabel = offerLabel;
  }

  override get body() {
    return `Customer met eligibility requirements for ${this.offerLabel} - Activation email sent to ${this.email}`;
  }
}
