import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import {
  BoAccordion,
  BoAccordionDetails,
} from "@ops-design-system/components/BoAccordion/BoAccordion";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { yesNo } from "@ops-design-system/utils/booleanHelpers";
import { OffersSection } from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import {
  EmailOfferSnapshot,
  EmailOffersContainer,
  StyledBoAccordionSummary,
} from "@ops/components/ContractSwapsAndRenewals/ContractRenewal/ContractRenewal.styled";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { usePremiseOneClickRenewalOfferSnapshots } from "@ops/hooks/queries/usePremiseOneClickRenewalOfferSnapshots";
import React from "react";

interface EmailOutReachOfferSnapshotsProps {
  handleOfferSnapshotSelected: (offerSnapshot: OfferSnapshot) => void;
  premiseId: IdType;
  selectedOfferSnapshot: OfferSnapshot | null;
}

export const EmailOutReachOfferSnapshots = (
  props: EmailOutReachOfferSnapshotsProps
) => {
  const { premiseId, handleOfferSnapshotSelected, selectedOfferSnapshot } =
    props;
  const oneClickRenewalOfferSnapshotsQuery =
    usePremiseOneClickRenewalOfferSnapshots({ premiseId, retry: false });

  if (oneClickRenewalOfferSnapshotsQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (oneClickRenewalOfferSnapshotsQuery.isError) {
    return (
      <BoCard>
        <Body2>Failed to fetch Email Outreach Offer Snapshots</Body2>
      </BoCard>
    );
  }

  if (
    oneClickRenewalOfferSnapshotsQuery.data.oneClickRenewalOfferSnapshots
      .length === 0
  ) {
    return (
      <BoCard>
        <Body2>No Email Outreach Offer Snapshots</Body2>
      </BoCard>
    );
  }

  const { oneClickRenewalOfferSnapshots } =
    oneClickRenewalOfferSnapshotsQuery.data;

  return (
    <BoAccordion>
      <StyledBoAccordionSummary
        label="Email Outreach Offer Snapshots"
        summary="Email Outreach Offer Snapshots"
      />
      <BoAccordionDetails>
        <OffersSection aria-label="Email Outreach Offer Snapshots">
          <EmailOffersContainer>
            {oneClickRenewalOfferSnapshots.map((oneClick) => {
              const { offerSnapshot } = oneClick;

              return (
                <EmailOfferSnapshot key={offerSnapshot.id}>
                  <div>
                    <Body2 $fontWeight="Bold">Expired:</Body2>
                    <Body2 data-expired={oneClick.expired}>
                      {yesNo(oneClick.expired)}
                    </Body2>
                  </div>
                  <div>
                    <Body2 $fontWeight="Bold">Date Offered:</Body2>
                    <Body2>
                      {formatMonthDayYear(oneClick.lastSegmentEventSent)}
                    </Body2>
                  </div>
                  <OfferCard
                    offerSnapshot={offerSnapshot}
                    onSelect={handleOfferSnapshotSelected}
                    active={selectedOfferSnapshot?.id === offerSnapshot.id}
                  />
                </EmailOfferSnapshot>
              );
            })}
          </EmailOffersContainer>
        </OffersSection>
      </BoAccordionDetails>
    </BoAccordion>
  );
};
