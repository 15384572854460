import { premiseApi } from "@common/api/premiseApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as ClockWithArrow } from "@ops-design-system/icons/ClockWithArrow.svg";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { PremiseErrorCodes } from "@ops/constants/errorCodes.constants";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface UpdateServiceStartFormValues {
  newServiceStartDate: string;
}

interface UpdateServiceStartProps {
  currentPremise: Premise;
}

const updateServiceStartFormValidator =
  generateValidationErrorCollector<UpdateServiceStartFormValues>({
    newServiceStartDate: [isRequired],
  });

export const UpdateServiceStart = ({
  currentPremise,
}: UpdateServiceStartProps) => {
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const flash = useRhFlash();

  const handleUpdateServiceStart = (newServiceStartDate: string) => {
    premiseApi
      .updateServiceStartDate(currentPremise.id, newServiceStartDate)
      .then(() => {
        dispatch(premiseRefetch(currentPremise.id));
        queryClient.invalidateQueries({
          queryKey: ["premise", currentPremise.id],
        });
      })
      .catch((error: RhApiError) => {
        let errorMsg;

        switch (error.data.errorCode) {
          case PremiseErrorCodes.SERVICE_START_DATE_INVALID: {
            errorMsg =
              "Invalid date given. Unable to update service start date on premise.";
            break;
          }
          case PremiseErrorCodes.INVALID_PREMISE_STATE_FOR_OPERATION: {
            errorMsg =
              "The update you requested cannot be performed because the current premise state does not allow it.";
            break;
          }
          default: {
            errorMsg =
              "Issue updating service start date for this premise. Please try again.";
          }
        }
        flash.error(errorMsg);
      });
  };
  const handleSubmit = ({
    newServiceStartDate,
  }: UpdateServiceStartFormValues) => {
    close();

    return handleUpdateServiceStart(newServiceStartDate);
  };

  return (
    <>
      <BoButton
        onClick={open}
        variant="secondary"
        size="extraSmall"
        icon={ClockWithArrow}
      >
        Update Service Start
      </BoButton>
      <BoModal open={isOpen} onClose={close}>
        <Form<UpdateServiceStartFormValues>
          initialValues={{
            newServiceStartDate: currentPremise.currentOrder?.startDate,
          }}
          onSubmit={handleSubmit}
          validate={updateServiceStartFormValidator}
          validateOnBlur
          render={({ handleSubmit: formHandleSubmit }) => (
            <form noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Update Service Start</BoDialogHeader>
              <BoDialogBody>
                <BoDateField
                  name="newServiceStartDate"
                  placeholder="New Service Start Date"
                  label="New Service Start Date"
                />
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Close"
                onCancel={close}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
