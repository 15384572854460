import { ErrorText } from "@ops-design-system/components/BoTextInput/BoTextInput.styled";
import {
  activeBorders,
  borders,
  errorBorders,
} from "@ops-design-system/styles/borders";
import styled from "styled-components";

const radius = "4px";
const height = "120px";

const borderColor = (hasErrors?: boolean) => {
  if (hasErrors) {
    return errorBorders["500"];
  } else {
    return borders["300"];
  }
};

export const StyledTextAreaError = styled(ErrorText)`
  font: inherit;
`;

interface StyledBoTextAreaProps {
  $hasErrors?: boolean;
}

export const StyledBoTextArea = styled.textarea<StyledBoTextAreaProps>`
  border: ${({ $hasErrors }) => borderColor($hasErrors)};
  border-radius: ${radius};
  font: inherit;
  height: ${height};
  outline: none;
  resize: none;
  width: 100%;

  &:focus {
    border: ${activeBorders["500"]};
  }
`;

export const BoTextAreaContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;
