const BASE_PATH = "/api/support-logs/contact-logs";

export const contactLogUrls = {
  batchUploads: {
    list: () => `${BASE_PATH}/batch-uploads/`,
  },
  categories: {
    list: () => `${BASE_PATH}/categories/`,
  },
};
