import { IdType } from "@common/types/apiTypes";
import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import { InvoiceQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceUsageQueryProps {
  invoiceId: IdType;
}

export const useInvoiceUsageQuery = (props: UseInvoiceUsageQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => rhapsodyApi.invoices.usage(invoiceId),
    queryKey: InvoiceQueryKeys.usage(invoiceId),
  });
};
