import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { PaymentExtensionType } from "@common/types/billingTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as Extension } from "@ops-design-system/icons/Extension.svg";
import { paymentMethodsFetch } from "@ops-redux/slices/paymentMethodsSlice";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { AddACreditCardForm } from "@ops/components/AddCreditCardForm/AddACreditCardForm";
import { initialDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/DownPaymentCalculatorStateProvider";
import { useDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/useDownPaymentCalculatorState";
import { LoadingModalContent } from "@ops/components/LoadingModalContent/LoadingModalContent";
import { PaymentExtensionForm } from "@ops/components/PaymentExtensionForm/PaymentExtensionForm";
import { initialPaymentExtensionFormState } from "@ops/components/PaymentExtensionForm/PaymentExtensionForm.constants";
import { usePaymentExtensionFormState } from "@ops/components/PaymentExtensionForm/usePaymentExtensionFormState";
import { PaymentExtensionComponentType } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionComponentType";
import { PaymentExtensionSuccess } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionSuccess";
import { usePaymentExtensionModalState } from "@ops/components/PaymentExtensionWizardModal/usePaymentExtensionModalStateProvider";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export interface CreatePaymentExtensionSuccessType {
  paymentExtension: PaymentExtensionType;
  zuoraPaymentNumber?: string;
}

interface PaymentExtensionWizardModalProps {
  onMakePayment: () => void;
  pastDueBalance: number;
  premiseId: IdType;
}

export const PaymentExtensionWizardModal = ({
  pastDueBalance,
  premiseId,
  onMakePayment,
}: PaymentExtensionWizardModalProps) => {
  const { open, close: closeModal, isOpen } = useModalState();
  const { paymentMethods, requestMonitor } = usePaymentMethods(premiseId);
  const [
    createPaymentExtensionSuccessResponse,
    setCreatePaymentExtensionSuccessResponse,
  ] = useState<CreatePaymentExtensionSuccessType | null>(null);

  const { updateFormState } = usePaymentExtensionFormState();

  const { updateModalState, activeComponent } = usePaymentExtensionModalState();

  const { updateCalculatorState } = useDownPaymentCalculatorState();

  const dispatch = useDispatch();
  const resetModalState = () => {
    setCreatePaymentExtensionSuccessResponse(null);
    updateFormState({
      ...initialPaymentExtensionFormState,
    });
    updateModalState({
      activeComponent: PaymentExtensionComponentType.paymentExtensionForm,
    });
    updateCalculatorState({ ...initialDownPaymentCalculatorState });
  };

  const close = () => {
    closeModal();
    if (activeComponent === "paymentExtensionSuccess") {
      dispatch(premiseRefetch(premiseId));
      if (createPaymentExtensionSuccessResponse?.zuoraPaymentNumber) {
        onMakePayment();
      }
    }
    resetModalState();
  };

  const handleSuccess = (
    paymentExtension: PaymentExtensionType,
    zuoraPaymentNumber?: string
  ) => {
    setCreatePaymentExtensionSuccessResponse({
      paymentExtension,
      zuoraPaymentNumber,
    });
    updateModalState({
      activeComponent: PaymentExtensionComponentType.paymentExtensionSuccess,
    });
  };

  const handleAddedCard = () => {
    dispatch(paymentMethodsFetch(premiseId));
    updateModalState({
      activeComponent: PaymentExtensionComponentType.paymentExtensionForm,
    });
  };

  useEffect(() => {
    const setInitialActivePaymentComponent = (paymentMethodCount: number) => {
      if (isNil(paymentMethodCount)) {
        updateModalState({
          activeComponent: PaymentExtensionComponentType.loading,
        });
      } else {
        updateModalState({
          activeComponent: PaymentExtensionComponentType.paymentExtensionForm,
        });
      }
    };

    if (requestMonitor.didSucceed) {
      setInitialActivePaymentComponent(paymentMethods?.length ?? 0);
    }
    if (requestMonitor.didFail) {
      updateModalState({
        activeComponent: PaymentExtensionComponentType.paymentExtensionForm,
      });
    }
  }, [
    paymentMethods?.length,
    requestMonitor.didSucceed,
    requestMonitor.didFail,
  ]);

  const makeAPaymentExtensionComponents: Record<
    PaymentExtensionComponentType,
    () => JSX.Element
  > = {
    addPaymentMethod: () => (
      <AddACreditCardForm
        premiseId={premiseId}
        onCancel={() => {
          updateModalState({
            activeComponent: PaymentExtensionComponentType.paymentExtensionForm,
          });
        }}
        onSuccess={handleAddedCard}
        setAsDefault={(paymentMethods?.length ?? 0) === 0}
      />
    ),
    loading: () => <LoadingModalContent headerText="Payment Extension" />,
    paymentExtensionForm: () => (
      <PaymentExtensionForm
        premiseId={premiseId}
        pastDueBalance={pastDueBalance}
        onSuccess={handleSuccess}
        closeModal={close}
      />
    ),
    paymentExtensionSuccess: () => (
      <PaymentExtensionSuccess
        newPaymentExtensionData={createPaymentExtensionSuccessResponse}
        closeModal={close}
      />
    ),
  };

  return (
    <>
      <BoButton
        fullWidth
        icon={Extension}
        variant="secondary"
        size="extraSmall"
        onClick={open}
      >
        Extend Payment
      </BoButton>
      <BoModal
        open={isOpen}
        size={
          activeComponent ===
            PaymentExtensionComponentType.paymentExtensionSuccess ||
          activeComponent === PaymentExtensionComponentType.addPaymentMethod
            ? "default"
            : "large"
        }
        onClose={close}
      >
        {makeAPaymentExtensionComponents[activeComponent]()}
      </BoModal>
    </>
  );
};
