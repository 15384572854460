import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface AdverseActionLoggedData extends EventLogBaseData {
  creditScore: string;
  depositAmount: string;
}

export class AdverseActionLogged extends EventLog {
  public readonly depositAmount: string;
  public readonly creditScore: string;

  constructor({
    depositAmount,
    creditScore,
    ...baseEventData
  }: AdverseActionLoggedData) {
    super({ ...baseEventData, eventText: "Adverse Action Logged" });

    this.depositAmount = depositAmount;
    this.creditScore = creditScore;
  }

  override get body() {
    return `${this.name} - Deposit Amount: $${this.depositAmount}.00 - Credit Score: ${this.creditScore}`;
  }
}
