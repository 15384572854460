import { EM_DASH } from "@common/constants/characters.constant";
import { Premise } from "@common/models/Premise.model";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import {
  ContractRenewalWizardPremiseInfoSidebarContainer,
  SideBarLabel,
  SideBarSectionContainer,
  SideBarValue,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardPremiseInfoSidebar/ContractRenewalWizardPremiseInfoSidebar.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import dayjs from "dayjs";
import React, { PropsWithChildren, useId } from "react";

interface ContractRenewalWizardPremiseInfoSidebarProps {
  premise: Premise;
}

interface SideBarSectionProps {
  inline?: boolean;
  label: string;
}
const SideBarSection = (props: PropsWithChildren<SideBarSectionProps>) => {
  const { label, children, inline = false } = props;

  const sectionId = useId();

  return (
    <SideBarSectionContainer $inline={inline}>
      <SideBarLabel $inline={inline} id={sectionId}>
        {label}
      </SideBarLabel>
      <SideBarValue aria-labelledby={sectionId}>{children}</SideBarValue>
    </SideBarSectionContainer>
  );
};

export const ContractRenewalWizardPremiseInfoSidebar = (
  props: ContractRenewalWizardPremiseInfoSidebarProps
) => {
  const { premise } = props;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: premise.currentOrder?.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offersnapshotId),
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <ContractRenewalWizardPremiseInfoSidebarContainer>
        <BoCircularProgress />
      </ContractRenewalWizardPremiseInfoSidebarContainer>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <ContractRenewalWizardPremiseInfoSidebarContainer>
        <Body2>Error fetching offer details</Body2>
      </ContractRenewalWizardPremiseInfoSidebarContainer>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  let remainingMonths = EM_DASH;
  let cancelationFee = EM_DASH;
  const shouldDisplayCancellationInfo =
    !premise.serviceEndDate &&
    premise.currentOrder?.contractEndDate &&
    dayjs() <= dayjs(premise.currentOrder?.contractEndDate);

  if (shouldDisplayCancellationInfo) {
    remainingMonths = `${premise.currentOrder?.remainingMonths} months`;
    cancelationFee = formatCurrency(premise.currentOrder?.cancellationFee);
  }

  return (
    <ContractRenewalWizardPremiseInfoSidebarContainer
      as="section"
      aria-label="Premise Information"
    >
      <SideBarSection label="Current Address">
        <div>{premise.serviceAddress?.line1}</div>
        <div>{premise.serviceAddress?.line2}</div>
      </SideBarSection>

      <SideBarSection label="Current Plan">
        <span>{offerSnapshot.title}</span>
        <span>
          {offerSnapshot.isTimeOfUse ? (
            <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
          ) : (
            <div>
              Energy Rate: {formatEnergyRate(offerSnapshot.energyRate())}
            </div>
          )}
        </span>
        <span>
          {`${offerSnapshot.termMonths} months, ${
            offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
          } Rate`}
        </span>
      </SideBarSection>

      <SideBarSection label="Cancelation Fee">{cancelationFee}</SideBarSection>
      <SideBarSection label="Remaining Months">
        {remainingMonths}
      </SideBarSection>

      <div>
        <SideBarSection label="Start Date" inline>
          {stringOrEmDash(formatMonthDayYear(premise.currentOrder?.startDate))}
        </SideBarSection>

        <SideBarSection label="End Date" inline>
          {stringOrEmDash(
            formatMonthDayYear(premise.currentOrder?.contractEndDate)
          )}
        </SideBarSection>
      </div>

      <div>
        <SideBarSection label="Paperless" inline>
          {onOff(premise.isPaperless)}
        </SideBarSection>
        <SideBarSection label="Auto Pay" inline>
          {onOff(premise.autopay)}
        </SideBarSection>
      </div>
    </ContractRenewalWizardPremiseInfoSidebarContainer>
  );
};
