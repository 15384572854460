import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ReturnPaymentFeeData extends EventLogBaseData {
  billingAccountNumber: string;
  paymentAmount: string;
  paymentNumber: string;
  returnFeeAmount: string;
  returnPaymentDate: string;
}

export class ReturnPaymentFee extends EventLog {
  public readonly billingAccountNumber: string;
  public readonly paymentAmount: string;
  public readonly paymentNumber: string;
  public readonly returnFeeAmount: string;
  public readonly returnPaymentDate: string;

  constructor({
    billingAccountNumber,
    paymentAmount,
    paymentNumber,
    returnFeeAmount,
    returnPaymentDate,
    ...baseEventData
  }: ReturnPaymentFeeData) {
    super({
      ...baseEventData,
      eventText: "Return Payment Fee",
    });

    this.billingAccountNumber = billingAccountNumber;
    this.paymentAmount = paymentAmount;
    this.paymentNumber = paymentNumber;
    this.returnFeeAmount = returnFeeAmount;
    this.returnPaymentDate = returnPaymentDate;
  }

  override get body() {
    return `${this.name} - Return Payment Fee: ${formatCurrency(
      this.returnFeeAmount
    )} - Return Date: ${formatMonthDayYear(
      this.returnPaymentDate
    )} -  Payment Number: ${
      this.paymentNumber
    } - Payment Amount: ${formatCurrency(this.paymentAmount)}
    - Account Number: ${this.billingAccountNumber} `;
  }
}
