import { useAjaxState } from "@common/hooks/useAjaxState";
import { ContactLogService } from "@ops/components/SupportLogs/ContactLog/ContactLogService";
import { SupportLogTarget } from "@ops/components/SupportLogs/SupportLogs.types";
import { ContactLog as ContactLogModel } from "@ops/models/ContactLog.model";
import { useCallback } from "react";

export const useContactLogsInState = (target: SupportLogTarget) => {
  const [
    { data, requestMonitor, error },
    { setFailure, setSuccess, setPending },
  ] = useAjaxState<ContactLogModel[]>();
  const contactLogService = new ContactLogService(target.type);

  const fetch = useCallback(() => {
    if (!target.id) {
      return Promise.resolve();
    }

    setPending();

    return contactLogService
      .list(target.id)
      .then((response) => {
        const contactLogModels = response.map(
          (contactLog) => new ContactLogModel(contactLog)
        );

        setSuccess(contactLogModels);
      })
      .catch(setFailure);
  }, [target.id]);

  return {
    data,
    error,
    fetch,
    requestMonitor,
  };
};
