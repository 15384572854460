import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DeferredPaymentPlanCreationData extends EventLogBaseData {
  agreementDate: string;
  depositAmount: string;
  numberOfInstallments: string;
  pastDueBalance: string;
  totalBalance: string;
}

export class DeferredPaymentPlanCreation extends EventLog {
  public readonly agreementDate: string;
  public readonly depositAmount: string;
  public readonly pastDueBalance: string;
  public readonly numberOfInstallments: string;
  public readonly totalBalance: string;

  constructor({
    agreementDate,
    depositAmount,
    pastDueBalance,
    numberOfInstallments,
    totalBalance,
    ...baseEventData
  }: DeferredPaymentPlanCreationData) {
    super({
      ...baseEventData,
      eventText: "Deferred Payment Plan (DPP) Created",
    });

    this.agreementDate = agreementDate;
    this.depositAmount = depositAmount;
    this.pastDueBalance = pastDueBalance;
    this.numberOfInstallments = numberOfInstallments;
    this.totalBalance = totalBalance;
  }

  override get body() {
    return `${this.name} – Total Balance: $${this.totalBalance} – Down Payment: $${this.depositAmount} - Deferred Amount: $${this.pastDueBalance} – Number of Installments: ${this.numberOfInstallments}`;
  }
}
