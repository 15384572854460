import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface InvoiceAvailableData extends EventLogBaseData {
  accountNumber: string;
  balance: string;
  billPeriodEnd: string;
  billPeriodStart: string;
  deviceMangementProgram: string;
  dueDate: string;
  ineligibilityReasons: string;
  invoiceAmountDue: string;
  invoiceDate: string;
  invoiceEnergyAmountDue: string;
  invoiceId: string;
  invoiceProductAmountDue: string;
  invoiceType: string;
  metEligibilityRequirements: string;
  onDeviceManagementPlan: string;
  productAddOn: string;
}

export class InvoiceAvailable extends EventLog {
  public readonly billPeriodStart: string;
  public readonly balance: string;
  public readonly billPeriodEnd: string;
  public readonly invoiceId: string;
  public readonly accountNumber: string;
  public readonly deviceMangementProgram: string;
  public readonly dueDate: string;
  public readonly ineligibilityReasons: string;
  public readonly invoiceDate: string;
  public readonly invoiceAmountDue: string;
  public readonly invoiceType: string;
  public readonly invoiceEnergyAmountDue: string;
  public readonly invoiceProductAmountDue: string;
  public readonly metEligibilityRequirements: string;
  public readonly onDeviceManagementPlan: string;
  public readonly productAddOn: string;

  constructor({
    billPeriodStart,
    balance,
    billPeriodEnd,
    deviceMangementProgram,
    ineligibilityReasons,
    invoiceId,
    accountNumber,
    dueDate,
    invoiceDate,
    invoiceAmountDue,
    invoiceType,
    invoiceEnergyAmountDue,
    invoiceProductAmountDue,
    metEligibilityRequirements,
    onDeviceManagementPlan,
    productAddOn,
    ...baseEventData
  }: InvoiceAvailableData) {
    super(baseEventData);

    this.billPeriodStart = formatMonthDayYear(billPeriodStart);
    this.balance = balance;
    this.billPeriodEnd = formatMonthDayYear(billPeriodEnd);
    this.deviceMangementProgram = deviceMangementProgram;
    this.ineligibilityReasons = ineligibilityReasons;
    this.invoiceId = invoiceId;
    this.accountNumber = accountNumber;
    this.dueDate = formatMonthDayYear(dueDate);
    this.invoiceAmountDue = invoiceAmountDue;
    this.invoiceDate = invoiceDate;
    this.invoiceType = invoiceType;
    this.invoiceEnergyAmountDue = invoiceEnergyAmountDue;
    this.invoiceProductAmountDue = invoiceProductAmountDue;
    this.metEligibilityRequirements = metEligibilityRequirements;
    this.onDeviceManagementPlan = onDeviceManagementPlan;
    this.productAddOn = productAddOn;
  }

  override get body() {
    return `${this.name} - ${
      this.invoiceType !== "" ? ` Type: ${this.invoiceType}, ` : " "
    } Bill Period Start: ${this.billPeriodStart} - Bill Period End: ${
      this.billPeriodEnd
    }, Due Date: ${this.dueDate} - Invoice Amount Due: $${
      this.invoiceAmountDue
    }   ${
      this.productAddOn
        ? ` - Total Energy Amount: $${this.invoiceEnergyAmountDue} - Total Add On Amount:$${this.invoiceProductAmountDue} - Product Add On: ${this.productAddOn}`
        : " - Product Add On: false"
    } ${
      this.onDeviceManagementPlan ||
      this.deviceMangementProgram ||
      (this.metEligibilityRequirements &&
        this.metEligibilityRequirements.length > 0)
        ? `\n\nThermostat Eligibility
          On Device Management Plan: ${this.onDeviceManagementPlan}
          Has Devices Connected: ${this.deviceMangementProgram}
          Met Eligibility Requirements: ${this.metEligibilityRequirements}
          ${this.ineligibilityReasons.length > 0 ? `Ineligibility Reasons: ${this.ineligibilityReasons}` : ""}`
        : ""
    }
    `;
  }
}
