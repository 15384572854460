import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DeferredPaymentPlanDefaultSettlementData
  extends EventLogBaseData {
  outstandingAmount: string;
}

export class DeferredPaymentPlanDefaultSettlement extends EventLog {
  public readonly outstandingAmount: string;

  constructor({
    outstandingAmount,
    ...baseEventData
  }: DeferredPaymentPlanDefaultSettlementData) {
    super({
      ...baseEventData,
      eventText: "Deferred Payment Plan (DPP) Defaulted",
    });
    this.outstandingAmount = outstandingAmount;
  }

  override get body() {
    return `${this.name} - Settlement Charge of $${this.outstandingAmount}`;
  }
}
