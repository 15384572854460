import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { PriceTierSnapshotType } from "@common/types/priceTierSnapshotType";
import { ProspectOfferSnapshotType } from "@ops-models/types/prospectTypes";

export class ProspectOfferSnapshot {
  private readonly rhythmTos: Record<SupportedPortalLanguage, string>;
  public readonly title: string;
  public readonly termMonths: number;
  public readonly price2000Kwh: string;
  public readonly rhythmKwhRate: number;
  public readonly promoCode: string | null | undefined;
  public readonly promoValue: number | null | undefined;
  public readonly solarCreditKwhRate: string;
  public readonly solarEligible: boolean;
  public readonly priceTierSnapshots: PriceTierSnapshotType[];
  public readonly rhythmTosEn: string;
  public readonly rhythmTosEs: string;

  constructor(offerSnapshot: ProspectOfferSnapshotType) {
    this.title = offerSnapshot.title;
    this.termMonths = offerSnapshot.termMonths;
    this.price2000Kwh = offerSnapshot.price2000Kwh;
    this.rhythmKwhRate = offerSnapshot.rhythmKwhRate;
    this.promoCode = offerSnapshot.promoCode;
    this.promoValue = offerSnapshot.promoValue;
    this.solarCreditKwhRate = offerSnapshot.solarCreditKwhRate;
    this.solarEligible = offerSnapshot.solarEligible;
    this.priceTierSnapshots = offerSnapshot.priceTierSnapshots;
    this.rhythmTosEn = offerSnapshot.rhythmTosEn;
    this.rhythmTosEs = offerSnapshot.rhythmTosEs;
    this.rhythmTos = {
      en: offerSnapshot.rhythmTosEn,
      es: offerSnapshot.rhythmTosEs,
    };
  }

  get energyRate() {
    return this.rhythmKwhRate;
  }

  get isMonthToMonth(): boolean {
    return Boolean(this.termMonths && this.termMonths < 2);
  }

  get isTimeOfUse(): boolean {
    return Boolean(
      this.priceTierSnapshots ? this.priceTierSnapshots.length > 0 : false
    );
  }

  rhythmTosLink(locale: SupportedPortalLanguage): string {
    return this.rhythmTos[locale];
  }
}
