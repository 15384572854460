import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { DeviceDemandResponseActivityTable } from "@ops/components/DeviceDemandResponseActivityTable/DeviceDemandResponseActivityTable";
import { useThermostatDemandResponseActivityQuery } from "@ops/hooks/queries/useThermostatDemandResponseActivity";
import React, { useState } from "react";

interface DeviceDemandResponseActivityProps {
  premiseId: IdType;
  thermostatId: IdType;
}

export const DeviceDemandResponseActivity = (
  props: DeviceDemandResponseActivityProps
) => {
  const { premiseId, thermostatId } = props;
  // Controlled table state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: thermostatDemandResponseActivityData,
    error,
    isPending,
  } = useThermostatDemandResponseActivityQuery({
    premiseId,
    queryOptions: {
      enabled: Boolean(premiseId && thermostatId),
      keepPreviousData: true,
    },
    searchOptions: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      thermostatId,
    },
  });

  if (error) {
    return <div>Failed to fetch device demand response activity</div>;
  }

  if (isPending) {
    return <BoCircularProgress />;
  }

  if (!thermostatDemandResponseActivityData) {
    return null;
  }

  const pageCount = thermostatDemandResponseActivityData?.count
    ? Math.ceil(thermostatDemandResponseActivityData.count / pageSize)
    : 0;

  return (
    <DeviceDemandResponseActivityTable
      demandResponseActivity={thermostatDemandResponseActivityData.results}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
    />
  );
};
