import { IdType } from "@common/types/apiTypes";
import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import {
  ProductContractKeys,
  ProductKeys,
} from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateProductContract = (premiseId: IdType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rhapsodyApi.productContracts.create,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ProductContractKeys.PREMISE_LIST(premiseId),
      });
      queryClient.invalidateQueries({
        queryKey: ProductKeys.AVAILABLE_FOR_PREMISE(premiseId),
      });
    },
  });
};
