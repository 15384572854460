import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface EbillPreferenceUpdatedData extends EventLogBaseData {
  currentCommPreference: string;
  ebill: boolean;
  previousCommPreference: string;
}

export class EbillPreferenceUpdated extends EventLog {
  public readonly ebill: boolean;
  public readonly previousCommPreference: string;
  public readonly currentCommPreference: string;

  constructor({
    ebill,
    previousCommPreference,
    currentCommPreference,
    ...baseEventData
  }: EbillPreferenceUpdatedData) {
    super(baseEventData);

    this.ebill = ebill;
    this.previousCommPreference = previousCommPreference;
    this.currentCommPreference = currentCommPreference;
  }

  override get body() {
    if (this.previousCommPreference && this.currentCommPreference) {
      return `Switched from ${this.previousCommPreference} to ${this.currentCommPreference}`;
    }
    if (this.currentCommPreference) {
      return `Opted in to ${this.currentCommPreference}`;
    }
    if (this.ebill) {
      return "Opted in to Ebill";
    } else {
      return "Opted out of Ebill";
    }
  }
}
