import { prospectUrls } from "@common/api/prospectUrls";
import { ajax } from "@common/services/ajax";
import { CreditScoreResponseType } from "@common/types/apiResponseTypes";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { ProspectCreatePostDataType } from "@common/types/prospectTypes";
import { rhapsodyUrls } from "@ops-api/api/rhapsodyUrls";
import {
  ContactLogType,
  CreateContactLogType,
} from "@ops-models/types/contactLogTypes";
import {
  ProspectCreditCheckRequestType,
  ProspectSearchRequestType,
  ProspectType,
} from "@ops-models/types/prospectTypes";
import { ProspectValidationReportType } from "@ops-models/types/prospectValidationReportTypes";

export const rhapsodyProspectApi = {
  contactLogs: {
    create: (id: IdType, contactLog: CreateContactLogType): Promise<void> => {
      return ajax.post(prospectUrls.contactLogs.create(id), contactLog);
    },
    list: (id: IdType): Promise<ContactLogType[]> => {
      return ajax.get(prospectUrls.contactLogs.list(id));
    },
  },
  create: <R = ProspectType>(
    prospectData: ProspectCreatePostDataType
  ): Promise<R> => {
    return ajax.post<R, ProspectCreatePostDataType>(
      rhapsodyUrls.prospects.create(),
      prospectData
    );
  },
  creditScoreEvaluation: <R = CreditScoreResponseType>(
    customerData: ProspectCreditCheckRequestType
  ): Promise<R> => {
    return ajax.post<R, ProspectCreditCheckRequestType>(
      rhapsodyUrls.prospects.creditScoreEvaluation(),
      customerData
    );
  },
  fraud: {
    premiseMatches: (
      prospectId: IdType
    ): Promise<ProspectValidationReportType[]> => {
      return ajax.get(rhapsodyUrls.prospects.premiseMatches(prospectId));
    },
  },
  retrieve: <R = ProspectType>(prospectId: IdType | UUIDType): Promise<R> => {
    return ajax.get<R>(rhapsodyUrls.prospects.retrieve(prospectId));
  },
  search: (
    queryParams?: ProspectSearchRequestType
  ): Promise<ProspectType[]> => {
    return ajax.get<ProspectType[]>(rhapsodyUrls.prospects.list(), {
      params: queryParams,
    });
  },
  // TODO: refactor update to take a single object for eventual TanStack Query Mutation conversion
  update: <R = void>(
    prospectId: IdType,
    prospectData: ProspectCreatePostDataType
  ): Promise<R> => {
    return ajax.patch<R, ProspectCreatePostDataType>(
      rhapsodyUrls.prospects.update(prospectId),
      prospectData
    );
  },
};
