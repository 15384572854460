import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body1, H1 } from "@ops-design-system/components/Typography/Typography";
import { AFFILIATE_UNIQUE_EMAIL_ERROR_CODE } from "@ops/constants/affiliates.constants";
import { useAffiliateUpdate } from "@ops/hooks/mutations/useAffiliateUpdate";
import { useAffiliate } from "@ops/hooks/queries/useAffiliate";
import { AffiliateFields } from "@ops/pages/NewAffiliatePage/NewAffiliateFields";
import {
  AffiliateFormValues,
  affiliateFormValidator,
} from "@ops/pages/NewAffiliatePage/NewAffiliatePage";
import { affiliatesPath } from "@ops/routes/routePaths";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > h1 {
    text-align: center;
  }

  & > form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const EditAffiliatePage = () => {
  const navigate = useNavigate();
  const flash = useRhFlash();
  const { id } = useParams<{ id: string }>();

  const affiliateQuery = useAffiliate({ affiliateId: id });

  const updateAffiliateMutation = useAffiliateUpdate();

  if (affiliateQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (affiliateQuery.isError || !affiliateQuery.data) {
    return <Body1>Unable to get affiliate. Please try again.</Body1>;
  }

  const initialValues = Object.freeze<AffiliateFormValues>(affiliateQuery.data);

  const onSubmit = (affiliateData: AffiliateFormValues) => {
    updateAffiliateMutation.mutate(
      {
        affiliateData,
        affiliateId: id,
      },
      {
        onError: (error) => {
          if (error.data.errorCode === AFFILIATE_UNIQUE_EMAIL_ERROR_CODE) {
            flash.error("Affiliate Email must be unique.");
          } else {
            flash.error("Unable to create affiliate. Please try again.");
          }
        },
        onSuccess: () => {
          navigate(affiliatesPath());
        },
      }
    );
  };

  return (
    <Container>
      <H1>Edit Affiliate</H1>
      <RhFlexBox justifyContent="center" width={500}>
        <Form<AffiliateFormValues>
          initialValues={initialValues}
          validate={affiliateFormValidator}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form noValidate style={{ width: "100%" }} onSubmit={handleSubmit}>
              <AffiliateFields />
              <BoButton type="submit">Save Affiliate</BoButton>
            </form>
          )}
        />
      </RhFlexBox>
    </Container>
  );
};
