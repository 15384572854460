import { formatCurrency } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface LateFeeAppliedData extends EventLogBaseData {
  accountNumber: string;
  balance: string;
  invoiceNumber: string;
}

export class LateFeeApplied extends EventLog {
  public readonly invoiceNumber: string;
  public readonly accountNumber: string;
  public readonly balance: string;

  constructor({
    invoiceNumber,
    accountNumber,
    balance,
    ...baseEventData
  }: LateFeeAppliedData) {
    super(baseEventData);

    this.invoiceNumber = invoiceNumber;
    this.accountNumber = accountNumber;
    this.balance = balance;
  }

  override get body() {
    return `${this.name} - Invoice Number: ${
      this.invoiceNumber
    } - Balance: ${formatCurrency(this.balance)}`;
  }
}
