import { ediApi } from "@common/api/ediApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import {
  CopyEdiMessageRequestType,
  MoveEdiMessageRequestType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoRadioField } from "@ops-design-system/components/BoRadioField/BoRadioField";
import { BoRadioGroup } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const Header = styled(Body2)`
  font-weight: 600;
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const RhapsodyFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(3)};
`;

const IronhideFlexbox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(4)};
  width: 400px;
`;

const StyledDivider = styled(BoDivider)`
  margin: ${rhOpsSpacingPx(3)} 0 ${rhOpsSpacingPx(4)};
`;

const StyledBoBox = styled.div`
  width: 400px;
`;

const EdiMessageFormButtonContainer = styled.div`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

export interface MoveEdiMessageFormType {
  message: string;
  premise: IdType;
}

export interface CopyEdiMessageFormType {
  ediFilePath: string;
  ediTransactionId: string;
  premise: IdType;
}

export interface TransferEdiMessageFormType {
  copyPremise?: IdType;
  ediFilePath?: string;
  ediTransactionId?: string;
  message?: string;
  moveOrCopy: "move" | "copy";
  movePremise?: IdType;
}

const transferEdiMessageFormValidator =
  generateValidationErrorCollector<TransferEdiMessageFormType>({
    copyPremise: [
      (copyPremise: IdType, { moveOrCopy }: TransferEdiMessageFormType) => {
        if (moveOrCopy === "copy") {
          return isRequired(copyPremise);
        }
      },
    ],
    ediFilePath: [
      (ediFilePath: string, { moveOrCopy }: TransferEdiMessageFormType) => {
        if (moveOrCopy === "copy") {
          return isRequired(ediFilePath);
        }
      },
    ],
    ediTransactionId: [
      (
        ediTransactionId: string,
        { moveOrCopy }: TransferEdiMessageFormType
      ) => {
        if (moveOrCopy === "copy") {
          return isRequired(ediTransactionId);
        }
      },
    ],
    message: [
      (message: string, { moveOrCopy }: TransferEdiMessageFormType) => {
        if (moveOrCopy === "move") {
          return isRequired(message);
        }
      },
    ],
    moveOrCopy: [isRequired],
    movePremise: [
      (movePremise: IdType, { moveOrCopy }: TransferEdiMessageFormType) => {
        if (moveOrCopy === "move") {
          return isRequired(movePremise);
        }
      },
    ],
  });

export const TransferEdiMessageForm = () => {
  const [, { setSuccess, setPending, setFailure }] = useAjaxState();

  const flash = useRhFlash();

  const submitTransferEdiMessage = (values: TransferEdiMessageFormType) => {
    if (values.moveOrCopy === "copy") {
      setPending();
      /* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/ban-ts-comment */
      const data: CopyEdiMessageRequestType = {
        ediFilePath: values.ediFilePath!,
        ediTransactionId: values.ediTransactionId!,
        premise: values.copyPremise!,
      };
      /* eslint-enable */

      ediApi.ediMessage
        .copy(data)
        .then(() => {
          setSuccess();
          flash.success("Successfully transferred EDI message from Ironhide");
        })
        .catch((error: RhApiError) => {
          setFailure(error);
          flash.error("An error occurred attempting to transfer EDI message");
        });
    } else if (values.moveOrCopy === "move") {
      setPending();
      /* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/ban-ts-comment */
      const data: MoveEdiMessageRequestType = {
        message: values.message!,
        premise: values.movePremise!,
      };
      /* eslint-enable */

      ediApi.ediMessage
        .move(data)
        .then(() => {
          setSuccess();
          flash.success("Successfully transferred EDI message in Rhapsody");
        })
        .catch((error: RhApiError) => {
          setFailure(error);
          flash.error("An error occurred attempting to transfer EDI message");
        });
    }
  };

  return (
    <>
      <Header>EDI Message</Header>
      <BoCard>
        <Form<TransferEdiMessageFormType>
          onSubmit={submitTransferEdiMessage}
          validate={transferEdiMessageFormValidator}
          render={({ handleSubmit, values }) => {
            const moveDisabled = values.moveOrCopy !== "move";
            const copyDisabled = values.moveOrCopy !== "copy";

            return (
              <form>
                <BoRadioGroup>
                  <BoRadioField
                    value="move"
                    name="moveOrCopy"
                    label="Transfer within Rhapsody"
                  />
                  <RhapsodyFlexBox>
                    <StyledBoBox>
                      <BoTextField name="message" disabled={moveDisabled}>
                        EDI Message ID
                      </BoTextField>
                    </StyledBoBox>
                    <StyledBoBox>
                      <BoTextField name="movePremise" disabled={moveDisabled}>
                        Transfer to premise ID
                      </BoTextField>
                    </StyledBoBox>
                  </RhapsodyFlexBox>
                  <StyledDivider />
                  <BoRadioField
                    value="copy"
                    name="moveOrCopy"
                    label="Transfer from Ironhide"
                  />
                  <IronhideFlexbox>
                    <BoTextField name="ediFilePath" disabled={copyDisabled}>
                      ESG File Full Path
                    </BoTextField>
                    <BoTextField
                      name="ediTransactionId"
                      disabled={copyDisabled}
                    >
                      EDI Sender Transaction ID
                    </BoTextField>
                    <BoTextField name="copyPremise" disabled={copyDisabled}>
                      Transfer to premise ID
                    </BoTextField>
                  </IronhideFlexbox>
                </BoRadioGroup>
                <EdiMessageFormButtonContainer>
                  <BoButtonWithConfirmDialog
                    message="Are you sure you want to complete this transfer?"
                    header="Transfer EDI Message"
                    handleConfirm={handleSubmit}
                    confirmBtnText="Transfer"
                    buttonContent="Transfer Message"
                    buttonFullWidth={false}
                  />
                </EdiMessageFormButtonContainer>
              </form>
            );
          }}
        />
      </BoCard>
    </>
  );
};
