import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { noop } from "@common/utils/genericFunctions";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoSelectInput,
  BoSelectOptionType,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { red } from "@ops-design-system/styles/palette.colors";
import { useActiveCampaignSlugs } from "@ops/hooks/queries/useActiveCampaignSlugs";
import React from "react";
import styled from "styled-components";

const ErrorMessage = styled(Body2).attrs({ as: "div" })`
  color: ${red.main};
`;
const BoSelectWidthConstraint = styled.div`
  max-width: 400px;
  width: 100%;
`;

interface BoCampaignSlugSelectProps {
  ariaLabelledby?: string;
  filter?: string;
  onChange: (slug: CampaignSlugs | null) => void;
  value?: CampaignSlugs | null;
}

export const BoCampaignSlugSelect = (
  { ariaLabelledby, filter, onChange, value }: BoCampaignSlugSelectProps = {
    onChange: noop,
  }
) => {
  const campaignSlugQuery = useActiveCampaignSlugs(filter);

  if (campaignSlugQuery.isPending) {
    return <BoCircularProgress />;
  }
  if (campaignSlugQuery.isError) {
    return <ErrorMessage>Unable to fetch campaign information</ErrorMessage>;
  }

  if (campaignSlugQuery.data.length === 0) {
    return null;
  }

  const campaignSlugOptions = campaignSlugQuery.data.map((campaignSlug) => {
    return { label: campaignSlug, value: campaignSlug };
  });

  const handleChange = (option: BoSelectOptionType | null) => {
    if (option?.value) {
      onChange(option?.value?.toString() as CampaignSlugs);
    } else {
      onChange(null);
    }
  };

  return (
    <BoSelectWidthConstraint>
      <BoSelectInput
        ariaLabelledby={ariaLabelledby}
        value={value}
        options={campaignSlugOptions}
        onChange={handleChange}
      />
    </BoSelectWidthConstraint>
  );
};
