import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { PlanTypes } from "@ops-models/types/planTypes";

export enum OfferSnapshotRenewalTiersOfferLabels {
  RENEWAL_TIER_1 = "renewal-tier-1",
  RENEWAL_TIER_2 = "renewal-tier-2",
  RENEWAL_TIER_3 = "renewal-tier-3",
  RENEWAL_TIER_4 = "renewal-tier-4",
}

enum OfferSnapshotOfferLabels {}

export const OfferSnapshotLabels = {
  ...OfferSnapshotRenewalTiersOfferLabels,
  ...OfferSnapshotOfferLabels,
} as const;

export type OfferSnapshotLabelsType =
  | OfferSnapshotRenewalTiersOfferLabels
  | OfferSnapshotOfferLabels;

export enum OfferSnapshotOrderOptions {
  "Price: High to Low" = "-price",
  "Price: Low to High" = "price",
}

export enum OfferSnapshotTermLengths {
  NINE = "9",
  ONE = "1",
  SIX = "6",
  SIXTEEN = "16",
  THIRTY_SIX = "36",
  THREE = "3",
  TWELVE = "12",
  TWENTY_FOUR = "24",
}

export interface OfferSnapshotSearchOptions {
  campaignSlug?: CampaignSlugs | null;
  dunsNumber: string;
  limit?: number;
  offerLabels?: OfferSnapshotLabelsType[];
  ordering?: OfferSnapshotOrderOptions;
  planType?: PlanTypes[];
  termMonths?: OfferSnapshotTermLengths[];
}
