import Menu from "@mui/material/Menu";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckbox } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { ButtonFont } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as FilterIcon } from "@ops-design-system/icons/FilterIcon.svg";
import {
  OfferSnapshotRenewalTiersOfferLabels,
  OfferSnapshotSearchOptions,
  OfferSnapshotTermLengths,
} from "@ops-models/types/offerSnapshotTypes";
import { PlanTypes } from "@ops-models/types/planTypes";
import {
  Legend,
  StyledButtonContainer,
  StyledCheckboxRow,
  StyledFieldset,
  StyledFilterButton,
  StyledFilterForm,
} from "@ops/components/OfferSnapshotFiltering/FilterMenu/FilterMenu.styled";
import {
  planTypeMapping,
  termLengthMapping,
  tiersMapping,
} from "@ops/components/OfferSnapshotFiltering/offerSnapshotFiltering.constants";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

interface FilterOfferSnapshotsMenuProps {
  onSubmit: (values: OfferSnapshotSearchOptions) => void;
  searchOptions?: OfferSnapshotSearchOptions;
}

export const FilterMenu = (props: FilterOfferSnapshotsMenuProps) => {
  const { onSubmit, searchOptions } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledFilterButton
        $isPurple={isMenuOpen}
        onClick={handleClick}
        aria-label="Filter OfferSnapshots"
      >
        <FilterIcon />
        <ButtonFont>Filter</ButtonFont>
      </StyledFilterButton>
      {isMenuOpen ? (
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
          <FilterMenuForm
            onSubmit={onSubmit}
            searchOptions={searchOptions}
            onClose={handleClose}
          />
        </Menu>
      ) : null}
    </>
  );
};

interface OfferSnapshotFilterFormProps extends FilterOfferSnapshotsMenuProps {
  onClose: () => void;
}

const FilterMenuForm = ({
  searchOptions,
  onSubmit,
  onClose,
}: OfferSnapshotFilterFormProps) => {
  const { reset, handleSubmit, register } = useForm<OfferSnapshotSearchOptions>(
    {
      defaultValues: searchOptions,
    }
  );

  const handleReset = () => {
    reset(
      {
        offerLabels: [],
        planType: [],
        termMonths: [],
      },
      {
        keepDefaultValues: false,
      }
    );
  };

  const handleFormSubmit: SubmitHandler<OfferSnapshotSearchOptions> = (
    data
  ) => {
    onSubmit(data);
    onClose();
  };

  return (
    <StyledFilterForm onSubmit={handleSubmit(handleFormSubmit)}>
      <StyledFieldset>
        <Legend>Tiers:</Legend>
        {Object.values(OfferSnapshotRenewalTiersOfferLabels).map((value) => {
          return (
            <StyledCheckboxRow key={value}>
              <BoCheckbox {...register("offerLabels")} value={value} />
              {tiersMapping[value]}
            </StyledCheckboxRow>
          );
        })}
      </StyledFieldset>

      <StyledFieldset>
        <Legend>Term length:</Legend>
        {Object.values(OfferSnapshotTermLengths)
          .sort((a, b) => Number(a) - Number(b))
          .map((value) => {
            return (
              <StyledCheckboxRow key={value}>
                <BoCheckbox {...register("termMonths")} value={value} />
                {termLengthMapping[value]}
              </StyledCheckboxRow>
            );
          })}
      </StyledFieldset>

      <StyledFieldset>
        <Legend>Plan type:</Legend>
        {Object.values(PlanTypes).map((value) => {
          return (
            <StyledCheckboxRow key={value}>
              <BoCheckbox {...register("planType")} value={value} />
              {planTypeMapping[value]}
            </StyledCheckboxRow>
          );
        })}
      </StyledFieldset>
      <StyledButtonContainer>
        <BoButton type="submit">Apply</BoButton>
        <BoButton variant="secondary" onClick={handleReset}>
          Reset
        </BoButton>
      </StyledButtonContainer>
    </StyledFilterForm>
  );
};
