import { useModalState } from "@common/hooks/useModalState";
import { formatCurrency } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-api/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import { BoSsnInput } from "@ops-design-system/components/BoSsnInput/BoSsnInput";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { Prospect } from "@ops-models/models/Prospect.model";
import { ProspectCreditCheckRequestType } from "@ops-models/types/prospectTypes";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import {
  WarningCard,
  WarningMessage,
} from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoStyledCards";
import { CreditCheckFormValues } from "@ops/components/ProspectEditor/BillingInfoTab/CreditCheckCard/CreditCheckCard";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React, { useState } from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  width: 135px;
`;

const RefetchWrapper = styled.div`
  padding-left: ${rhOpsSpacingPx(2)};
`;

const RadioWrapper = styled.div`
  flex-basis: 50%;
`;

const InputWrapper = styled.div`
  margin-top: ${rhOpsSpacingPx(3.5)};
`;

interface RunCreditCheckModalProps {
  disabled?: boolean;
}

interface HandleSsnRequiredProps {
  onChange: (values: CreditCheckFormValues) => void;
}

const RunCreditCheckModal = ({ disabled }: RunCreditCheckModalProps) => {
  const { open, close, isOpen } = useModalState();
  const [ssn, setSsn] = useState("");
  const flash = useRhFlash();

  const {
    prospect: {
      acquisitionMedium,
      addressLine1,
      city,
      dateOfBirth,
      email,
      firstName,
      id,
      lastName,
      phone,
      state,
      unitNumber,
      zipCode,
    },
    updateProspectState,
  } = useEnrollmentFlowContext();

  const { fetchProspect } = useProspectInState(id);

  const onSubmit = (ssnValue: string) => {
    if (
      !firstName ||
      !lastName ||
      !dateOfBirth ||
      !phone ||
      !email ||
      !addressLine1 ||
      !city ||
      !state ||
      !zipCode
    ) {
      flash.error("Needs more prospect data to run credit check");

      return;
    }

    const creditCheckData: ProspectCreditCheckRequestType = {
      acquisitionMedium,
      addressLine1,
      city,
      dateOfBirth,
      email,
      firstName,
      lastName,
      phone,
      prospectId: id,
      ssn: ssnValue,
      state,
      unitNumber,
      zipCode,
    };

    return rhapsodyProspectApi
      .creditScoreEvaluation(creditCheckData)
      .then(() => {
        fetchProspect()
          .then((prospect) => {
            if (prospect) {
              updateProspectState(prospect);
            }
          })
          .catch(() => flash.error("Unable to gather updated prospect data"));
      })
      .catch(() => {
        flash.error("Unable to complete credit check. Try again later");
      });
  };

  return (
    <>
      <BoButton variant="secondary" onClick={open} disabled={disabled}>
        Run Credit Check
      </BoButton>
      <BoModal open={isOpen} onClose={close}>
        <BoDialogHeader>Run Credit Check</BoDialogHeader>
        <BoDialogBody>
          <Subtitle2>
            To run credit check, enter customer&apos;s social security number.
          </Subtitle2>
          <InputWrapper>
            <BoSsnInput name="ssn" onChange={(val) => setSsn(String(val))}>
              Social Security Number
            </BoSsnInput>
          </InputWrapper>
        </BoDialogBody>
        <BoDialogButtonFooter
          cancelBtnText="Cancel"
          confirmBtnText="Submit"
          onCancel={close}
          onConfirm={() => onSubmit(ssn)}
        />
      </BoModal>
    </>
  );
};

export const CreditCheckSsnRequired = ({
  onChange,
}: HandleSsnRequiredProps) => {
  const { prospect: prospectData, updateProspectState } =
    useEnrollmentFlowContext();
  const prospect = new Prospect(prospectData);

  const [bypassCreditCheck, setBypassCreditCheck] = useState<
    boolean | undefined
  >(prospect.bypassedCreditCheck);

  const { fetchProspect } = useProspectInState(prospectData.id);

  const flash = useRhFlash();

  const handleToggle = (value: number | string) => {
    const bypass = value === "bypassCreditCheck";

    setBypassCreditCheck(bypass);
    onChange({ bypassedCreditCheck: bypass });
  };

  const handleRefetch = () => {
    if (
      !prospectData.firstName ||
      !prospectData.lastName ||
      !prospectData.dateOfBirth ||
      !prospectData.phone ||
      !prospectData.email ||
      !prospectData.addressLine1 ||
      !prospectData.city ||
      !prospectData.state ||
      !prospectData.zipCode
    ) {
      flash.error("Needs more prospect data to run credit check");

      return;
    }

    const creditCheckData: ProspectCreditCheckRequestType = {
      acquisitionMedium: prospectData.acquisitionMedium,
      addressLine1: prospectData.addressLine1,
      city: prospectData.city,
      dateOfBirth: prospectData.dateOfBirth,
      email: prospectData.email,
      firstName: prospectData.firstName,
      lastName: prospectData.lastName,
      phone: prospectData.phone,
      prospectId: prospectData.id,
      state: prospectData.state,
      unitNumber: prospectData.unitNumber,
      zipCode: prospectData.zipCode,
    };

    return rhapsodyProspectApi
      .creditScoreEvaluation(creditCheckData)
      .then(() => {
        fetchProspect()
          .then((prospectRefetchData) => {
            if (prospectRefetchData) {
              updateProspectState(prospectRefetchData);
            }
          })
          .catch(() => flash.error("Unable to gather updated prospect data"));
      })
      .catch(() => {
        flash.error("Unable to fetch credit check information");
      });
  };

  return (
    <WarningCard>
      <WarningMessage>
        Additional information needed to complete credit check.
      </WarningMessage>
      <BoDivider />
      <BoFlexBox>
        <RadioWrapper>
          <BoRadioInput
            name="ssnOrBypass"
            checked={!bypassCreditCheck}
            label="Provide SSN"
            value="provideSsn"
            onClick={handleToggle}
          />
        </RadioWrapper>
        <RadioWrapper>
          <BoRadioInput
            name="ssnOrBypass"
            value="bypassCreditCheck"
            checked={
              bypassCreditCheck === undefined ? false : bypassCreditCheck
            }
            label={`Bypass and pay a ${formatCurrency(
              prospect?.depositAmount ?? 0
            )} deposit.`}
            onClick={handleToggle}
          />
        </RadioWrapper>
      </BoFlexBox>
      <ButtonContainer>
        <RunCreditCheckModal disabled={bypassCreditCheck} />
        <AccessControl>
          <RefetchWrapper>
            <BoButton variant="secondary" onClick={handleRefetch}>
              Refetch Credit Status
            </BoButton>
          </RefetchWrapper>
        </AccessControl>
      </ButtonContainer>
    </WarningCard>
  );
};
