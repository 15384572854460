import { AjaxStatuses, IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { StatusIndicatorType } from "@common/types/statusIndicatorTypes";
import { StatusIndicatorsStateType } from "@ops-models/types/opsSlicesTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";

export const initialPremiseStatusIndicatorsState =
  Object.freeze<StatusIndicatorsStateType>({
    data: null,
    error: null,
    requestStatus: AjaxStatuses.Idle,
  });

const premiseStatusIndicatorsRequestedHandler: CaseReducer<
  StatusIndicatorsStateType,
  PayloadAction<void>
> = (state) => {
  return { ...state, requestStatus: AjaxStatuses.Pending };
};

const premiseStatusIndicatorsReceivedHandler: CaseReducer<
  StatusIndicatorsStateType,
  PayloadAction<StatusIndicatorType[]>
> = (state, action) => {
  return {
    data: action.payload,
    error: null,
    requestStatus: AjaxStatuses.Success,
  };
};

const premiseStatusIndicatorsErroredHandler: CaseReducer<
  StatusIndicatorsStateType,
  PayloadAction<RhApiError>
> = ({ data }, { payload: error }) => {
  return {
    data,
    error,
    requestStatus: AjaxStatuses.Failure,
  };
};

const premiseStatusIndicatorsClearHandler: CaseReducer<
  StatusIndicatorsStateType,
  PayloadAction<void>
> = () => {
  return { ...initialPremiseStatusIndicatorsState };
};

const premiseStatusIndicatorsReducers = {
  clear: premiseStatusIndicatorsClearHandler,
  errored: premiseStatusIndicatorsErroredHandler,
  received: premiseStatusIndicatorsReceivedHandler,
  requested: premiseStatusIndicatorsRequestedHandler,
};

const premiseStatusIndicatorsSlice = createSlice<
  StatusIndicatorsStateType,
  typeof premiseStatusIndicatorsReducers
>({
  initialState: initialPremiseStatusIndicatorsState,
  name: "premiseStatusIndicators",
  reducers: premiseStatusIndicatorsReducers,
});

export const premiseStatusIndicatorsReducer =
  premiseStatusIndicatorsSlice.reducer;

export const premiseStatusIndicatorsRequested =
  premiseStatusIndicatorsSlice.actions.requested;
export const premiseStatusIndicatorsReceived =
  premiseStatusIndicatorsSlice.actions.received;
export const premiseStatusIndicatorsErrored =
  premiseStatusIndicatorsSlice.actions.errored;
export const premiseStatusIndicatorsClear =
  premiseStatusIndicatorsSlice.actions.clear;

export const premiseStatusIndicatorsFetch = createAction<IdType>(
  `${premiseStatusIndicatorsSlice.name}/fetch`
);
