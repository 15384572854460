import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PaymentExtensionDueTomorrowData extends EventLogBaseData {
  dueDate: string;
}

export class PaymentExtensionDueTomorrow extends EventLog {
  public readonly dueDate: string;

  constructor({ dueDate, ...baseEventData }: PaymentExtensionDueTomorrowData) {
    super(baseEventData);

    this.dueDate = formatMonthDayYear(dueDate);
  }

  override get body(): string | null {
    return `${this.name}\nExtension Date: ${this.dueDate}`;
  }
}
