import { premiseApi } from "@common/api/premiseApi";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { selectPremiseState } from "@ops-redux/selectors/premise.selectors";
import {
  premiseErrored,
  premiseFetch,
  premiseReceived,
  premiseRefetch,
  premiseRequested,
} from "@ops-redux/slices/premiseSlice";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchPremiseWorker(action: { payload: IdType; type: string }) {
  const { premise } = yield select(selectPremiseState);

  const premiseId = action.payload;

  if (premise?.id !== premiseId) {
    yield call(refetchPremiseWorker, action);
  }
}

export function* refetchPremiseWorker(action: {
  payload: IdType;
  type: string;
}) {
  yield put(premiseRequested());
  try {
    const premiseId = action.payload;

    const premise: SagaReturnType<typeof premiseApi.retrieve> = yield call(
      premiseApi.retrieve,
      premiseId
    );

    yield put(premiseReceived(premise));
  } catch (err: unknown) {
    yield put(premiseErrored(err as RhApiError));
  }
}

export function* premiseWatcher() {
  yield all([
    takeLatest(premiseFetch.type, fetchPremiseWorker),
    takeLatest(premiseRefetch.type, refetchPremiseWorker),
  ]);
}
