import { IdType } from "@common/types/apiTypes";

const PREMISE_BASE_URL = "/api/rhapsody/v2/premises";
const V1_PREMISE_BASE_URL = "/api/premises";

export const rhapsodyPremiseUrls = {
  accountSummary: {
    retrieve: (id: IdType) => `${PREMISE_BASE_URL}/${id}/account_summary/`,
  },
  attachedDevices: (id: IdType) => `${PREMISE_BASE_URL}/${id}/attached-devices`,
  billForecastSummary: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/bill-forecast-summary`,
  communicationPreferences: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/communication-preferences`,
  createPayment: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/create-payment/`,
  depositAlternative: {
    retrieve: (premiseId: IdType) =>
      `${PREMISE_BASE_URL}/${premiseId}/deposit-alternative`,
  },
  kwhUsageDay: (id: IdType) => `${PREMISE_BASE_URL}/${id}/kwh-usage-day`,
  kwhUsageSummary: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/kwh-usage-summary`,
  oneClickRenewalOfferSnapshots: (id: IdType) =>
    `${PREMISE_BASE_URL}/${id}/one-click-renewal-offer-snapshots`,
  orders: (id: IdType) => `${PREMISE_BASE_URL}/${id}/orders/`,
  quickPay: {
    sendPaymentLink: (premiseId: IdType) =>
      `api/rhapsody/premises/${premiseId}/quick-pay-token/`,
  },
  renewContract: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/renew-contract`,
  setAutopay: (premiseId: IdType) =>
    `${V1_PREMISE_BASE_URL}/${premiseId}/autopay/`,
  thermostatConnectionActivity: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/thermostat-connection-activity/`,
  thermostatDemandResponseActivity: (premiseId: IdType) =>
    `${PREMISE_BASE_URL}/${premiseId}/thermostat-demand-response-activity/`,
  transferService: (id: IdType) =>
    `${V1_PREMISE_BASE_URL}/${id}/transfer-service/`,
};
