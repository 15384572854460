import {
  OfferSnapshotLabelsType,
  OfferSnapshotTermLengths,
} from "@ops-models/types/offerSnapshotTypes";
import { PlanTypes } from "@ops-models/types/planTypes";

export const termLengthMapping: Record<OfferSnapshotTermLengths, string> = {
  "1": "Month-to-month",
  "12": "12 months",
  "16": "16 months",
  "24": "24 months",
  "3": "3 months",
  "36": "36 months",
  "6": "6 months",
  "9": "9 months",
};

export const tiersMapping: Record<OfferSnapshotLabelsType, string> = {
  "renewal-tier-1": "Renewal Tier 1",
  "renewal-tier-2": "Renewal Tier 2",
  "renewal-tier-3": "Renewal Tier 3",
  "renewal-tier-4": "Renewal Tier 4",
};

export const planTypeMapping: Record<PlanTypes, string> = {
  "fixed-standard": "Fixed Standard",
  "solar-buyback": "Solar buyback",
  "time-of-use": "Time-of-use",
};
