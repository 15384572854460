import { premiseApi } from "@common/api/premiseApi";
import { EdiActions } from "@common/constants/edi.constants";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { ReactSVGComponent } from "@common/types/svg";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDialog } from "@ops-design-system/components/BoDialog/BoDialog";
import { customerRefetch } from "@ops-redux/slices/customerSlice";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

interface EdiActionTriggerWithConfirmationProps {
  action: EdiActions;
  buttonIcon: ReactSVGComponent;
  cancelBtnText?: string;
  confirmBtnText: string;
  confirmMessage: string;
  customerId: IdType;
  errorMessage: string;
  header: string;
  triggerOpenDialogBtnText: string;
}

export const EdiActionTriggerWithConfirmation = ({
  action,
  buttonIcon,
  cancelBtnText,
  confirmBtnText,
  confirmMessage,
  customerId,
  errorMessage,
  header,
  triggerOpenDialogBtnText,
}: EdiActionTriggerWithConfirmationProps) => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { open, close, isOpen } = useModalState();
  const [{ requestMonitor }, { setPending, setFailure, setSuccess }] =
    useAjaxState();
  const flash = useRhFlash();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  if (!premiseId) {
    return null;
  }

  const handleConfirm = () => {
    setPending();

    premiseApi.ediMessages
      .create(premiseId, {
        actionName: action,
      })
      .then(() => {
        setSuccess();
        dispatch(customerRefetch(customerId));
        queryClient.invalidateQueries({
          queryKey: ["premise", premiseId],
        });
      })
      .catch(() => {
        setFailure();
        flash.error(errorMessage);
      })
      .finally(close);
  };

  return (
    <>
      <BoButton
        fullWidth
        variant="secondary"
        size="extraSmall"
        icon={buttonIcon}
        onClick={open}
      >
        {triggerOpenDialogBtnText}
      </BoButton>
      <BoDialog
        open={isOpen}
        header={header}
        cancelBtnText={cancelBtnText || "Close"}
        confirmBtnText={confirmBtnText}
        onClose={close}
        onConfirm={handleConfirm}
        confirmDisabled={requestMonitor.isPending}
        cancelDisabled={requestMonitor.isPending}
      >
        {confirmMessage}
      </BoDialog>
    </>
  );
};
