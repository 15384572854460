import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { StatusIndicator } from "@common/models/StatusIndicator.model";
import { OpsStoreType } from "@ops-models/types/opsSlicesTypes";

export const selectPremiseStatusIndicatorsState = ({
  premiseStatusIndicators,
}: Pick<OpsStoreType, "premiseStatusIndicators">) => {
  const { data, error, requestStatus } = premiseStatusIndicators;

  let premiseStatusIndicatorsList = null;

  if (data !== null) {
    premiseStatusIndicatorsList = data.map(
      (premiseStatusIndicator) => new StatusIndicator(premiseStatusIndicator)
    );
  }

  return {
    error,
    premiseStatusIndicators: premiseStatusIndicatorsList,
    status: new RequestMonitor(requestStatus),
  };
};
