import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  H2,
  Subtitle1,
} from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { BoCampaignSlugSelect } from "@ops/components/BoCampaignSlugSelect/BoCampaignSlugSelect";
import {
  EmptyOfferMessage,
  SelectContainer,
  SelectLabel,
} from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import { OfferCardRadioGroup } from "@ops/components/OfferCardRadioGroup/OfferCardRadioGroup";
import { WizardComponentName } from "@ops/components/TransferServiceWizard/TransferServiceWizard.types";
import { useTransferServiceWizardContext } from "@ops/hooks/useTransferServiceWizardContext";
import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";

const CenteredRow = styled(FormRow)`
  text-align: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const OfferForm = () => {
  const {
    tosWizardState: { dunsNumber, offerSnapshotId },
    updateTosWizardState,
    setActiveComponentName,
    setNextButtonProps,
    visitedComponents,
    setVisitedComponents,
  } = useTransferServiceWizardContext();

  const [rcid, setRcid] = useState<CampaignSlugs>(
    CampaignSlugs.TransferOfService
  );

  const flash = useRhFlash();

  const [selectedOfferSnapshotId, setSelectedOfferSnapshotId] =
    useState<IdType>(offerSnapshotId);

  const handleOverrideSlug = (slug: CampaignSlugs | null) => {
    setRcid(slug ?? CampaignSlugs.TransferOfService);
  };

  useEffect(() => {
    if (selectedOfferSnapshotId) {
      setNextButtonProps({ disabled: false });
    } else {
      setNextButtonProps({ disabled: true });
    }
  }, [selectedOfferSnapshotId]);

  if (!dunsNumber) {
    flash.error(
      "Unable to fetch offers. DUNS number is not found on new service address."
    );
    return (
      <Container>
        <CenteredRow>
          <H2>The address is in a different service area.</H2>
          <Subtitle1>Choose a new plan.</Subtitle1>
        </CenteredRow>
      </Container>
    );
  }

  const handleOfferSnapshotSelect = (offerSnapshot: OfferSnapshot) => {
    setSelectedOfferSnapshotId(offerSnapshot.id);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedOfferSnapshotId) {
      flash.error("An offerSnapshot must be selected in order to proceed.");
      return;
    }
    const visitComponents = [...visitedComponents];

    visitComponents.push(WizardComponentName.offerForm);
    setVisitedComponents(visitComponents);

    updateTosWizardState({
      offerSnapshotId: selectedOfferSnapshotId,
    });
    setActiveComponentName(WizardComponentName.movingForm);
  };

  return (
    <Container>
      <CenteredRow>
        <H2>The address is in a different service area.</H2>
        <Subtitle1>Choose a new plan.</Subtitle1>
      </CenteredRow>
      <form noValidate onSubmit={onSubmit} id={WizardComponentName.offerForm}>
        <AccessControl>
          <SelectContainer>
            <SelectLabel id="override_campaign_slug">
              Override Campaign Slug
            </SelectLabel>
            <BoCampaignSlugSelect
              ariaLabelledby="override_campaign_slug"
              onChange={handleOverrideSlug}
              value={rcid}
            />
          </SelectContainer>
        </AccessControl>
        {dunsNumber ? (
          <OfferCardRadioGroup
            initialSelectedOfferSnapshotId={selectedOfferSnapshotId}
            onOfferSnapshotSelect={handleOfferSnapshotSelect}
            offerSnapshotListSearchParams={{
              dunsNumber,
              rcid,
            }}
          />
        ) : (
          <EmptyOfferMessage>No plans available.</EmptyOfferMessage>
        )}
      </form>
    </Container>
  );
};
