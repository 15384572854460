import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PaymentMadeData extends EventLogBaseData {
  initiativeBy: string;
  paymentAmount: string;
  paymentDate: string;
  paymentLastFour: string;
  paymentType: string;
  transactionFee: string;
}

export class PaymentMade extends EventLog {
  public readonly paymentAmount: string;
  public readonly paymentDate: string;
  public readonly paymentLastFour: string;
  public readonly paymentType: string;
  public readonly transactionFee: string;
  public readonly initiativeBy: string;

  constructor({
    paymentAmount,
    paymentDate,
    paymentLastFour,
    paymentType,
    transactionFee,
    initiativeBy,
    ...baseEventData
  }: PaymentMadeData) {
    super(baseEventData);

    this.paymentDate = formatMonthDayYear(paymentDate);
    this.paymentType = paymentType;
    this.paymentAmount = paymentAmount;
    this.paymentLastFour = paymentLastFour;
    this.transactionFee = transactionFee;
    this.initiativeBy = initiativeBy;
  }

  override get body(): string | null {
    const agentInfo = this.initiativeBy ? ` - Agent: ${this.initiativeBy}` : "";
    const baseMessage = `${this.name}${agentInfo}\nDate: ${this.paymentDate} - Amount: ${this.paymentAmount} - Type: ${this.paymentType}`;

    if (this.transactionFee) {
      return `${baseMessage}\nTransaction Fee: ${formatCurrency(this.transactionFee)}`;
    } else {
      return baseMessage;
    }
  }
}
