import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { formatEnergyRate } from "@ops/formatters";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ContractExpirationData extends EventLogBaseData {
  newContractAveragePriceAt2000: string;
  newContractExpectedEndDate: string;
  newContractPlanName: string;
  newContractStartDate: string;
}

export class ContractExpiration extends EventLog {
  public readonly newContractAveragePriceAt2000: string;
  public readonly newContractPlanName: string;
  public readonly newContractExpectedEndDate: string;
  public readonly newContractStartDate: string;

  constructor({
    newContractAveragePriceAt2000,
    newContractPlanName,
    newContractExpectedEndDate,
    newContractStartDate,
    ...baseEventData
  }: ContractExpirationData) {
    super(baseEventData);

    this.newContractExpectedEndDate = newContractExpectedEndDate;
    this.newContractAveragePriceAt2000 = newContractAveragePriceAt2000;
    this.newContractPlanName = newContractPlanName;
    this.newContractStartDate = newContractStartDate;
  }

  override get body() {
    if (this.newContractPlanName) {
      return `${this.name}\nNew Plan ${this.newContractPlanName} - Rate @ 2000kwh: ${formatEnergyRate(
        this.newContractAveragePriceAt2000
      )}\nStart Date: ${formatMonthDayYear(this.newContractStartDate)} - End Date: ${formatMonthDayYear(this.newContractExpectedEndDate)}`;
    } else {
      return `${this.name}`;
    }
  }
}
