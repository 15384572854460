import { IdType } from "@common/types/apiTypes";
import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import { ProductContractClaimKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProductContractClaims = (premiseId: IdType) => {
  return useQuery({
    queryFn: () => rhapsodyApi.productContractClaims.list(premiseId),
    queryKey: ProductContractClaimKeys.list(premiseId),
  });
};
