import { contactLogs, eventLogs } from "@common/api/customerUrls";
import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import { rhapsodyUrls } from "@ops-api/api/rhapsodyUrls";
import {
  ContactLogType,
  CreateContactLogType,
} from "@ops-models/types/contactLogTypes";
import { RhapsodyCustomerType } from "@ops-models/types/customerTypes";
import { EventLogResponse } from "@ops-models/types/eventLogTypes";

export const rhapsodyCustomerApi = {
  customers: {
    contactLogs: {
      create: (
        customerId: IdType,
        contactLog: CreateContactLogType
      ): Promise<void> => {
        return ajax.post(contactLogs(customerId), contactLog);
      },
      list: (customerId: IdType): Promise<ContactLogType[]> => {
        return ajax.get(contactLogs(customerId));
      },
    },
    eventLogs: {
      list: (customerId: IdType): Promise<EventLogResponse> => {
        return ajax.get(eventLogs(customerId));
      },
    },
    update: ({
      customerId,
      updateData,
    }: {
      customerId: IdType;
      updateData: RhapsodyCustomerType;
    }): Promise<RhapsodyCustomerType> => {
      return ajax.patch(rhapsodyUrls.customer.update(customerId), updateData);
    },
  },
};
