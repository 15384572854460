import { Prospect } from "@ops-models/models/Prospect.model";

export enum ConfirmationSection {
  billing = "billing",
  contactInfo = "contactInfo",
  marketing = "marketing",
  plan = "plan",
  productAddons = "productAddons",
  serviceAddress = "serviceAddress",
}
export interface ConfirmationCardType {
  errors?: Record<string, boolean>;
  prospect: Prospect;
}
