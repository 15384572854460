import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DeferredPaymentPlanEarlySettlementData
  extends EventLogBaseData {
  outstandingAmount: string;
  status: string;
}

export class DeferredPaymentPlanEarlySettlement extends EventLog {
  public readonly outstandingAmount: string;
  public readonly status: string;

  constructor({
    outstandingAmount,
    status,
    ...baseEventData
  }: DeferredPaymentPlanEarlySettlementData) {
    super({
      ...baseEventData,
      eventText: "Deferred Payment Plan (DPP) Paid Off Early",
    });
    this.outstandingAmount = outstandingAmount;
    this.status = status;
  }

  override get body() {
    return `${this.name} - Settlement Charge of $${this.outstandingAmount} - ${this.status}`;
  }
}
