import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseAttachedDevicesProps {
  premiseId: IdType;
  queryOptions: {
    enabled: boolean;
  };
}

export const useAttachedDevicesQuery = (props: UseAttachedDevicesProps) => {
  const {
    premiseId,
    queryOptions: { enabled = true },
  } = props;

  const query = useQuery({
    enabled,
    queryFn: () => rhapsodyPremiseApi.attachedDevices(premiseId as IdType),
    queryKey: PremiseKeys.attachedDevices(premiseId as IdType),
  });

  return query;
};
