import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-api/api/rhapsodyProspectApi";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProspectCreditCheckRequestType } from "@ops-models/types/prospectTypes";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React, { useCallback } from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  width: 135px;
`;

const CreditCheckCard = styled(BoCard)`
  margin-top: ${rhOpsSpacingPx(1)};
`;

export const InitialCreditCheck = () => {
  const {
    prospect: {
      id,
      email,
      dateOfBirth,
      zipCode,
      addressLine1,
      firstName,
      lastName,
      phone,
      city,
      state,
      acquisitionMedium,
      unitNumber,
    },
    updateProspectState,
  } = useEnrollmentFlowContext();

  const flash = useRhFlash();
  const { fetchProspect } = useProspectInState(id);

  const runInitialCreditCheck = useCallback(() => {
    if (
      !firstName ||
      !lastName ||
      !dateOfBirth ||
      !phone ||
      !email ||
      !addressLine1 ||
      !city ||
      !state ||
      !zipCode
    ) {
      flash.error("Needs more prospect data to run credit check");

      return;
    }
    if (!fetchProspect) {
      return;
    }

    const creditCheckData: ProspectCreditCheckRequestType = {
      acquisitionMedium,
      addressLine1,
      city,
      dateOfBirth,
      email,
      firstName,
      lastName,
      phone,
      prospectId: id,
      state,
      unitNumber,
      zipCode,
    };

    return rhapsodyProspectApi
      .creditScoreEvaluation(creditCheckData)
      .then(() => {
        fetchProspect()
          .then((prospect) => {
            if (prospect) {
              updateProspectState(prospect);
            }
          })
          .catch(() => flash.error("Unable to gather updated prospect data"));
      })
      .catch(() => {
        flash.error("Unable to complete credit check. Try again later");
      });
  }, [
    fetchProspect,
    id,
    firstName,
    lastName,
    dateOfBirth,
    phone,
    email,
    addressLine1,
    city,
    state,
    zipCode,
  ]);

  return (
    <CreditCheckCard>
      <ButtonContainer>
        <BoButtonWithConfirmDialog
          buttonContent="Run Credit Check"
          header="Run Initial Credit Check"
          message="Are you sure you want to run the initial credit check?"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          handleConfirm={runInitialCreditCheck}
        />
      </ButtonContainer>
    </CreditCheckCard>
  );
};
