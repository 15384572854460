import { IdType } from "@common/types/apiTypes";
import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import { InvoiceQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceQueryProps {
  invoiceId: IdType;
}

export const useInvoiceQuery = (props: UseInvoiceQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => rhapsodyApi.invoices.retrieve(invoiceId),
    queryKey: InvoiceQueryKeys.retrieve(invoiceId),
  });
};
