import { premiseApi } from "@common/api/premiseApi";
import { EnrollmentStatus } from "@common/enums/customer.enum";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import {
  BoSelectOptionType,
  BoSelectValue,
} from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { red } from "@ops-design-system/styles/palette.colors";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { ordinal } from "@ops/utils/ordinalHelper";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const RedSubtitle2 = styled(Subtitle2)`
  color: ${red["500"]};
`;

interface EditPYDDProps {
  premise: Premise;
}

interface EditPYDDFormValues {
  pydd: BoSelectValue;
}

const options: BoSelectOptionType[] = [];

const days = Array(28)
  .fill(0)
  .map((_, i) => i + 1);

days.forEach((element) => {
  options.push({
    label: `${ordinal(element)}, monthly`,
    value: element,
  });
});

const StyledSelectWrapper = styled.div`
  max-width: 50%;
`;

export const EditPYDD = ({ premise }: EditPYDDProps) => {
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();
  const flash = useRhFlash();

  const fieldName = "pydd";

  const handleSubmit = ({ pydd }: EditPYDDFormValues) => {
    setPending();

    premiseApi
      .setPickedBillingDueDay(premise.id, Number(pydd))
      .then(() => {
        setSuccess();
        dispatch(premiseRefetch(premise.id));
        close();
      })
      .catch((error: RhApiError) => {
        if (premise.enrollmentStatus === EnrollmentStatus.PRE_ENROLL) {
          flash.error("Unable to set up PYDD, premise is in pending status");
        } else {
          flash.error(error.data.errors.map((e) => e?.detail).join(", "));
        }
        setFailure();
      });
  };

  const meterIsSmart = premise.meter?.isSmt;

  const pyddAlreadyEnrolled = Boolean(premise.pickedBillingDueDay);

  const meterHasSmtData = premise.meter?.smtSyncedAt !== null;

  const allowPYDDEnroll =
    meterIsSmart && !pyddAlreadyEnrolled && meterHasSmtData;

  return (
    <>
      <EditLink onClick={open} disabled={!allowPYDDEnroll} />

      <BoModal open={isOpen} onClose={close}>
        <Form<EditPYDDFormValues>
          onSubmit={handleSubmit}
          render={({ dirty, handleSubmit: handleFormSubmit }) => (
            <form onSubmit={handleFormSubmit}>
              <BoDialogHeader>Pick Your Due Date</BoDialogHeader>
              <BoDialogBody>
                <Subtitle2 as="div">
                  By default, customer will be charged total balance due on the
                  1st day of the month.
                </Subtitle2>
                <br />
                <RedSubtitle2 $fontWeight="SemiBold" as="div">
                  Once selected, this date can not be changed.
                </RedSubtitle2>
                <br />
                <br />
                <StyledSelectWrapper>
                  <BoSelectField
                    ariaLabel="Select Due Date"
                    defaultValue={options[0].value}
                    name={fieldName}
                    options={options}
                  />
                </StyledSelectWrapper>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmBtnText="Update"
                confirmBtnType="submit"
                confirmDisabled={!dirty || requestMonitor.isPending}
                cancelBtnText="Cancel"
                onCancel={close}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
