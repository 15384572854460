import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";
import {
  StatusIndicatorType,
  StatusIndicatorUpdateType,
} from "@common/types/statusIndicatorTypes";
import { statusIndicatorsUrls } from "@ops-api/api/statusIndicatorsUrls";

export const statusIndicatorsApi = {
  premiseAssignedStatusIndicators: {
    list: (premiseId: IdType): Promise<StatusIndicatorType[]> => {
      return ajax.get(
        statusIndicatorsUrls.premiseAssignedStatusIndicators.list(premiseId)
      );
    },
    update: (
      premiseId: IdType,
      premiseIndicators: StatusIndicatorUpdateType[]
    ): Promise<void> => {
      return ajax.post(
        statusIndicatorsUrls.premiseAssignedStatusIndicators.update(premiseId),
        {
          premiseIndicators,
        }
      );
    },
  },
  premiseTypeStatusIndicators: {
    list: (): Promise<StatusIndicatorType[]> => {
      return ajax.get(statusIndicatorsUrls.premiseTypeStatusIndicators.list());
    },
  },
};
