import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ServiceStartDateAssignedData extends EventLogBaseData {
  serviceEndDate: string;
  serviceStartDate: string;
}

export class ServiceStartDateAssigned extends EventLog {
  public readonly serviceEndDate: string;
  public readonly serviceStartDate: string;

  constructor({
    serviceEndDate,
    serviceStartDate,
    ...baseEventData
  }: ServiceStartDateAssignedData) {
    super(baseEventData);

    this.serviceEndDate = formatMonthDayYear(serviceEndDate);
    this.serviceStartDate = formatMonthDayYear(serviceStartDate);
  }

  override get body() {
    return `${this.name} - Service Start Date: ${this.serviceStartDate} - Service End Date: ${this.serviceEndDate}`;
  }
}
