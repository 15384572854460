import { EM_DASH } from "@common/constants/characters.constant";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import { ConfirmationCardType } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import { LANGUAGE_PREFERENCE_TEXT } from "@ops/constants/customer.constants";
import React from "react";

interface ConfirmationContactInfoCardProps extends ConfirmationCardType {}

export const ConfirmationContactInfoCard = ({
  prospect,
  errors,
}: ConfirmationContactInfoCardProps) => {
  const languagePreference = prospect.languagePreference
    ? `${LANGUAGE_PREFERENCE_TEXT[prospect.languagePreference]}`
    : null;

  return (
    <ConfirmationSection label="Contact Info" error={Boolean(errors)}>
      <BoGrid $variant={GridVariants.Grid4_1}>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.firstName}>
            <BoInfoLabel>First Name</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.firstName)}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer $error={errors?.dateOfBirth}>
            <BoInfoLabel>Birthday</BoInfoLabel>
            <BoInfoValue>
              {prospect.dateOfBirth
                ? formatMonthDayYear(prospect.dateOfBirth)
                : EM_DASH}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.lastName}>
            <BoInfoLabel>Last Name</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.lastName)}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer $error={errors?.languagePreference}>
            <BoInfoLabel>Language</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(languagePreference)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.email}>
            <BoInfoLabel>Email</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.email)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.phone}>
            <BoInfoLabel>Phone</BoInfoLabel>
            <BoInfoValue>
              {stringOrEmDash(prospect.formattedPhoneNumber)}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
      </BoGrid>
    </ConfirmationSection>
  );
};
