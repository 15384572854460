import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseDepositAlternativeQueryProps {
  premiseId: IdType;
}

export const usePremiseDepositAlternativeQuery = (
  props: UsePremiseDepositAlternativeQueryProps
) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => rhapsodyPremiseApi.depositAlternative.retrieve(premiseId),
    queryKey: PremiseKeys.DEPOSIT_ALTERNATIVE(premiseId),
    retry: false,
  });
};
