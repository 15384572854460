import { IdType } from "@common/types/apiTypes";
import { selectCustomerState } from "@ops-redux/selectors/customer.selectors";
import { customerFetch } from "@ops-redux/slices/customerSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCustomer = (customerId: IdType) => {
  const { customer, status, error } = useSelector(selectCustomerState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customer?.id !== customerId && !status.isPending) {
      dispatch(customerFetch(customerId));
    }
  }, [customerId]);

  return {
    customer,
    error,
    requestMonitor: status,
  };
};
