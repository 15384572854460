import { ProductType } from "@ops-models/types/productTypes";
import { ProductAddOnCard } from "@ops/components/ProductAddOnCard/ProductAddOnCard";
import React from "react";
import styled from "styled-components";

export const ProductSelectionPageContainer = styled.article`
  flex: 1;
`;

interface ProductSelectionPageProps {
  products: ProductType[];
}
export const ProductSelectionPage = ({
  products,
}: ProductSelectionPageProps) => {
  return (
    <ProductSelectionPageContainer aria-label="Product Selection Page">
      {products.map((product) => {
        return <ProductAddOnCard product={product} key={product.id} />;
      })}
    </ProductSelectionPageContainer>
  );
};
