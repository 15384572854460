import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledAddNewButton = styled(Body2).attrs({
  as: BoTextButton,
})`
  color: ${darkPurple["500"]};
`;

export const StyledFormBody = styled.div`
  & > div {
    margin-bottom: ${rhOpsSpacingPx(3)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
