import { premiseApi } from "@common/api/premiseApi";
import { EdiActions } from "@common/constants/edi.constants";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { EnrollmentType, SWITCH } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckboxField } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { ReactComponent as RotatingArrows } from "@ops-design-system/icons/RotatingArrows.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { EnrollmentRadioGroup } from "@ops/components/EnrollmentRadioGroup/EnrollmentRadioGroup";
import { useQueryClient } from "@tanstack/react-query";
import React, { MouseEventHandler, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

export interface ResubmitEnrollmentFormValues {
  enrollmentType: EnrollmentType;
  newStartDate: string;
  priorityEnrollment: boolean;
}

const resubmitEnrollmentStartDateValidator =
  generateValidationErrorCollector<ResubmitEnrollmentFormValues>({
    newStartDate: [isRequired],
  });

const StyledBody = styled(BoDialogBody)`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${rhOpsSpacingPx(2)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

interface ReSubmitEnrollmentProps {
  premise: Premise;
}

export const ReSubmitEnrollment = ({ premise }: ReSubmitEnrollmentProps) => {
  const [showConfirmMessage, setShowConfirmMessage] = useState<boolean>(false);
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const queryClient = useQueryClient();

  const handleResubmitEnrollment = (
    formValues: ResubmitEnrollmentFormValues
  ) => {
    return premiseApi.ediMessages
      .create(premise.id, {
        actionName: EdiActions.RESUBMIT_ENROLLMENT,
        ...formValues,
      })
      .then(() => {
        dispatch(premiseRefetch(premise.id));
        queryClient.invalidateQueries({
          queryKey: ["premise", premise.id],
        });
      })
      .catch((_error: RhApiError) => {
        flash.error("Issue updating customer account. Please try again.");
      });
  };

  const closeModal = () => {
    setShowConfirmMessage(false);
    close();
  };

  const handleSubmit = (formValues: ResubmitEnrollmentFormValues) => {
    closeModal();
    return handleResubmitEnrollment(formValues);
  };

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
    // because this is `onConfirm`, this will prevent `onSubmit` from being called if we need to show the
    // confirm message.  If not, it is a no-op which then allows the event to bubble up and trigger the form submit
    const shouldShowConfirmMessage =
      premise.isConfirmationRequiredForEnrollmentStatus && !showConfirmMessage;

    if (shouldShowConfirmMessage) {
      event.preventDefault();
      setShowConfirmMessage(true);
    }
  };

  return (
    <>
      <BoButton
        onClick={open}
        variant="secondary"
        size="extraSmall"
        icon={RotatingArrows}
        disabled={premise.isCancelled}
      >
        Re-Submit Enrollment
      </BoButton>
      <BoModal open={isOpen} onClose={closeModal}>
        <Form<ResubmitEnrollmentFormValues>
          initialValues={{
            enrollmentType: premise.enrollmentType,
            newStartDate: premise.confirmedStartDate ?? undefined,
            priorityEnrollment: false,
          }}
          onSubmit={handleSubmit}
          validate={resubmitEnrollmentStartDateValidator}
          render={({
            handleSubmit: formHandleSubmit,
            values: { enrollmentType },
            invalid,
          }) => (
            <form noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Re-Submit Enrollment</BoDialogHeader>
              <StyledBody>
                {!showConfirmMessage ? (
                  <>
                    <BoDateField
                      name="newStartDate"
                      placeholder="New Start Date"
                      label="New Start Date"
                    />
                    <EnrollmentRadioGroup />
                    <BoCheckboxField
                      name="priorityEnrollment"
                      label="Priority Enrollment"
                      disabled={enrollmentType === SWITCH}
                    />
                  </>
                ) : (
                  <>
                    Are you sure you want to resubmit enrollment for this
                    premise?
                  </>
                )}
              </StyledBody>
              <BoDialogButtonFooter
                confirmBtnText="Re-Submit"
                confirmBtnType="submit"
                cancelBtnText="Close"
                onCancel={closeModal}
                onConfirm={handleConfirm}
                confirmDisabled={invalid}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
