import { EnergyUsageGraphV2 } from "@common/components/EnergyUsageGraphV2/EnergyUsageGraphV2";
import { useLatestAvailableDate } from "@common/components/EnergyUsageGraphV2/useLatestAvailableDate";
import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { Premise } from "@common/models/Premise.model";
import {
  SelectedGraph,
  SelectedGraphMode,
  chartTypeMapping,
} from "@common/types/usageTypes";
import {
  BoButtonTab,
  BoButtonTabContainer,
} from "@ops-design-system/components/BoButtonTab/BoButtonTab";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDateInput } from "@ops-design-system/components/BoDate/BoDate";
import { H2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { KwhUsageSummaryType } from "@ops-models/types/usageTypes";
import { BillSummary } from "@ops/components/EnergyUsageChartV2/BillSummary/BillSummary";
import { TotalUsage } from "@ops/components/EnergyUsageChartV2/TotalUsage/TotalUsage";
import { usePremiseKwhUsageDay } from "@ops/hooks/queries/usePremiseKwhUsageDay";
import { usePremiseKwhUsageSummary } from "@ops/hooks/queries/usePremiseKwhUsageSummary";
import React, { useState } from "react";
import styled from "styled-components";

interface EnergyGraphProps {
  premise: Premise;
}

// We don't need to map hourly data so exclude from this mapping
const usageDataMapping: Record<
  Exclude<SelectedGraph, "hourly">,
  keyof KwhUsageSummaryType
> = {
  [SelectedGraph.billPeriod]: "billPeriodData",
  [SelectedGraph.monthly]: "thirtyDaysData",
  [SelectedGraph.yearly]: "oneYearData",
};

const StyledBoButtonTabContainer = styled(BoButtonTabContainer)`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const DateAndTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${rhOpsSpacingPx(2)};

  margin-top: ${rhOpsSpacingPx(2)};
  width: 100%;

  & > *:first-child {
    flex: 20%;
  }

  & > *:last-child {
    flex: 80%;
  }
`;

const graphModeTabs: { label: string; value: SelectedGraphMode }[] = [
  { label: "Both", value: SelectedGraphMode.both },
  {
    label: "Consumption",
    value: SelectedGraphMode.consumption,
  },
  { label: "Surplus", value: SelectedGraphMode.surplus },
];

export const EnergyGraph = (props: EnergyGraphProps) => {
  const { premise } = props;

  const [selectedDate, setSelectedDate] = useState<string>("");

  const [selectedGraph, setSelectedGraph] = useState<SelectedGraph>(
    SelectedGraph.billPeriod
  );

  const [selectedGraphMode, setSelectedGraphMode] = useState<SelectedGraphMode>(
    SelectedGraphMode.both
  );

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalTouGenerationUsageGraph } = featureFlagClient.getFlags([
    ["portalTouGenerationUsageGraph", false],
  ]);

  const kwhUsageSummaryQuery = usePremiseKwhUsageSummary({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt
    ),
    premiseId: premise.id,
  });

  const latestAvailableDate = useLatestAvailableDate(
    kwhUsageSummaryQuery.data?.billPeriodData || [],
    setSelectedDate
  );

  const kwhUsageDayQuery = usePremiseKwhUsageDay({
    enabled: Boolean(
      premise.id &&
        premise.currentOrder &&
        premise.meter?.isSmt &&
        selectedDate !== ""
    ),
    premiseId: premise.id,
    targetDate: selectedDate,
  });

  if (!premise.currentOrder) {
    return null;
  }

  if (kwhUsageSummaryQuery.isError || kwhUsageDayQuery.isError) {
    return <H2>Failed to get usage data</H2>;
  }

  if (kwhUsageSummaryQuery.isPending || kwhUsageDayQuery.isPending) {
    return <BoCircularProgress />;
  }

  const handleDateSelection = (dateString: string | null) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const tabs: { label: string; value: SelectedGraph }[] = [
    { label: "Current Billing Period", value: SelectedGraph.billPeriod },
    { label: "Hourly", value: SelectedGraph.hourly },
    { label: "Past 30 Days", value: SelectedGraph.monthly },
    { label: "Monthly", value: SelectedGraph.yearly },
  ];

  const usageData =
    selectedGraph === SelectedGraph.hourly
      ? kwhUsageDayQuery.data
      : kwhUsageSummaryQuery.data[usageDataMapping[selectedGraph]];

  const { solarEligible, solarGenerationCapped } = premise.currentOrder;

  const showGraphModeTabs =
    portalTouGenerationUsageGraph.value && solarEligible;

  const showGeneration = showGraphModeTabs
    ? selectedGraphMode === SelectedGraphMode.surplus ||
      selectedGraphMode === SelectedGraphMode.both
    : Boolean(solarEligible);

  const showConsumption =
    selectedGraphMode === SelectedGraphMode.consumption ||
    selectedGraphMode === SelectedGraphMode.both;

  return (
    <section>
      {showGraphModeTabs ? (
        <StyledBoButtonTabContainer>
          {graphModeTabs.map(({ label, value }) => (
            <BoButtonTab
              key={label}
              data-active={selectedGraphMode === value}
              onClick={() => {
                setSelectedGraphMode(value);
              }}
            >
              {label}
            </BoButtonTab>
          ))}
        </StyledBoButtonTabContainer>
      ) : null}
      <StyledBoButtonTabContainer>
        {tabs.map(({ label, value }) => (
          <BoButtonTab
            key={label}
            data-active={selectedGraph === value}
            onClick={() => {
              setSelectedGraph(value);
            }}
          >
            {label}
          </BoButtonTab>
        ))}
      </StyledBoButtonTabContainer>

      <DateAndTotalContainer>
        {selectedGraph === SelectedGraph.hourly ? (
          <BoDateInput
            label="Select Date"
            name="usageGraphDatePicker"
            minDate={premise.confirmedStartDate || undefined}
            maxDate={latestAvailableDate}
            value={selectedDate}
            onChange={handleDateSelection}
            aria-label="Select Date"
          />
        ) : null}

        <TotalUsage
          usageData={usageData}
          showConsumption={showConsumption}
          showGeneration={showGeneration}
          showEarned={!solarGenerationCapped}
        />
      </DateAndTotalContainer>

      <EnergyUsageGraphV2
        usageData={usageData}
        chartType={chartTypeMapping[selectedGraph]}
        chartDirection="horizontal"
        showConsumption={showConsumption}
        showGeneration={showGeneration}
        showEarned={!solarGenerationCapped}
        latestAvailableDate={latestAvailableDate}
      />

      <BillSummary premise={premise} />
    </section>
  );
};
