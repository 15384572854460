import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  NewContractSideBarCard,
  SideBarLabel,
  SideBarSectionContainer,
  SideBarValue,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardNewContractSideBar/ContractRenewalWizardNewContractSideBar.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import dayjs from "dayjs";
import React, { PropsWithChildren, useId } from "react";

interface ContractRenewalWizardNewContractSideBarProps {
  offerSnapshotId: IdType;
  premise: Premise;
}

interface SideBarSectionProps {
  inline?: boolean;
  label: string;
}
const SideBarSection = (props: PropsWithChildren<SideBarSectionProps>) => {
  const { label, children, inline = false } = props;

  const sectionId = useId();

  return (
    <SideBarSectionContainer $inline={inline}>
      <SideBarLabel $inline={inline} id={sectionId}>
        {label}
      </SideBarLabel>
      <SideBarValue aria-labelledby={sectionId}>{children}</SideBarValue>
    </SideBarSectionContainer>
  );
};

export const ContractRenewalWizardNewContractSideBar = (
  props: ContractRenewalWizardNewContractSideBarProps
) => {
  const { offerSnapshotId, premise } = props;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
    queryOptions: {
      enabled: Boolean(offerSnapshotId),
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <BoCard>
        <BoCircularProgress />
      </BoCard>
    );
  }

  if (offerSnapshotQuery.isError) {
    return <BoCard>Failed to fetch offer details</BoCard>;
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const termMonthsAndRate = `${offerSnapshot.termMonths} months, ${
    offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
  } Rate`;

  return (
    <NewContractSideBarCard as="section" aria-label="New Contract Information">
      <SideBarSection label="New Contract" />
      <SideBarSection label="Plan">
        <div>{`${offerSnapshot.title},`}</div>
        {offerSnapshot.isTimeOfUse ? (
          <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
        ) : (
          <div>Energy Rate: {formatEnergyRate(offerSnapshot.energyRate())}</div>
        )}
        <div>{termMonthsAndRate}</div>
      </SideBarSection>

      <SideBarSection label="Start Date" inline>
        {formatMonthDayYear(premise?.currentOrder?.renewalStartDate ?? "")}
      </SideBarSection>
      <SideBarSection label="End Date" inline>
        {formatMonthDayYear(
          dayjs(premise.currentOrder?.renewalStartDate)
            .add(offerSnapshot.termMonths, "months")
            .toString()
        )}
      </SideBarSection>
    </NewContractSideBarCard>
  );
};
