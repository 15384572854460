import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { Premise } from "@common/models/Premise.model";
import {
  DisconnectNoticeType,
  PaymentExtensionType,
} from "@common/types/billingTypes";
import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCurrencyInput } from "@ops-design-system/components/BoCurrencyInput/BoCurrencyInput";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useUpdatePaymentExtensionMutation } from "@ops/hooks/mutations/useUpdatePaymentExtension.mutation";
import orderBy from "lodash/orderBy";
import React, { useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const CustomerBalance = styled.span`
  color: ${red["500"]};
`;

const Subheader = styled(Subtitle2).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const SubheaderEntry = styled.div``;

const StyledBody = styled(BoDialogBody)`
  & > * {
    margin-bottom: ${rhOpsSpacingPx(3)};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;

export interface EditPaymentExtensionFormValues {
  amountExtended: number;
  extendToDate: string;
}

interface EditPaymentExtensionFormModalProps {
  currentPaymentExtension: PaymentExtensionType;
  onClose: () => void;
  onSuccess: (newPaymentExtension: PaymentExtensionType) => void;
  pastDueBalance: number;
  premise: Premise;
}

const editPaymentExtensionValidator =
  generateValidationErrorCollector<EditPaymentExtensionFormValues>({
    amountExtended: [isRequired],
  });

export const EditPaymentExtensionForm = ({
  onClose,
  onSuccess,
  pastDueBalance,
  premise,
  currentPaymentExtension,
}: EditPaymentExtensionFormModalProps) => {
  const initialAmountExtended =
    parseFloat(currentPaymentExtension.amountExtended) ?? 0;
  const [newAmountExtended, updateAmountExtended] = useState<number>(
    initialAmountExtended
  );
  const updatePaymentExtensionMutation = useUpdatePaymentExtensionMutation();
  const flash = useRhFlash();

  const onSubmit = ({
    amountExtended,
    extendToDate,
  }: EditPaymentExtensionFormValues) => {
    const paymentExtensionData = {
      amountExtended: amountExtended.toFixed(2),
      extendToDate,
    };

    updatePaymentExtensionMutation.mutate(
      {
        paymentExtensionData,
        paymentExtensionId: currentPaymentExtension.id,
        premiseId: premise.id,
      },
      {
        onError: (error) => {
          flash.error(error.data.errors.map((e) => e?.detail).join("\n"));
        },
        onSuccess: (response) => {
          onSuccess(response);
        },
      }
    );
  };

  const handleAmountExtendedChange = (value: number) => {
    updateAmountExtended(value);
  };

  const disconnectNotices: DisconnectNoticeType[] =
    premise?.disconnectNotices ?? [];

  const disconnectNotice: DisconnectNoticeType = orderBy(
    disconnectNotices,
    "disconnectionDate",
    "desc"
  )[0];

  const disconnectDate = disconnectNotice?.disconnectionDate;

  return (
    <Form<EditPaymentExtensionFormValues>
      initialValues={{
        amountExtended: newAmountExtended,
        extendToDate: currentPaymentExtension.extendToDate ?? undefined,
      }}
      onSubmit={onSubmit}
      validate={editPaymentExtensionValidator}
      render={({ handleSubmit, hasValidationErrors }) => (
        <form noValidate onSubmit={handleSubmit}>
          <BoDialogHeader>Edit Payment Extension</BoDialogHeader>
          <StyledBody>
            <Subheader>
              <SubheaderEntry>
                Past due balance:{" "}
                <CustomerBalance>
                  {formatCurrency(pastDueBalance)}
                </CustomerBalance>
              </SubheaderEntry>
              {disconnectDate && (
                <SubheaderEntry>
                  DNP Expiration Date: {formatMonthDayShortYear(disconnectDate)}
                </SubheaderEntry>
              )}
            </Subheader>
            <BoDivider />
            <BoCurrencyInput
              name="amountExtended"
              onChange={handleAmountExtendedChange}
              value={newAmountExtended}
            >
              Amount Extended
            </BoCurrencyInput>
            <BoDateField
              name="extendToDate"
              placeholder="New Expiration Date"
              label="New Expiration Date"
            />
          </StyledBody>
          <BoDialogButtonFooter
            cancelDisabled={updatePaymentExtensionMutation.isPending}
            confirmDisabled={
              hasValidationErrors || updatePaymentExtensionMutation.isPending
            }
            confirmBtnText="Update"
            cancelBtnText="Cancel"
            confirmBtnType="submit"
            onCancel={onClose}
          />
        </form>
      )}
    />
  );
};
