import { AjaxStatuses, IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { PaymentMethodType } from "@common/types/paymentMethodTypes";
import { PaymentMethodsStateType } from "@ops-models/types/opsSlicesTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const initialPaymentMethodsState =
  Object.freeze<PaymentMethodsStateType>({
    data: null,
    error: null,
    requestStatus: AjaxStatuses.Idle,
  });

export const paymentMethodsRequestedHandler: CaseReducer<
  PaymentMethodsStateType,
  PayloadAction
> = (state) => ({
  ...state,
  requestStatus: AjaxStatuses.Pending,
});

export const paymentMethodsUpdatedHandler: CaseReducer<
  PaymentMethodsStateType,
  PayloadAction<Partial<PaymentMethodType[]>>
> = ({ data, ...state }, { payload }) => ({
  ...state,
  data: merge({}, data, payload),
});

export const paymentMethodsReceivedHandler: CaseReducer<
  PaymentMethodsStateType,
  PayloadAction<PaymentMethodType[]>
> = (state, action) => ({
  data: action.payload,
  error: null,
  requestStatus: AjaxStatuses.Success,
});

export const paymentMethodsErroredHandler: CaseReducer<
  PaymentMethodsStateType,
  PayloadAction<RhApiError>
> = ({ data }, { payload: error }) => ({
  data,
  error,
  requestStatus: AjaxStatuses.Failure,
});

export const paymentMethodsClearHandler: CaseReducer<
  PaymentMethodsStateType,
  PayloadAction
> = () => ({ ...initialPaymentMethodsState });

const paymentMethodReducers = {
  clear: paymentMethodsClearHandler,
  errored: paymentMethodsErroredHandler,
  received: paymentMethodsReceivedHandler,
  requested: paymentMethodsRequestedHandler,
  updated: paymentMethodsUpdatedHandler,
};

const paymentMethodsSlice = createSlice<
  PaymentMethodsStateType,
  typeof paymentMethodReducers
>({
  initialState: initialPaymentMethodsState,
  name: "paymentMethods",
  reducers: paymentMethodReducers,
});

export const paymentMethodsReducer = paymentMethodsSlice.reducer;

export const paymentMethodsRequested = paymentMethodsSlice.actions.requested;
export const paymentMethodsUpdated = paymentMethodsSlice.actions.updated;
export const paymentMethodsReceived = paymentMethodsSlice.actions.received;
export const paymentMethodsErrored = paymentMethodsSlice.actions.errored;
export const paymentMethodsClear = paymentMethodsSlice.actions.clear;

export const paymentMethodsFetch = createAction<IdType>(
  `${paymentMethodsSlice.name}/fetch`
);
