import {
  formatAsLocalTime,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { DeviceConnectionActivityType } from "@ops-models/types/deviceTypes";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Dispatch, SetStateAction } from "react";

interface DeviceConnectionActivityTableProps {
  connectionActivity: DeviceConnectionActivityType[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  setPagination: Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >;
}

export const DeviceConnectionActivityTable = (
  props: DeviceConnectionActivityTableProps
) => {
  const { connectionActivity, pageCount, pageIndex, pageSize, setPagination } =
    props;

  const columnHelper = createColumnHelper<DeviceConnectionActivityType>();

  const columns = [
    columnHelper.display({
      cell: ({ row }) => <b>{formatMonthDayYear(row.original.occurredAt)}</b>,
      header: "Date",
      id: "occurredAt",
    }),
    columnHelper.display({
      cell: ({ row }) => formatAsLocalTime(row.original.occurredAt),
      header: "Time",
      id: "timeOccurredAt",
    }),
    columnHelper.display({
      cell: ({ row }) => row.original.eventType,
      header: "Event Type",
      id: "eventType",
    }),
  ];

  return (
    <BoReactTable<DeviceConnectionActivityType>
      columns={columns}
      data={connectionActivity}
      pagination
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
      tableCaption="Device Connection Activity Table"
    />
  );
};
