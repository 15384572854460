import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CSRContractSwapData extends EventLogBaseData {
  newEndDate: string;
  newPlanPrice: string;
  newPlanTitle: string;
  oldPlanPrice: string;
  oldPlanTitle: string;
}

export class CSRContractSwap extends EventLog {
  public readonly newPlanTitle: string;
  public readonly newPlanPrice: string;
  public readonly oldPlanTitle: string;
  public readonly oldPlanPrice: string;
  public readonly newEndDate: string;

  constructor({
    newPlanTitle,
    newPlanPrice,
    oldPlanTitle,
    oldPlanPrice,
    newEndDate,
    ...baseEventData
  }: CSRContractSwapData) {
    super(baseEventData);

    this.newPlanTitle = newPlanTitle;
    this.newPlanPrice = newPlanPrice;
    this.oldPlanTitle = oldPlanTitle;
    this.oldPlanPrice = oldPlanPrice;
    this.newEndDate = newEndDate ? formatMonthDayYear(newEndDate) : "";
  }

  override get body() {
    return `${this.name} - Swapped from old plan '${this.oldPlanTitle}' to new plan '${this.newPlanTitle}' with contract end date '${this.newEndDate}'`;
  }
}
