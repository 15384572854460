import { PriceScheduleBreakdown } from "@common/components/PriceScheduleBreakdown/PriceScheduleBreakdown";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { BoDialog } from "@ops-design-system/components/BoDialog/BoDialog";
import React from "react";

interface TOUPriceScheduleModalProps {
  closeModal: () => void;
  offerSnapshot: OfferSnapshot;
}

export const TOUPriceScheduleModal = ({
  offerSnapshot,
  closeModal,
}: TOUPriceScheduleModalProps) => {
  return (
    <BoDialog
      size="medium"
      open
      header={offerSnapshot.title}
      onClose={closeModal}
    >
      <PriceScheduleBreakdown
        title={
          offerSnapshot.isTimeOfUseGeneration
            ? "Energy & Solar Buyback Price Breakdown"
            : "Energy Price Breakdown"
        }
        offerSnapshot={offerSnapshot}
      />
    </BoDialog>
  );
};
