import { ajax } from "@common/services/ajax";
import { rhapsodyCampaignUrls } from "@ops-api/api/rhapsodyCampaignUrls";
import { CampaignSlugType } from "@ops-models/types/campaignTypes";

export const rhapsodyCampaignApi = {
  campaignSlugs: {
    retrieve: (filter?: string): Promise<CampaignSlugType> => {
      return ajax.get(rhapsodyCampaignUrls.campaignSlugs.retrieve(), {
        params: {
          slug: filter,
        },
      });
    },
  },
};
