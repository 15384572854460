import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface PickYourOwnDueDateData extends EventLogBaseData {
  pydd: string;
}

export class PickYourOwnDueDate extends EventLog {
  public readonly pydd: string;

  constructor({ pydd, ...baseEventData }: PickYourOwnDueDateData) {
    super(baseEventData);

    this.pydd = pydd;
  }

  override get body() {
    return `${this.name} - PYDD: ${this.pydd}`;
  }
}
