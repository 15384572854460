import { premiseApi } from "@common/api/premiseApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidDateTodayOrFuture } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { Customer } from "@common/models/Customer.model";
import { Premise } from "@common/models/Premise.model";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { customerRefetch } from "@ops-redux/slices/customerSlice";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { PremiseErrorCodes } from "@ops/constants/errorCodes.constants";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface ServiceEndDateFormModalProps {
  customer: Customer;
  header: string;
  onClose: () => void;
  open: boolean;
  premise: Premise;
}

interface UpdateServiceEndFormValues {
  serviceEndDate: string;
}

const updateServiceEndFormValidator =
  generateValidationErrorCollector<UpdateServiceEndFormValues>({
    newServiceEndDate: [isRequired, isValidDateTodayOrFuture],
  });

export const ServiceEndDateFormModal = ({
  open,
  onClose,
  premise,
  customer,
  header,
}: ServiceEndDateFormModalProps) => {
  const [{ requestMonitor }, { setPending, setFailure, setSuccess }] =
    useAjaxState();
  const flash = useRhFlash();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    setSuccess();
    flash.success("Successfully updated the service end date.");
    dispatch(premiseRefetch(premise.id));
    dispatch(customerRefetch(customer.id));
    queryClient.invalidateQueries({
      queryKey: ["premise", premise.id],
    });

    onClose();
  };
  const handleError = (error: RhApiError) => {
    setFailure();
    flash.error(errorMessage(error.data.errorCode));
  };
  const errorMessage = (errorCode: string | null) => {
    switch (errorCode) {
      case PremiseErrorCodes.SERVICE_END_DATE_INVALID: {
        return "Invalid date given. Unable to update service end date on premise.";
      }
      case PremiseErrorCodes.INVALID_PREMISE_STATE_FOR_OPERATION: {
        return "The update you requested cannot be performed because the current premise state does not allow it.";
      }
      default: {
        return "Issue updating service start date for this premise. Please try again.";
      }
    }
  };

  const onSubmit = ({ serviceEndDate }: UpdateServiceEndFormValues) => {
    setPending();
    premiseApi
      .updateServiceEndDate(premise.id, serviceEndDate)
      .then(handleSuccess)
      .catch(handleError);
  };

  return (
    <BoModal open={open} onClose={onClose}>
      <Form<UpdateServiceEndFormValues>
        onSubmit={onSubmit}
        validators={updateServiceEndFormValidator}
        validateOnBlur
        render={({ dirty, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <BoDialogHeader>{header}</BoDialogHeader>
            <BoDialogBody>
              <BoDateField
                name="serviceEndDate"
                placeholder="mm/dd/yyyy"
                label="New Service End Date"
              />
            </BoDialogBody>
            <BoDialogButtonFooter
              confirmDisabled={!dirty || requestMonitor.isPending}
              confirmBtnText="Update"
              confirmBtnType="submit"
              cancelBtnText="Close"
              onCancel={onClose}
            />
          </form>
        )}
      />
    </BoModal>
  );
};
