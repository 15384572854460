import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { AccountSummary } from "@ops/models/AccountSummary.model";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export function useAccountSummaryQuery(premiseId: IdType) {
  return useQuery({
    queryFn: () => rhapsodyPremiseApi.accountSummary.retrieve(premiseId),
    queryKey: PremiseKeys.ACCOUNT_SUMMARY(premiseId),
    select: (r) => {
      return new AccountSummary(r);
    },
  });
}

export function useInvalidateAccountSummaryQuery() {
  const queryClient = useQueryClient();

  return (premiseId: IdType) => {
    return queryClient.invalidateQueries({
      queryKey: PremiseKeys.ACCOUNT_SUMMARY(premiseId),
    });
  };
}
