import {
  SupportedPortalLanguage,
  SupportedPortalLanguageLabel,
  englishLanguageCode,
  spanishLanguageCode,
} from "@common/types/languageTypes";

export const LANGUAGE_PREFERENCE_TEXT: Record<
  SupportedPortalLanguage,
  SupportedPortalLanguageLabel
> = {
  [englishLanguageCode]: "English",
  [spanishLanguageCode]: "Spanish",
};
