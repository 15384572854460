import { BoTextArea } from "@ops-design-system/components/BoTextArea/BoTextArea";
import { FieldValidator } from "final-form";
import isArray from "lodash/isArray";
import React, { FC } from "react";
import { useField } from "react-final-form";

export interface BoTextAreaFieldProps<Type> {
  autoFocus?: boolean;
  disabled?: boolean;
  name: string;
  placeholder?: string;
  requiredIndicator?: boolean;
  validate?: FieldValidator<Type>;
}

export const BoTextAreaField: FC<
  React.PropsWithChildren<BoTextAreaFieldProps<string>>
> = ({
  autoFocus,
  children,
  disabled,
  name,
  placeholder,
  requiredIndicator,
  validate,
}) => {
  const { input, meta } = useField<string>(name, {
    type: "text",
    validate,
  });
  const hasErrors =
    (meta.touched && Boolean(meta.error)) ||
    (meta.submitFailed && Boolean(meta.submitError));
  let errorMessage;

  if (hasErrors && !meta.dirtySinceLastSubmit) {
    if (isArray(meta.error)) {
      errorMessage = meta.error.join(" ");
    } else {
      errorMessage = meta.error || meta.submitError;
    }
  }

  return (
    <BoTextArea
      {...input}
      autoFocus={autoFocus}
      disabled={disabled || meta.submitting}
      hasErrors={hasErrors}
      errorMessage={errorMessage}
      placeholder={placeholder}
      requiredIndicator={requiredIndicator}
    >
      {children}
    </BoTextArea>
  );
};
