import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface SMSDeliveredData extends EventLogBaseData {
  templateName: string;
  toPhoneNumber: string;
}

export class SMSDelivered extends EventLog {
  public readonly phoneNumber: string;
  public readonly templateName: string;

  constructor({
    toPhoneNumber,
    templateName,
    ...baseEventData
  }: SMSDeliveredData) {
    super(baseEventData);

    this.phoneNumber = toPhoneNumber;
    this.templateName = templateName;
  }

  override get body() {
    return `${this.name} - ${this.phoneNumber} - ${this.templateName}`;
  }
}
