import {
  formatDateOfBirth,
  unformatDateOfBirth,
} from "@common/forms/formatters";
import { maskPhone } from "@common/forms/phone.mask";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import {
  isValidAdultBirthday,
  isValidEmail,
  isValidPhoneNumber,
} from "@common/forms/validators";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoPhoneField } from "@ops-design-system/components/BoPhoneField/BoPhoneField";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { FormFlexRow } from "@ops-design-system/styles/common.styles";
import { ProspectType } from "@ops-models/types/prospectTypes";
import { ProspectEditorForm } from "@ops/components/ProspectEditor/ProspectEditorForm";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { LANGUAGE_PREFERENCE_TEXT } from "@ops/constants/customer.constants";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

type ContactInfoPageField =
  | "firstName"
  | "lastName"
  | "email"
  | "phone"
  | "dateOfBirth"
  | "languagePreference";

interface ContactInfoPageFormValues
  extends Pick<ProspectType, ContactInfoPageField> {}

const InputContainer = styled.div`
  flex: 1;
  padding-left: 10px;
  &:first-child {
    padding-left: 0px;
    padding-right: 10px;
  }
`;

const blankContactInfoValues: ContactInfoPageFormValues = {
  dateOfBirth: null,
  email: null,
  firstName: null,
  languagePreference: null,
  lastName: null,
  phone: null,
};

const contactInfoFormValidator =
  generateValidationErrorCollector<ContactInfoPageFormValues>({
    dateOfBirth: [isValidAdultBirthday],
    email: [isValidEmail],
    phone: [isValidPhoneNumber],
  });

export const ContactInfoForm = () => {
  const { prospect, updateProspectState } = useEnrollmentFlowContext();
  const flash = useRhFlash();
  const {
    firstName,
    lastName,
    email,
    phone,
    languagePreference,
    creditCheckSummary,
  } = prospect;
  const dateOfBirth = prospect.dateOfBirth
    ? formatMonthDayYear(prospect.dateOfBirth)
    : null;

  const onSubmit = ({
    dateOfBirth: givenDateOfBirth,
    languagePreference: givenLanguagePreference,
    ...formValues
  }: ContactInfoPageFormValues) => {
    const sanitizedDateOfBirth = givenDateOfBirth
      ? unformatDateOfBirth(givenDateOfBirth)
      : null;
    let rerunCreditCheck = false;

    if (email && creditCheckSummary && email !== formValues.email) {
      rerunCreditCheck = true;
    }

    updateProspectState({
      ...blankContactInfoValues,
      creditCheckSummary: rerunCreditCheck ? null : creditCheckSummary,
      dateOfBirth: sanitizedDateOfBirth,
      languagePreference: givenLanguagePreference,
      ...formValues,
    });
    if (rerunCreditCheck) {
      flash.success("Please Re-run the credit check. The email has changed.");
    }
  };
  const options: BoSelectOptionType[] = Object.entries(
    LANGUAGE_PREFERENCE_TEXT
  ).map(([code, name]) => ({
    label: name,
    value: code,
  }));

  return (
    <Form<ContactInfoPageFormValues>
      initialValues={{
        dateOfBirth,
        email,
        firstName,
        languagePreference,
        lastName,
        phone,
      }}
      validate={contactInfoFormValidator}
      onSubmit={onSubmit}
      render={() => {
        return (
          <ProspectEditorForm id={TabType.contactInfo}>
            <BoCard>
              <FormFlexRow>
                <InputContainer>
                  <BoTextField name="firstName">First Name</BoTextField>
                </InputContainer>
                <InputContainer>
                  <BoTextField name="lastName">Last Name</BoTextField>
                </InputContainer>
              </FormFlexRow>
              <FormFlexRow>
                <InputContainer>
                  <BoTextField name="email">Email</BoTextField>
                </InputContainer>
                <InputContainer>
                  <BoPhoneField
                    name="phone"
                    format={maskPhone}
                    placeholder="555-555-5555"
                  >
                    Phone
                  </BoPhoneField>
                </InputContainer>
              </FormFlexRow>
              <FormFlexRow>
                <InputContainer>
                  <BoTextField
                    name="dateOfBirth"
                    format={formatDateOfBirth}
                    placeholder="MM/DD/YYYY"
                    maxLength={10}
                  >
                    Birthday
                  </BoTextField>
                </InputContainer>
                <InputContainer>
                  <BoSelectField
                    label="Language"
                    placeholder="Choose language..."
                    name="languagePreference"
                    options={options}
                  />
                </InputContainer>
              </FormFlexRow>
            </BoCard>
          </ProspectEditorForm>
        );
      }}
    />
  );
};
