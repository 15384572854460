import { ajax } from "@common/services/ajax";
import {
  AccountSummaryResponseType,
  PaginationEndpointResponseType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { PremiseType } from "@common/types/premiseTypes";
import { ThermostatSearchOptions } from "@common/types/thermostatTypes";
import { UsageType } from "@common/types/usageTypes";
import { zodIsValid } from "@common/utils/zodHelper";
import { rhapsodyPremiseUrls } from "@ops-api/api/rhapsodyPremiseUrls";
import {
  DeviceConnectionActivityType,
  DeviceDemandResponseActivityType,
  DevicesType,
} from "@ops-models/types/deviceTypes";
import {
  RhapsodyOrderType,
  RhapsodyOrderZod,
} from "@ops-models/types/orderTypes";
import {
  CreatePaymentDataType,
  CreatePaymentResponseType,
} from "@ops-models/types/paymentTypes";
import { PremiseDepositAlternativeType } from "@ops-models/types/premiseTypes";
import { OneClickRenewalOfferSnapshotResponse } from "@ops-models/types/renewalTypes";
import { TransferServiceDataType } from "@ops-models/types/transferServiceTypes";
import {
  BillForecastSummaryType,
  KwhUsageSummaryType,
} from "@ops-models/types/usageTypes";
import { z } from "zod";

export const rhapsodyPremiseApi = {
  accountSummary: {
    retrieve: (premiseId: IdType): Promise<AccountSummaryResponseType> => {
      return ajax.get(rhapsodyPremiseUrls.accountSummary.retrieve(premiseId));
    },
  },
  attachedDevices: (premiseId: IdType) =>
    ajax.get<DevicesType>(rhapsodyPremiseUrls.attachedDevices(premiseId)),
  billForecastSummary: (premiseId: IdType): Promise<BillForecastSummaryType> =>
    ajax.get(rhapsodyPremiseUrls.billForecastSummary(premiseId)),
  communicationPreferences: ({
    premiseId,
    paperless,
  }: {
    paperless: boolean;
    premiseId: IdType;
  }) =>
    ajax.post<null>(rhapsodyPremiseUrls.communicationPreferences(premiseId), {
      paperless,
    }),
  createPayment: (
    premiseId: IdType,
    payload: CreatePaymentDataType
  ): Promise<CreatePaymentResponseType> =>
    ajax.post(rhapsodyPremiseUrls.createPayment(premiseId), payload),
  depositAlternative: {
    retrieve: (premiseId: IdType): Promise<PremiseDepositAlternativeType> => {
      return ajax.get(
        rhapsodyPremiseUrls.depositAlternative.retrieve(premiseId)
      );
    },
  },
  kwhUsageDay: (
    premiseId: IdType,
    targetDate: string
  ): Promise<UsageType[]> => {
    return ajax.get(rhapsodyPremiseUrls.kwhUsageDay(premiseId), {
      params: {
        targetDate,
      },
    });
  },
  kwhUsageSummary: (premiseId: IdType): Promise<KwhUsageSummaryType> => {
    return ajax.get(rhapsodyPremiseUrls.kwhUsageSummary(premiseId));
  },
  oneClickRenewalOfferSnapshots: (
    premiseId: IdType
  ): Promise<OneClickRenewalOfferSnapshotResponse> =>
    ajax.get(rhapsodyPremiseUrls.oneClickRenewalOfferSnapshots(premiseId)),
  orders: async (premiseId: IdType) => {
    const result = await ajax.get<RhapsodyOrderType[]>(
      rhapsodyPremiseUrls.orders(premiseId)
    );

    const schema = z.array(RhapsodyOrderZod);

    zodIsValid(schema, result);

    return result;
  },
  quickPay: {
    sendPaymentLink: (premiseId: IdType): Promise<void> => {
      return ajax.post(
        rhapsodyPremiseUrls.quickPay.sendPaymentLink(premiseId),
        {}
      );
    },
  },
  renewContract: ({
    premiseId,
    paperless,
    offerSnapshotId,
  }: {
    offerSnapshotId: IdType;
    paperless: boolean;
    premiseId: IdType;
  }) =>
    ajax.post<null>(rhapsodyPremiseUrls.renewContract(premiseId), {
      offerSnapshotId,
      paperless,
    }),
  setAutopay: ({
    premiseId,
    value,
    paymentMethodId,
  }: {
    paymentMethodId?: string;
    premiseId: IdType;
    value: boolean;
  }) =>
    ajax.patch<PremiseType>(rhapsodyPremiseUrls.setAutopay(premiseId), {
      paymentMethodId,
      value,
    }),
  thermostatConnectionActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: ThermostatSearchOptions;
  }) =>
    ajax.get<PaginationEndpointResponseType<DeviceConnectionActivityType>>(
      rhapsodyPremiseUrls.thermostatConnectionActivity(premiseId),
      {
        params: {
          ...searchOptions,
        },
      }
    ),
  thermostatDemandResponseActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: ThermostatSearchOptions;
  }) =>
    ajax.get<PaginationEndpointResponseType<DeviceDemandResponseActivityType>>(
      rhapsodyPremiseUrls.thermostatDemandResponseActivity(premiseId),
      {
        params: {
          ...searchOptions,
        },
      }
    ),
  transferService: (
    premiseId: IdType,
    transferServiceData: TransferServiceDataType
  ): Promise<PremiseType> =>
    ajax.post<PremiseType>(
      rhapsodyPremiseUrls.transferService(premiseId),
      transferServiceData
    ),
};
