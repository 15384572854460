import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import { ProductKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProducts = () => {
  return useQuery({
    queryFn: rhapsodyApi.products.list,
    queryKey: ProductKeys.PRODUCTS,
  });
};
