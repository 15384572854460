import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { IdType } from "@common/types/apiTypes";
import { isOps } from "@common/utils/authenticationHelpers";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { H2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { BoCampaignSlugSelect } from "@ops/components/BoCampaignSlugSelect/BoCampaignSlugSelect";
import {
  EmptyOfferMessage,
  SelectContainer,
  SelectLabel,
} from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import { OfferCardRadioGroup } from "@ops/components/OfferCardRadioGroup/OfferCardRadioGroup";
import { WizardComponentName } from "@ops/components/WinbackWizard/winbackWizard.enums";
import { useWinbackWizardContext } from "@ops/hooks/useWinbackWizardContext";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import styled from "styled-components";

const CenteredRow = styled(FormRow)`
  text-align: center;
`;

const Container = styled(BoFlexBox).attrs({
  $flexDirection: "column",
  $justifyContent: "center",
})``;
const Header = styled(H2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

export const WinbackChoosePlan = () => {
  const {
    premise,
    winbackWizardState: { offerSnapshotId },
    updateWinbackWizardState,
    setActiveComponentName,
    setNextButtonProps,
  } = useWinbackWizardContext();

  const { groups } = useContext(AuthGroupsContext);
  const isOpsUser = isOps(groups);

  const [rcid, setRcid] = useState<CampaignSlugs>(
    CampaignSlugs.InboundTelemarketing
  );

  const flash = useRhFlash();

  const [selectedOfferSnapshotId, setSelectedOfferSnapshotId] =
    useState<IdType>(offerSnapshotId);

  useEffect(() => {
    const disableNext = !selectedOfferSnapshotId;

    setNextButtonProps({ disabled: disableNext });
  }, [selectedOfferSnapshotId]);

  const dunsNumber = premise.meter?.duns;

  if (!dunsNumber) {
    flash.error("Unable to fetch offers. DUNS number is not found.");
    return null;
  }

  const handleOfferSnapshotSelect = (offerSnapshot: OfferSnapshot) => {
    setSelectedOfferSnapshotId(offerSnapshot.id);
  };

  const handleOverrideSlug = (slug: CampaignSlugs | null) => {
    setRcid(slug ?? CampaignSlugs.Winback);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedOfferSnapshotId) {
      flash.error("An offerSnapshot must be selected in order to proceed.");
      return;
    }
    setActiveComponentName(WizardComponentName.winbackStartDateForm);
    updateWinbackWizardState({
      offerSnapshotId: selectedOfferSnapshotId,
    });
  };

  return (
    <Container>
      <CenteredRow>
        <Header>Choose a new plan</Header>
      </CenteredRow>
      <form
        noValidate
        onSubmit={onSubmit}
        id={WizardComponentName.winbackChoosePlan}
      >
        <SelectContainer>
          <SelectLabel id="override_campaign_slug">
            Override Campaign Slug
          </SelectLabel>
          <BoCampaignSlugSelect
            ariaLabelledby="override_campaign_slug"
            onChange={handleOverrideSlug}
            value={rcid}
            filter={
              isOpsUser
                ? ""
                : [
                    CampaignSlugs.Winback,
                    CampaignSlugs.InboundTelemarketing,
                  ].join(",")
            }
          />
        </SelectContainer>
        {dunsNumber ? (
          <OfferCardRadioGroup
            initialSelectedOfferSnapshotId={offerSnapshotId}
            onOfferSnapshotSelect={handleOfferSnapshotSelect}
            offerSnapshotListSearchParams={{
              dunsNumber,
              rcid,
            }}
          />
        ) : (
          <EmptyOfferMessage>No plans available.</EmptyOfferMessage>
        )}
      </form>
    </Container>
  );
};
