import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import {
  Body1,
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@ops/components/LegalDocumentLinks/LegalDocumentLinks";
import {
  AveragePriceGrid,
  AveragePriceGridItem,
  CardDivider,
  CardFlexRow,
  CardRow,
  CardTitle,
  OfferCardContainer,
  OfferInfoCard,
  StyledBody,
  StyledCardCallout,
  TitleContainer,
} from "@ops/components/OfferCard/OfferCard.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import React from "react";

interface OfferCardProps {
  active?: boolean;
  className?: string;
  name?: string;
  offerSnapshot: OfferSnapshot;
  onSelect?: (offerSnapshot: OfferSnapshot) => void;
}

interface OfferInfoProps {
  info: string;
  label: string;
}

const OfferInfo = (props: OfferInfoProps) => {
  const { label, info } = props;

  return (
    <OfferInfoCard>
      <Body3 $fontWeight="SemiBold" as="div">
        {label}
      </Body3>
      <Body2 as="div">{stringOrEmDash(info)}</Body2>
    </OfferInfoCard>
  );
};

export const OfferCard = (props: OfferCardProps) => {
  const { offerSnapshot, onSelect, active = false, className, name } = props;

  const {
    title,
    termMonths,
    isTimeOfUse,
    id: offerSnapshotId,
    rhythmKwhRate,
    solarEligible,
    priceTierSnapshots,
    solarCreditKwhRate,
    promo,
    campaignSlug,
    price1000Kwh,
    price2000Kwh,
    price500Kwh,
    isAutoPayPaperlessDiscountOffer,
  } = offerSnapshot;

  return (
    <OfferCardContainer
      $active={active}
      $calloutColor={offerSnapshot.calloutBackgroundColor()}
      className={className}
      onClick={() => onSelect?.(offerSnapshot)}
      data-active={active}
      aria-label={`${offerSnapshot.title} -  ${offerSnapshot.id}`}
    >
      {offerSnapshot.hasCallout() && (
        <StyledCardCallout
          $calloutBackgroundColor={offerSnapshot.calloutBackgroundColor()}
        >
          <Body1 $fontWeight="SemiBold">
            {offerSnapshot.calloutText("en")}
          </Body1>
        </StyledCardCallout>
      )}
      <StyledBody>
        <TitleContainer>
          <CardTitle>{title}</CardTitle>
          {isAutoPayPaperlessDiscountOffer ? (
            <Body3>Paperless and Auto Pay Required</Body3>
          ) : null}
        </TitleContainer>
        <CardFlexRow>
          <OfferInfo label="Term" info={`${termMonths} months`} />
          <div>
            <Body3 $fontWeight="SemiBold" as="div">
              Energy Rate
            </Body3>
            {isTimeOfUse ? (
              <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshotId} />
            ) : (
              <Body2 as="div">
                {stringOrEmDash(formatEnergyRate(rhythmKwhRate, 5))}
              </Body2>
            )}
            {solarEligible &&
            (!priceTierSnapshots || priceTierSnapshots.length === 0) ? (
              <OfferInfo
                label="Buyback Energy Rate"
                info={formatEnergyRate(solarCreditKwhRate, 5)}
              />
            ) : (
              // Add a blank component to keep the layout consistent
              <OfferInfoCard />
            )}
          </div>
        </CardFlexRow>
        <CardRow>
          <OfferInfo
            label="OfferSnapshot Promo Code"
            info={promo?.code ?? ""}
          />
        </CardRow>
        <CardRow>
          <OfferInfo label="Campaign" info={campaignSlug ?? ""} />
        </CardRow>
        <CardDivider />
        <CardRow>
          <Body3 $fontWeight="SemiBold" as="div">
            Average price per kWh
          </Body3>
          <AveragePriceGrid>
            <AveragePriceGridItem $row={1} $column={1}>
              <Body2>500</Body2>
            </AveragePriceGridItem>
            <AveragePriceGridItem $row={1} $column={2}>
              <Body2>{formatDollarsToCents(price500Kwh)}</Body2>
            </AveragePriceGridItem>

            <AveragePriceGridItem $row={2} $column={1}>
              <Body2>1000</Body2>
            </AveragePriceGridItem>
            <AveragePriceGridItem $row={2} $column={2}>
              <Body2>{formatDollarsToCents(price1000Kwh)}</Body2>
            </AveragePriceGridItem>

            <AveragePriceGridItem $row={3} $column={1}>
              <Body2>2000</Body2>
            </AveragePriceGridItem>
            <AveragePriceGridItem $row={3} $column={2}>
              <Body2>{formatDollarsToCents(price2000Kwh)}</Body2>
            </AveragePriceGridItem>
          </AveragePriceGrid>
        </CardRow>
        <CardDivider />
        <CardRow>
          <div>
            <EFLLink
              offerId={offerSnapshotId}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Electricity Facts Label
            </EFLLink>
          </div>
          <div>
            <TOSLink
              tosUrl={offerSnapshot.rhythmTosLink("en")}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Terms of Service
            </TOSLink>
          </div>
          <div>
            <YRACLink
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Your Rights as a Customer
            </YRACLink>
          </div>
        </CardRow>

        {onSelect ? (
          <>
            <CardDivider />
            <CardFlexRow $centered>
              <BoRadioInput
                id={`offer__${offerSnapshotId}`}
                onClick={() => onSelect(offerSnapshot)}
                checked={active}
                name={name}
                value={offerSnapshotId}
                title={offerSnapshotId}
              />
            </CardFlexRow>
          </>
        ) : null}
      </StyledBody>
    </OfferCardContainer>
  );
};
