import { IdType } from "@common/types/apiTypes";
import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import { DeviceType } from "@ops-models/types/deviceTypes";
import { deviceSelectorAtom } from "@ops/components/DeviceSelector/DeviceSelector.atoms";
import { DeviceSelectorListItem } from "@ops/components/DeviceSelector/DeviceSelectorListItem";
import {
  StyledChevronIconContainer,
  StyledOpsSelectorContainer,
  StyledOpsSelectorMenu,
  StyledOpsSelectorMenuContainer,
  StyledOpsSelectorMenuItem,
  StyledOpsSelectorMenuItemLink,
  StyledSelectedItemShown,
} from "@ops/components/OpsSelector/OpsSelector.styled";
import { useClickOutsideViewToggle } from "@ops/hooks/useClickOutsideViewToggle";
import { useAtom } from "jotai";
import React from "react";

interface DeviceSelectorProps {
  devices: Array<DeviceType> | undefined;
  premiseId: IdType;
}

export const DeviceSelector = (props: DeviceSelectorProps) => {
  const { devices, premiseId } = props;
  const { ref, isOpen, setIsOpen } =
    useClickOutsideViewToggle<HTMLDivElement>();
  const [selectedDevices, setSelectedDevices] = useAtom(deviceSelectorAtom);

  if (
    !devices ||
    !selectedDevices ||
    (selectedDevices && !selectedDevices[premiseId])
  ) {
    return null;
  }

  const toggleDeviceSelector = () => {
    setIsOpen((prev) => !prev);
  };

  const trackDeviceChanged = (device: DeviceType) => {
    setSelectedDevices((prev) => ({ ...prev, [premiseId]: device }));

    return toggleDeviceSelector();
  };

  const hasMultipleDevices = devices && devices.length > 1;

  return (
    <StyledOpsSelectorContainer ref={ref}>
      <StyledSelectedItemShown
        aria-controls="DeviceSelectorMenu"
        aria-haspopup="true"
        onClick={toggleDeviceSelector}
        $hasMenuItems={Boolean(hasMultipleDevices)}
        aria-label="DeviceSelector"
        id="DeviceSelector"
      >
        <DeviceSelectorListItem device={selectedDevices[premiseId]} />
        {hasMultipleDevices ? (
          <StyledChevronIconContainer
            $isOpen={isOpen}
            data-testid="DeviceSelector__ChevronDownIcon"
          >
            <ChevronDown height="10px" width="10px" />
          </StyledChevronIconContainer>
        ) : null}
      </StyledSelectedItemShown>
      {isOpen && hasMultipleDevices ? (
        <StyledOpsSelectorMenuContainer
          id="DeviceSelectorMenu"
          aria-hidden={!isOpen}
        >
          <StyledOpsSelectorMenu role="menu" aria-label="DeviceSelectorMenu">
            {devices.map((device) => (
              <StyledOpsSelectorMenuItem
                key={device.deviceId}
                role="menuitem"
                aria-label={device.deviceName}
              >
                <StyledOpsSelectorMenuItemLink
                  as="div"
                  onClick={() => trackDeviceChanged(device)}
                >
                  <DeviceSelectorListItem
                    device={device}
                    isSelectedDevice={
                      device.deviceId === selectedDevices[premiseId].deviceId
                    }
                    isInMenu
                  />
                </StyledOpsSelectorMenuItemLink>
              </StyledOpsSelectorMenuItem>
            ))}
          </StyledOpsSelectorMenu>
        </StyledOpsSelectorMenuContainer>
      ) : null}
    </StyledOpsSelectorContainer>
  );
};
