import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { RhApiErrorDetail, RhError } from "@common/types/errorTypes";
import { identity } from "@common/utils/genericFunctions";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { useCustomerUpdateMutation } from "@ops/hooks/mutations/useCustomerUpdate.mutation";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const NameDetailsContainer = styled.div`
  ${editDialogContentsCss}
`;

const FormWrapper = styled.div``;

interface EditNameFormValues {
  firstName: string;
  lastName: string;
}

interface EditCustomerNameProps {
  customer: Customer;
}

const validators = generateValidationErrorCollector<EditNameFormValues>({
  firstName: [isRequired],
  lastName: [isRequired],
});

const buildErrorMessage = (errors: RhApiErrorDetail[]): string => {
  if (!errors) {
    return "";
  }
  return (
    errors
      // eslint-disable-next-line array-callback-return
      .map((err): string | undefined => {
        switch (err?.code) {
          case "rhythm.customer.first_name.blank": {
            return "First name can't be blank.";
          }
          case "rhythm.customer.last_name.blank": {
            return "Last name can't be blank.";
          }
        }
      })
      .filter(identity)
      .join(" ")
  );
};

export const EditCustomerName = ({ customer }: EditCustomerNameProps) => {
  const { open, close, isOpen } = useModalState();
  const flash = useRhFlash();

  const updateCustomerMutation = useCustomerUpdateMutation();

  const handleSubmit = ({ firstName, lastName }: EditNameFormValues) => {
    updateCustomerMutation.mutate(
      {
        customerId: customer.id,
        updateData: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        },
      },
      {
        onError: (error: RhError) => {
          flash.error(
            `There was a problem updating the customer's name. ${buildErrorMessage(
              error.data.errors
            )}`
          );
        },
        onSuccess: () => {
          close();
        },
      }
    );
  };

  return (
    <>
      <EditLink aria-label="Edit Name" onClick={open} />
      <BoModal open={isOpen} onClose={close}>
        <Form<EditNameFormValues>
          initialValues={{
            firstName: customer.firstName,
            lastName: customer.lastName,
          }}
          onSubmit={handleSubmit}
          validate={validators}
          validateOnBlur
          render={({ dirty, valid, handleSubmit: formHandleSubmit }) => (
            <form noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Edit Name</BoDialogHeader>
              <BoDialogBody>
                <NameDetailsContainer>
                  <Subtitle2 as="div">
                    Customer&apos;s current Full Name:
                  </Subtitle2>
                  <Subtitle2 as="div" $fontWeight="Bold">
                    {customer.fullName}
                  </Subtitle2>
                </NameDetailsContainer>
                <FormWrapper>
                  <FormRow>
                    <BoTextField autoFocus name="firstName">
                      First Name
                    </BoTextField>
                  </FormRow>
                  <FormRow>
                    <BoTextField name="lastName">Last Name</BoTextField>
                  </FormRow>
                </FormWrapper>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Cancel"
                onCancel={close}
                confirmDisabled={
                  !dirty || updateCustomerMutation.isPending || !valid
                }
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
