import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface QuickPayOnDemandTokenResentData extends EventLogBaseData {
  billingAccountId: string;
  billingAccountNumber: string;
  expiresAt: string;
  oneClickPaymentUrl: string;
}

export class QuickPayOnDemandTokenResent extends EventLog {
  public readonly billingAccountId: string;
  public readonly billingAccountNumber: string;
  public readonly expiresAt: string;
  public readonly oneClickPaymentUrl: string;

  constructor({
    billingAccountId,
    billingAccountNumber,
    expiresAt,
    oneClickPaymentUrl,
    ...baseEventData
  }: QuickPayOnDemandTokenResentData) {
    super(baseEventData);

    this.billingAccountId = billingAccountId;
    this.billingAccountNumber = billingAccountNumber;
    this.expiresAt = formatMonthDayYear(expiresAt);
    this.oneClickPaymentUrl = oneClickPaymentUrl;
  }

  override get body(): string | null {
    return `${this.name} - Resent at: ${this.receivedAt.format(
      "MM/DD/YYYY HH:mm"
    )} - Expires at: ${this.expiresAt}`;
  }
}
