import { premiseApi } from "@common/api/premiseApi";
import { CampaignSlugs } from "@common/constants/campaigns.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as Extension } from "@ops-design-system/icons/Extension.svg";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { BoCampaignSlugSelect } from "@ops/components/BoCampaignSlugSelect/BoCampaignSlugSelect";
import {
  DateHeader,
  EmptyOfferMessage,
  Header,
  HeaderSubsection,
  OfferSectionsWrapper,
  OffersSection,
  SelectContainer,
  SelectLabel,
  StyledLabel,
  TermsCheckboxRow,
} from "@ops/components/BoCampaignSlugSelect/layoutComponents";
import {
  ContractSwapDivider,
  DateContainer,
  NewContractDatesContainer,
} from "@ops/components/ContractSwapsAndRenewals/ContractSwap/ContractSwap.styled";
import { useContractFormState } from "@ops/components/ContractSwapsAndRenewals/shared/useContractFormState.hook";
import { OfferCardRadioGroup } from "@ops/components/OfferCardRadioGroup/OfferCardRadioGroup";
import { PlanInfo } from "@ops/components/PlanInfo/PlanInfo";
import { termsAgreement } from "@ops/constants/contractTerms.constants";
import { ContractSwapType } from "@ops/constants/retention.constants";
import { usePremise } from "@ops/hooks/usePremise";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export const ContractSwap = () => {
  const [{ requestMonitor }, { setPending, setFailure, setSuccess }] =
    useAjaxState();
  const { open, close, isOpen } = useModalState();
  const {
    rcid,
    selectedOfferSnapshot,
    agreedToTerms,
    reset: resetState,
    handleOverrideSlug,
    handleOfferSnapshotSelected,
    handleToggleTermsCheckbox,
  } = useContractFormState({ defaultSlug: CampaignSlugs.ContractSwap });

  const dispatch = useDispatch();

  const flash = useRhFlash();
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const { premise } = usePremise(premiseId);

  if (!premise) {
    return null;
  }

  const handleClose = () => {
    resetState();
    close();
  };

  const handleSubmit = () => {
    if (!selectedOfferSnapshot) {
      flash.error("An offerSnapshot must be selected in order to proceed.");
      return;
    } else if (!agreedToTerms) {
      flash.error("The terms must be accepted in order to proceed");
      return;
    }

    setPending();
    premiseApi.contracts
      .swap(premise.id, selectedOfferSnapshot.id, ContractSwapType.CSR)
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleError = () => {
    setFailure();
    flash.error("Issue updating contract for this premise. Please try again.");
  };

  const handleSuccess = () => {
    setSuccess();
    flash.success("Contract swap for premise successful");
    dispatch(premiseRefetch(premise.id));
    handleClose();
  };

  const startDate = formatMonthDayYear(dayjs().add(1, "day").toString());
  const expectedEndDate = () => {
    if (selectedOfferSnapshot) {
      return formatMonthDayYear(
        dayjs(startDate)
          .add(selectedOfferSnapshot?.termMonths, "months")
          .toString()
      );
    }
    return "";
  };

  const dunsNumber = premise?.currentOrder?.dunsNumber;

  return (
    <>
      <BoButton
        onClick={open}
        variant="secondary"
        size="extraSmall"
        icon={Extension}
      >
        Swap Contract
      </BoButton>
      <BoModal open={isOpen} onClose={handleClose} size="large">
        <BoDialogHeader>Swap Contract</BoDialogHeader>
        <BoDialogBody>
          <form noValidate onSubmit={handleSubmit}>
            <Header>Current Contract</Header>
            <PlanInfo showDocuments={false} />
            <ContractSwapDivider />
            <HeaderSubsection>
              <div>
                <Header>New Contract</Header>
                <NewContractDatesContainer>
                  <DateContainer>
                    <DateHeader>Start Date</DateHeader>
                    <Body2>{startDate}</Body2>
                  </DateContainer>
                  <DateContainer>
                    <DateHeader>End Date</DateHeader>
                    <Body2>{expectedEndDate()}</Body2>
                  </DateContainer>
                </NewContractDatesContainer>
              </div>
              <div>
                <AccessControl>
                  <SelectContainer>
                    <SelectLabel id="override_campaign_slug">
                      Override Campaign Slug
                    </SelectLabel>
                    <BoCampaignSlugSelect
                      ariaLabelledby="override_campaign_slug"
                      onChange={handleOverrideSlug}
                      value={rcid}
                    />
                  </SelectContainer>
                </AccessControl>
              </div>
            </HeaderSubsection>
            <OfferSectionsWrapper>
              <OffersSection>
                {dunsNumber ? (
                  <OfferCardRadioGroup
                    initialSelectedOfferSnapshotId={selectedOfferSnapshot?.id}
                    onOfferSnapshotSelect={handleOfferSnapshotSelected}
                    offerSnapshotListSearchParams={{
                      dunsNumber,
                      rcid,
                    }}
                  />
                ) : (
                  <EmptyOfferMessage>No plans available.</EmptyOfferMessage>
                )}
              </OffersSection>
            </OfferSectionsWrapper>
            <TermsCheckboxRow>
              <BoCheckboxInput
                id="termsAgreement"
                onChange={handleToggleTermsCheckbox}
              />
              <StyledLabel htmlFor="termsAgreement">
                {termsAgreement}
              </StyledLabel>
            </TermsCheckboxRow>
          </form>
        </BoDialogBody>
        <BoDialogButtonFooter
          confirmBtnText="Confirm Swap"
          confirmBtnType="submit"
          cancelBtnText="Cancel"
          onCancel={handleClose}
          onConfirm={handleSubmit}
          confirmDisabled={
            !agreedToTerms || !selectedOfferSnapshot || requestMonitor.isPending
          }
          confirmLoading={requestMonitor.isPending}
        />
      </BoModal>
    </>
  );
};
