import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  NewPremiseDetailsSideBarContainer,
  NewPremiseDetailsSideBarStyledLabel,
  NewPremiseDetailsSideBarStyledValue,
} from "@ops/components/NewPremiseDetailsSidebar/NewPremiseDetailsSidebar.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import React from "react";

interface NewPremiseDetailsOfferSnapshotDetailsProps {
  offerSnapshotId: IdType;
}

export const NewPremiseDetailsOfferSnapshotDetails = (
  props: NewPremiseDetailsOfferSnapshotDetailsProps
) => {
  const { offerSnapshotId } = props;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId,
    queryOptions: {
      enabled: Boolean(offerSnapshotId),
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <NewPremiseDetailsSideBarContainer>
        <BoCircularProgress />
      </NewPremiseDetailsSideBarContainer>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <NewPremiseDetailsSideBarContainer>
        Failed to fetch offer details
      </NewPremiseDetailsSideBarContainer>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const termMonthsAndRate = `${offerSnapshot.termMonths} months, ${
    offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
  } Rate`;

  return (
    <NewPremiseDetailsSideBarContainer>
      <NewPremiseDetailsSideBarStyledLabel>
        Plan
      </NewPremiseDetailsSideBarStyledLabel>
      <NewPremiseDetailsSideBarStyledValue>
        <div>{`${offerSnapshot.title},`}</div>
        {offerSnapshot.isTimeOfUse ? (
          <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
        ) : (
          <div>{`${formatEnergyRate(offerSnapshot.energyRate())},`}</div>
        )}
        <div>{termMonthsAndRate}</div>
      </NewPremiseDetailsSideBarStyledValue>
    </NewPremiseDetailsSideBarContainer>
  );
};
