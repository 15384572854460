import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ReturnPaymentData extends EventLogBaseData {
  billingAccountNumber: string;
  disconnectionDate: string;
  disconnectionNoticeSendDate: string;
  paymentAmount: string;
  paymentNumber: string;
  returnPaymentDate: string;
}

export class ReturnPayment extends EventLog {
  public readonly billingAccountNumber: string;
  public readonly disconnectionDate: string;
  public readonly disconnectionNoticeSendDate: string;
  public readonly paymentAmount: string;
  public readonly paymentNumber: string;
  public readonly returnPaymentDate: string;

  constructor({
    billingAccountNumber,
    disconnectionDate,
    disconnectionNoticeSendDate,
    paymentAmount,
    paymentNumber,
    returnPaymentDate,
    ...baseEventData
  }: ReturnPaymentData) {
    super({
      ...baseEventData,
      eventText: "Return Payment",
    });

    this.billingAccountNumber = billingAccountNumber;
    this.disconnectionDate = disconnectionDate
      ? ` - DNP Notice Disconnection Date: ${formatMonthDayYear(
          disconnectionDate
        )} `
      : "";
    this.disconnectionNoticeSendDate = disconnectionNoticeSendDate
      ? ` - DNP Notice Send Date: ${formatMonthDayYear(
          disconnectionNoticeSendDate
        )}`
      : "";
    this.paymentAmount = paymentAmount;
    this.paymentNumber = paymentNumber;
    this.returnPaymentDate = returnPaymentDate;
  }

  override get body() {
    return `${this.name} - Payment Number: ${
      this.paymentNumber
    } - Payment Amount: ${formatCurrency(this.paymentAmount)} 
    - Return Date: ${formatMonthDayYear(this.returnPaymentDate)}
    ${this.disconnectionNoticeSendDate} ${this.disconnectionDate} 
    - Account Number: ${this.billingAccountNumber} `;
  }
}
