import { IdType } from "@common/types/apiTypes";
import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import { ProductContractKeys } from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCancelProductContract = (premiseId: IdType) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: rhapsodyApi.productContracts.cancel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ProductContractKeys.PREMISE_LIST(premiseId),
      });
    },
  });

  return mutation;
};
