import {
  BillComparisonType,
  ComparisonPeriodType,
} from "@ops-models/types/billBreakdownTypes";
import { StyledBillBreakdownComparisonTable } from "@ops/components/BillBreakdownComparisonTable/BillBreakdownComparisonTable.styled";
import React, { useMemo } from "react";

interface BillBreakdownComparisonTableProps {
  comparisonData: BillComparisonType;
}

export const BillBreakdownComparisonTable = (
  props: BillBreakdownComparisonTableProps
) => {
  const { comparisonData } = props;

  const tableData: (ComparisonPeriodType | null)[] = useMemo(() => {
    const data = [];

    if (comparisonData.previousYearPeriod) {
      data.push(comparisonData.previousYearPeriod);
    }

    if (comparisonData.previousPeriod) {
      data.push(comparisonData.previousPeriod);
    } else {
      data.push(null);
    }

    data.push(comparisonData.currentPeriod);

    return data;
  }, [comparisonData]);

  const getCellKey = (d: ComparisonPeriodType | null) =>
    d ? `${d.startDate}-${d.endDate}` : "previous-month";

  return (
    <StyledBillBreakdownComparisonTable>
      <tbody>
        <tr>
          <th>Days in Cycle</th>
          {tableData.map((d) => (
            <td key={`days-${getCellKey(d)}`}>
              {d ? d.servicePeriodDays : "-"}
            </td>
          ))}
        </tr>
        <tr>
          <th>Consumed (kWh)</th>
          {tableData.map((d) => (
            <td key={`consumption-${getCellKey(d)}`}>
              {d ? parseInt(d.consumption, 10).toLocaleString("en-US") : "-"}
            </td>
          ))}
        </tr>
        <tr>
          <th>High Temperature Days</th>
          {tableData.map((d) => (
            <td key={`high-temperature-${getCellKey(d)}`}>
              {d && d.numberOfHotDays ? d.numberOfHotDays : "-"}
            </td>
          ))}
        </tr>
        <tr>
          <th>Low Temperature Days </th>
          {tableData.map((d) => (
            <td key={`low-temperature-${getCellKey(d)}`}>
              {d && d.numberOfColdDays ? d.numberOfColdDays : "-"}
            </td>
          ))}
        </tr>
        <tr>
          <th>Average Temperature</th>
          {tableData.map((d) => (
            <td key={`average-temperature-${getCellKey(d)}`}>
              {d && d.averageTemperature ? `${d.averageTemperature}°F` : "-"}
            </td>
          ))}
        </tr>
      </tbody>
    </StyledBillBreakdownComparisonTable>
  );
};
