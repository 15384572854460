import { AjaxStatuses, IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { PremiseType } from "@common/types/premiseTypes";
import { PremiseStateType } from "@ops-models/types/opsSlicesTypes";
import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";
import merge from "lodash/merge";

export const initialPremiseState = Object.freeze<PremiseStateType>({
  data: null,
  error: null,
  requestStatus: AjaxStatuses.Idle,
});

export const premiseRequestedHandler: CaseReducer<
  PremiseStateType,
  PayloadAction
> = (state) => ({
  ...state,
  requestStatus: AjaxStatuses.Pending,
});

export const premiseUpdatedHandler: CaseReducer<
  PremiseStateType,
  PayloadAction<Partial<PremiseType>>
> = ({ data, ...state }, { payload }) => ({
  ...state,
  data: merge({}, data, payload),
});

export const premiseReceivedHandler: CaseReducer<
  PremiseStateType,
  PayloadAction<PremiseType>
> = (state, action) => ({
  data: action.payload,
  error: null,
  requestStatus: AjaxStatuses.Success,
});

export const premiseErroredHandler: CaseReducer<
  PremiseStateType,
  PayloadAction<RhApiError>
> = ({ data }, { payload: error }) => ({
  data,
  error,
  requestStatus: AjaxStatuses.Failure,
});

export const premiseClearHandler: CaseReducer<
  PremiseStateType,
  PayloadAction
> = () => ({ ...initialPremiseState });

const premiseReducers = {
  clear: premiseClearHandler,
  errored: premiseErroredHandler,
  received: premiseReceivedHandler,
  requested: premiseRequestedHandler,
  updated: premiseUpdatedHandler,
};

const premiseSlice = createSlice<PremiseStateType, typeof premiseReducers>({
  initialState: initialPremiseState,
  name: "premise",
  reducers: premiseReducers,
});

export const premiseReducer = premiseSlice.reducer;

export const premiseRequested = premiseSlice.actions.requested;
export const premiseUpdated = premiseSlice.actions.updated;
export const premiseReceived = premiseSlice.actions.received;
export const premiseErrored = premiseSlice.actions.errored;
export const premiseClear = premiseSlice.actions.clear;

export const premiseFetch = createAction<IdType>(`${premiseSlice.name}/fetch`);
export const premiseRefetch = createAction<IdType>(
  `${premiseSlice.name}/refetch`
);
