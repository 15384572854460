import { IdType } from "@common/types/apiTypes";

const PREMISE_BASE_URL = "/api/rhapsody/premises";

export const statusIndicatorsUrls = {
  premiseAssignedStatusIndicators: {
    list: (id: IdType) => `${PREMISE_BASE_URL}/${id}/status-indicators/`,
    update: (id: IdType) => `${PREMISE_BASE_URL}/${id}/status-indicators/`,
  },
  premiseTypeStatusIndicators: {
    list: (): string => `${PREMISE_BASE_URL}/status-indicators/`,
  },
};
