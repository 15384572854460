import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import {
  Body1,
  H3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProductType } from "@ops-models/types/productTypes";
import { ProductTierSelection } from "@ops/components/ProductAddOnCard/ProductTierSelection";
import React from "react";
import styled from "styled-components";

const StyledGrid = styled(BoGrid)`
  gap: ${rhOpsSpacingPx(1)};
  grid-template-areas:
    "logo name tiers "
    "logo term tiers"
    "logo description tiers";
  grid-template-columns: 150px 3fr 2fr;
  grid-template-rows: auto ${rhOpsSpacingPx(2)} 2fr;
  padding: ${rhOpsSpacingPx(2)} 0;
  :not(:last-of-type)::before {
    border-bottom: 1px solid ${grey["100"]};
    bottom: 0;
    content: "";
    height: 1px;
    left: 150px;
    position: absolute;
    right: 0;
  }
`;

const StyledImage = styled.img`
  grid-area: logo;
  height: 5rem;
  place-self: start center;
`;

const StyledProductName = styled(H3)`
  align-self: end;
  grid-area: name;
`;

const StyledDescription = styled(Body1)`
  color: ${grey["500"]};
  grid-area: description;
`;

const StyledTerm = styled(Subtitle2)`
  align-self: center;
  grid-area: term;
`;

const StyledTiers = styled(BoFlexBox)`
  display: grid;
  gap: ${rhOpsSpacingPx(1)};
  grid-area: tiers;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
`;

interface ProductAddOnCardProps {
  className?: string;
  product: ProductType;
  selectionRequired?: boolean;
}
export const ProductAddOnCard = ({
  product,
  className,
  selectionRequired = true,
}: ProductAddOnCardProps) => {
  return (
    <StyledGrid
      as="section"
      className={className}
      aria-label={`Product - ${product.name}`}
    >
      {product.icon ? (
        <StyledImage alt={`Product - ${product.name}`} src={product.icon} />
      ) : null}

      <StyledProductName>{product.name}</StyledProductName>
      <StyledTerm>12 Months</StyledTerm>
      <StyledDescription>{product.description}</StyledDescription>
      <StyledTiers>
        {product.tiers.map((tier) => {
          return (
            <ProductTierSelection
              productTier={tier}
              selectionRequired={selectionRequired}
              key={tier.id}
            />
          );
        })}
      </StyledTiers>
    </StyledGrid>
  );
};
