import { premiseApi } from "@common/api/premiseApi";
import { EnrollmentStatus } from "@common/enums/customer.enum";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { useModalState } from "@common/hooks/useModalState";
import { Premise } from "@common/models/Premise.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { BoSelectValue } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { editDialogContentsCss } from "@ops/styles/mixins";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import styled from "styled-components";

interface EditEnrollmentStatusProps {
  premise: Premise;
}

interface EditEnrollmentStatusFormValues {
  enrollmentStatus: BoSelectValue;
}

export const EnrollmentStatusContainer = styled.div`
  ${editDialogContentsCss}
`;

export const EditEnrollmentStatus = ({
  premise,
}: EditEnrollmentStatusProps) => {
  const { open, close, isOpen } = useModalState();
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();

  const enrollmentStatusOptions = Object.values(EnrollmentStatus).map(
    (status) => ({
      label: String(status),
      value: String(status),
    })
  );

  const handleSubmit = ({
    enrollmentStatus,
  }: EditEnrollmentStatusFormValues) => {
    setPending();

    return premiseApi
      .setEnrollmentStatus(premise.id, String(enrollmentStatus))
      .then(() => {
        setSuccess();
        dispatch(premiseRefetch(premise.id));
        close();
      })
      .catch(() => {
        setFailure();
        close();
        flash.error("Something went wrong. Please try again.");
      });
  };

  return (
    <>
      <EditLink onClick={open} />
      <BoModal open={isOpen} onClose={close} overflow="visible">
        <Form<EditEnrollmentStatusFormValues>
          validateOnBlur
          onSubmit={handleSubmit}
          initialValues={{
            enrollmentStatus: premise.enrollmentStatus,
          }}
          render={({ dirty, handleSubmit: handleFormSubmit }) => {
            return (
              <form noValidate onSubmit={handleFormSubmit}>
                <BoDialogHeader>Edit Enrollment Status</BoDialogHeader>
                <BoDialogBody>
                  <EnrollmentStatusContainer>
                    <Subtitle2 as="div">
                      Customer&apos;s current Enrollment Status:
                    </Subtitle2>
                    <Subtitle2 as="div" $fontWeight="Bold" role="region">
                      {premise.enrollmentStatus}
                    </Subtitle2>
                  </EnrollmentStatusContainer>
                  <BoSelectField
                    name="enrollmentStatus"
                    defaultValue={premise.enrollmentStatus}
                    options={enrollmentStatusOptions}
                    label="Enrollment Status"
                  />
                </BoDialogBody>
                <BoDialogButtonFooter
                  confirmDisabled={!dirty || requestMonitor.isPending}
                  confirmBtnText="Update"
                  confirmBtnType="submit"
                  cancelBtnText="Cancel"
                  onCancel={close}
                />
              </form>
            );
          }}
        />
      </BoModal>
    </>
  );
};
