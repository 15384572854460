import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { AddContactLog } from "@ops/components/SupportLogs/ContactLog/AddContactLog/AddContactLog";
import { ContactLogSearchEntry } from "@ops/components/SupportLogs/ContactLog/ContactLogEntry/ContactLogSearchEntry";
import { useSupportLogsContext } from "@ops/components/SupportLogs/hooks/useSupportLogsContext";
import { SupportLogEntryList } from "@ops/components/SupportLogs/SearchLog/SupportLogEntryList";
import { SupportLogSearchInput } from "@ops/components/SupportLogs/SearchLog/SupportLogSearchInput";
import { Header } from "@ops/components/SupportLogs/shared/Header";
import {
  ScrollWrap,
  StyledEmptyText,
  StyledSupportLog,
} from "@ops/components/SupportLogs/SupportLogs.styled";
import { LogType } from "@ops/components/SupportLogs/SupportLogs.types";
import { useContactLogsInState } from "@ops/hooks/useContactLogsInState";
import { ContactLog as ContactLogModel } from "@ops/models/ContactLog.model";
import { searchLogs } from "@ops/models/searchable";
import React, { useEffect, useState } from "react";

export const ContactLog = () => {
  const { target } = useSupportLogsContext();
  const { data, requestMonitor, fetch } = useContactLogsInState(target);
  const [query, setQuery] = useState<string | undefined>("");
  const flash = useRhFlash();

  useEffect(() => {
    fetch();
  }, [fetch]);

  if (requestMonitor.didFail) {
    flash.error("An error occurred while attempting to fetch contact logs");

    return (
      <StyledSupportLog>
        <Header />
        <div>
          <StyledEmptyText>Failed to retrieve contact logs.</StyledEmptyText>
        </div>
      </StyledSupportLog>
    );
  }

  const filteredContactLogs = searchLogs(data ?? [], query);

  const mapper = (contactLog: ContactLogModel): JSX.Element => (
    <ContactLogSearchEntry
      key={contactLog.id}
      contactLog={contactLog}
      query={query}
    />
  );

  return (
    <StyledSupportLog>
      <Header>
        <AddContactLog onAdd={fetch} />
        <SupportLogSearchInput
          supportType={LogType.CONTACT}
          setValue={setQuery}
          value={query}
        />
      </Header>
      {requestMonitor.isPending ? (
        <BoCircularProgress position="relative" />
      ) : (
        <ScrollWrap>
          <SupportLogEntryList<ContactLogModel>
            mapper={mapper}
            query={query}
            supportLogs={filteredContactLogs}
            logType={LogType.CONTACT}
          />
        </ScrollWrap>
      )}
    </StyledSupportLog>
  );
};
