import { IdType } from "@common/types/apiTypes";
import { selectPaymentMethodsState } from "@ops-redux/selectors/paymentMethods.selectors";
import { paymentMethodsFetch } from "@ops-redux/slices/paymentMethodsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePaymentMethods = (premiseId: IdType | null) => {
  const { paymentMethods, error, status } = useSelector(
    selectPaymentMethodsState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (premiseId && paymentMethods === null && !status.isPending && !error) {
      dispatch(paymentMethodsFetch(premiseId));
    }
  }, [premiseId]);

  return {
    error,
    paymentMethods,
    requestMonitor: status,
  };
};
