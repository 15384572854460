import { IdType } from "@common/types/apiTypes";
import { rhapsodyApi } from "@ops-api/api/rhapsodyApi";
import { ProductContractStatusFilter } from "@ops-models/types/productContractTypes";
import { ProductContractKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useExpiredProductContracts = (premiseId: IdType) => {
  return useQuery({
    queryFn: () =>
      rhapsodyApi.productContracts.list(
        premiseId,
        ProductContractStatusFilter.EXPIRED
      ),
    queryKey: ProductContractKeys.EXPIRED_CONTRACTS_BY_PREMISE_LIST(premiseId),
  });
};
