import { ediApi } from "@common/api/ediApi";
import { blankAddress } from "@common/constants/blankAddress.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { Meter } from "@common/models/Meter.model";
import { cityStateZip } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCheckboxField } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  Body2,
  Body3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProspectType } from "@ops-models/types/prospectTypes";
import { ValidatedAddressType } from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { ProspectEditorForm } from "@ops/components/ProspectEditor/ProspectEditorForm";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { StatusIndicator } from "@ops/components/StatusIndicator/StatusIndicator";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

interface ConfirmMeterProps {
  validatedAddress: ValidatedAddressType;
}
interface ConfirmMeterFormValues {
  confirmMeter: boolean;
}

interface HeaderProps {}

const HeaderContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(0.5)};
  padding-top: ${rhOpsSpacingPx(0.5)};
`;

const Header = ({ children }: PropsWithChildren<HeaderProps>) => {
  return (
    <HeaderContainer>
      <Body3 $fontWeight="SemiBold" as="div">
        {children}
      </Body3>
    </HeaderContainer>
  );
};
const Body = styled(Body2).attrs({ as: "div" })``;
const CheckboxContainer = styled.div`
  margin: -9px;
`;
const InfoContainer = styled.div`
  &:nth-child(2) {
    padding-left: ${rhOpsSpacingPx(2)};
  }
  padding-left: ${rhOpsSpacingPx(5)};
`;

const ConfirmMeterContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(1)};
`;

export const ConfirmMeter = ({
  validatedAddress: { esiId, serviceAddress, dunsNumber },
}: ConfirmMeterProps) => {
  const { updateProspectState, prospect } = useEnrollmentFlowContext();
  const [checked, setChecked] = useState<boolean>(esiId === prospect.meterId);

  const flash = useRhFlash();

  const [
    { data: meter, requestMonitor },
    { setPending, setFailure, setSuccess },
  ] = useAjaxState<Meter>();

  useEffect(() => {
    setChecked(esiId === prospect.meterId);
  }, [esiId, prospect.meterId]);

  const invalidatePlanOnAddressChanged = (): boolean => {
    if (!prospect.meterId || prospect.meter?.duns === dunsNumber) {
      return false;
    }

    return esiId !== prospect.meterId;
  };

  useEffect(() => {
    setPending();

    ediApi.meter
      .retrieve(esiId)
      .then((meterData) => {
        setSuccess(new Meter(meterData));
      })
      .catch(setFailure);
  }, [serviceAddress]);

  if (requestMonitor.isPending) {
    return null;
  }

  const handleSave = ({ confirmMeter }: ConfirmMeterFormValues) => {
    if (confirmMeter) {
      const newProspectState: Partial<ProspectType> = {
        meter: {
          duns: meter?.duns ?? null,
          premiseType: meter?.premiseType ?? null,
          utilityName: meter?.utilityName ?? null,
        },
        meterId: esiId,
        ...serviceAddress,
      };

      if (invalidatePlanOnAddressChanged() && prospect.offersnapshotId) {
        flash.success(
          "The address has changed. You will need to redo your plan selection."
        );
        newProspectState.offersnapshotId = null;
      }
      updateProspectState(newProspectState);
    } else {
      updateProspectState({
        meterId: null as unknown as undefined,
        offersnapshot: null,
        offersnapshotId: null,

        ...blankAddress,
      });
    }
  };

  return (
    <Form<ConfirmMeterFormValues>
      initialValues={{ confirmMeter: checked }}
      onSubmit={handleSave}
      render={() => {
        return (
          <ProspectEditorForm id={TabType.serviceAddress}>
            <ConfirmMeterContainer>
              <Subtitle2 $fontWeight="Bold">Confirm meter</Subtitle2>
            </ConfirmMeterContainer>
            <BoCard>
              <BoFlexBox>
                <CheckboxContainer>
                  <BoCheckboxField name="confirmMeter" />
                </CheckboxContainer>
                <InfoContainer>
                  <label htmlFor="confirmMeter">
                    <Header>Service Address</Header>
                    <Body>{serviceAddress.addressLine1}</Body>
                    {serviceAddress.unitNumber && (
                      <Body>{serviceAddress.unitNumber}</Body>
                    )}
                    <Body>{cityStateZip(serviceAddress)}</Body>
                  </label>
                </InfoContainer>
                <InfoContainer>
                  <Header>ESIID</Header>
                  <Body>{esiId}</Body>
                </InfoContainer>
                {meter && (
                  <>
                    <InfoContainer>
                      <Header>Premise Type</Header>
                      <Body>{meter.premiseType}</Body>
                    </InfoContainer>
                    <InfoContainer>
                      <Header>Utility</Header>
                      <Body>{meter.utilityName}</Body>
                    </InfoContainer>
                    {meter.hasViolationErrors && (
                      <InfoContainer>
                        {meter.violationErrors.map(({ text, status }) => (
                          <StatusIndicator status={status} key={text}>
                            {text}
                          </StatusIndicator>
                        ))}
                      </InfoContainer>
                    )}
                  </>
                )}
              </BoFlexBox>
            </BoCard>
          </ProspectEditorForm>
        );
      }}
    />
  );
};
