import {
  formatAsLocalTime,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ThermostatOfflineWarningData extends EventLogBaseData {
  offlineInHours: string;
  offlineSince: string;
  offlineThreshold: string;
  planOverridesDeviceCredit: string;
  rhythmDeviceId: string;
}

export class ThermostatOfflineWarning extends EventLog {
  public readonly planOverridesDeviceCredit: string;
  public readonly offlineInHours: string;
  public readonly offlineThreshold: string;
  public readonly offlineSince: string;
  public readonly rhythmDeviceId: string;

  constructor({
    planOverridesDeviceCredit,
    rhythmDeviceId,
    offlineInHours,
    offlineThreshold,
    offlineSince,
    ...baseEventData
  }: ThermostatOfflineWarningData) {
    super(baseEventData);

    this.planOverridesDeviceCredit = planOverridesDeviceCredit;
    this.offlineInHours = offlineInHours;
    this.offlineThreshold = offlineThreshold;
    this.offlineSince = `${formatMonthDayYear(offlineSince)} ${formatAsLocalTime(offlineSince)}`;
    this.rhythmDeviceId = rhythmDeviceId;
  }

  override get body() {
    return `${this.name}
    Rhythm Device ID: ${this.rhythmDeviceId}
    Offline Since: ${this.offlineSince}
    Offline Hours: ${this.offlineInHours}`;
  }
}
