import {
  ISO8601_DATE_FORMAT,
  MONTH_DAY_YEAR_SLASH,
} from "@common/constants/date.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidAdultBirthday } from "@common/forms/validators";
import { useModalState } from "@common/hooks/useModalState";
import { Customer } from "@common/models/Customer.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { EditLink } from "@ops/components/EditLink/EditLink";
import { useCustomerUpdateMutation } from "@ops/hooks/mutations/useCustomerUpdate.mutation";
import dayjs from "dayjs";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const EditDateOfBirthDivider = styled.div`
  border-bottom: 1px solid ${grey["100"]};
  margin-bottom: ${rhOpsSpacingPx(3)};
  padding-bottom: ${rhOpsSpacingPx(1)};
`;

interface EditDateOfBirthFormValues {
  dateOfBirth: string;
}

interface EditDateOfBirthProps {
  customer: Customer;
}

const Subheader = styled.div`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const StyledSelectWrapper = styled.div`
  max-width: 50%;
`;

const dateOfBirthFormValidations = {
  dateOfBirth: [isRequired, isValidAdultBirthday],
};

const dateOfBirthFormValidator =
  generateValidationErrorCollector<EditDateOfBirthFormValues>(
    dateOfBirthFormValidations
  );

export const EditDateOfBirth = ({ customer }: EditDateOfBirthProps) => {
  const { open, close, isOpen } = useModalState();
  const flash = useRhFlash();
  const updateCustomerMutation = useCustomerUpdateMutation();

  const handleSubmit = ({ dateOfBirth }: EditDateOfBirthFormValues) => {
    updateCustomerMutation.mutate(
      {
        customerId: customer.id,
        updateData: {
          dateOfBirth,
        },
      },
      {
        onError: () => {
          flash.error("Issue updating date of birth. Please try again.");
        },
        onSuccess: () => {
          close();
        },
      }
    );
  };

  const dateOfBirthLongFormat = () =>
    `${dayjs(customer.dateOfBirth, ISO8601_DATE_FORMAT).format(
      MONTH_DAY_YEAR_SLASH
    )}`;

  return (
    <>
      <EditLink aria-label="Edit DOB" onClick={open} />
      <BoModal open={isOpen} onClose={close}>
        <Form<EditDateOfBirthFormValues>
          initialValues={{
            dateOfBirth: dateOfBirthLongFormat(),
          }}
          onSubmit={handleSubmit}
          validate={dateOfBirthFormValidator}
          validateOnBlur
          render={({ dirty, handleSubmit: formHandleSubmit }) => (
            <form noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Edit Date Of Birth</BoDialogHeader>
              <BoDialogBody>
                <Subheader>
                  <Subtitle2 as="div">
                    Customer&apos;s current date of birth is:
                  </Subtitle2>
                  <Subtitle2 as="div" $fontWeight="Bold">
                    {dateOfBirthLongFormat()}
                  </Subtitle2>
                  <EditDateOfBirthDivider />
                </Subheader>
                <StyledSelectWrapper>
                  <BoDateField
                    name="dateOfBirth"
                    label="Date Of Birth"
                    placeholder={dateOfBirthLongFormat()}
                  />
                </StyledSelectWrapper>
              </BoDialogBody>
              <BoDialogButtonFooter
                confirmBtnText="Update"
                confirmBtnType="submit"
                cancelBtnText="Cancel"
                onCancel={close}
                confirmDisabled={!dirty || updateCustomerMutation.isPending}
              />
            </form>
          )}
        />
      </BoModal>
    </>
  );
};
