import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { Body1, H3 } from "@ops-design-system/components/Typography/Typography";
import { DeviceType } from "@ops-models/types/deviceTypes";
import {
  DeviceBrandText,
  SelectDeviceInfoColumn,
  SelectDeviceStatus,
  StyledInfoContainer,
} from "@ops/components/DeviceInformationCard/DeviceInformationCard.styled";
import React from "react";

interface DeviceInformationCardProps {
  device: DeviceType;
}

export const DeviceInformationCard = (props: DeviceInformationCardProps) => {
  const { device } = props;
  const { deviceBrand, deviceName, deviceStatus, smartThermostatId } = device;

  return (
    <BoCard>
      <H3>Thermostat - {deviceName}</H3>
      <StyledInfoContainer>
        <SelectDeviceInfoColumn>
          <div>
            <Body1 as="p" $fontWeight="Bold">
              Device name
            </Body1>
            <Body1 as="p">{deviceName}</Body1>
          </div>
          <div>
            <Body1 as="p" $fontWeight="Bold">
              Device type
            </Body1>
            <DeviceBrandText as="p">{deviceBrand} Thermostat</DeviceBrandText>
          </div>
        </SelectDeviceInfoColumn>
        <SelectDeviceInfoColumn>
          <div>
            <Body1 as="p" $fontWeight="Bold">
              Rhythm Device ID
            </Body1>
            <Body1 as="p">{smartThermostatId}</Body1>
          </div>
          <div>
            <Body1 as="p" $fontWeight="Bold">
              Device status
            </Body1>
            <SelectDeviceStatus $status={deviceStatus}>
              {deviceStatus}
            </SelectDeviceStatus>
          </div>
        </SelectDeviceInfoColumn>
      </StyledInfoContainer>
    </BoCard>
  );
};
