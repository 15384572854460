import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import {
  keepPreviousData as reactQueryKeepPreviousData,
  useQuery,
} from "@tanstack/react-query";

interface UseThermostatDemandResponseActivityQueryProps {
  premiseId: IdType;
  queryOptions: {
    enabled: boolean;
    keepPreviousData: boolean;
  };
  searchOptions: {
    limit: number;
    offset: number;
    thermostatId: IdType;
  };
}

export const useThermostatDemandResponseActivityQuery = (
  props: UseThermostatDemandResponseActivityQueryProps
) => {
  const {
    premiseId,
    queryOptions: { enabled, keepPreviousData } = {},
    searchOptions,
  } = props;

  return useQuery({
    enabled,
    placeholderData: keepPreviousData ? reactQueryKeepPreviousData : undefined,
    queryFn: () =>
      rhapsodyPremiseApi.thermostatDemandResponseActivity({
        premiseId,
        searchOptions,
      }),
    queryKey: PremiseKeys.thermostatDemandResponseActivity({
      premiseId,
      searchOptions,
    }),
  });
};
