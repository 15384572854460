import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { identity } from "@common/utils/genericFunctions";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-api/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { BoPhoneField } from "@ops-design-system/components/BoPhoneField/BoPhoneField";
import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { BoTextField } from "@ops-design-system/components/BoTextField/BoTextField";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProspectType } from "@ops-models/types/prospectTypes";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const SearchHeader = styled(Subtitle2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1.5)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

const StyledButtonContainer = styled.div``;

const INPUT_WIDTH = "240px";
const SearchFormRow = styled(BoFlexBox)`
  flex-flow: row wrap;
  & > * {
    flex-basis: ${INPUT_WIDTH};
    margin-bottom: ${rhOpsSpacingPx(3)};
    margin-right: ${rhOpsSpacingPx(2)};
  }
  &:last-child {
    margin-right: 0;
  }
`;

const FormButtonRow = styled(BoFlexBox)`
  align-items: center;
  & > * {
    margin-right: ${rhOpsSpacingPx(2)};
  }
  &:last-child {
    margin-right: 0;
  }
`;

export interface ProspectsSearchFormValues {
  addressLine1: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  meterId: string;
  phone: string;
}

interface ProspectsSearchFormProps {
  onSuccess: (prospects: ProspectType[]) => void;
}

export const ProspectsSearchForm = ({
  onSuccess,
}: ProspectsSearchFormProps) => {
  const [{ requestMonitor }, { setSuccess, setFailure, setPending }] =
    useAjaxState();
  const flash = useRhFlash();

  const onSubmit = (formValues: ProspectsSearchFormValues) => {
    setPending();
    return rhapsodyProspectApi
      .search(formValues)
      .then((results) => {
        setSuccess();
        onSuccess(results);
      })
      .catch((err: RhApiError) => {
        flash.error(err.statusText ?? "The query failed.  Try again later.");
        setFailure();
        onSuccess([]);
      });
  };

  return (
    <>
      <SearchHeader>Search Prospects</SearchHeader>
      <BoCard>
        <Form<ProspectsSearchFormValues>
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty, form, values }) => {
            const formIsEmpty = isEmpty(Object.values(values).filter(identity));

            return (
              <form onSubmit={handleSubmit}>
                <SearchFormRow>
                  <BoTextField autoFocus name="firstName">
                    First Name
                  </BoTextField>
                  <BoTextField name="lastName">Last Name</BoTextField>
                  <BoPhoneField name="phone">Phone Number</BoPhoneField>
                  <BoTextField name="email">Email</BoTextField>
                  <BoTextField name="id">Prospect ID</BoTextField>
                  <BoTextField name="addressLine1">Service Address</BoTextField>
                  <BoTextField name="meterId">Prospect ESI-ID</BoTextField>
                </SearchFormRow>

                <FormButtonRow>
                  <StyledButtonContainer>
                    <BoButton
                      type="submit"
                      disabled={!dirty || formIsEmpty}
                      loading={requestMonitor.isPending}
                    >
                      Search
                    </BoButton>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <BoTextButton
                      disabled={!dirty}
                      onClick={(e) => {
                        e.preventDefault();
                        form.reset();
                      }}
                    >
                      Clear Search
                    </BoTextButton>
                  </StyledButtonContainer>
                </FormButtonRow>
              </form>
            );
          }}
        />
      </BoCard>
    </>
  );
};
