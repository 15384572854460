import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { useMutation } from "@tanstack/react-query";

export const useSendPaymentLink = (premiseId: IdType) => {
  const mutation = useMutation({
    mutationFn: rhapsodyPremiseApi.quickPay.sendPaymentLink,
    onSuccess: () => {},
  });

  return mutation;
};
