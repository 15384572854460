import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface DisconnectNoticeFeeCreatedData extends EventLogBaseData {
  disconnectionExpirationDate: string;
  disconnectionSentDate: string;
}

export class DisconnectNoticeFeeCreated extends EventLog {
  public disconnectionExpirationDate: string;
  public disconnectionSentDate: string;

  constructor({
    disconnectionExpirationDate,
    disconnectionSentDate,
    ...baseEventData
  }: DisconnectNoticeFeeCreatedData) {
    super(baseEventData);
    this.disconnectionExpirationDate = disconnectionExpirationDate;
    this.disconnectionSentDate = disconnectionSentDate;
  }

  override get body() {
    return `Disconnect Notice Fee Debit Created - DNP Sent: ${formatMonthDayYear(
      this.disconnectionSentDate
    )} - DNP Expiration Date: ${formatMonthDayYear(
      this.disconnectionExpirationDate
    )}`;
  }
}
