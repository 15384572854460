import { ajax } from "@common/services/ajax";
import { contactLogUrls } from "@ops-api/api/contactLogUrls";
import { BatchContactLogUploadType } from "@ops-models/types/batchContactLogUploadTypes";
import { ContactLogCategoryChoice } from "@ops-models/types/contactLogTypes";

export const contactLogApi = {
  batchUploads: {
    create: (
      csvFile: File
    ): Promise<{ name: string; numberOfLogsAdded: number }> => {
      const data = new FormData();

      data.append("batch_notes_file", csvFile, csvFile.name);
      return ajax.postFile(contactLogUrls.batchUploads.list(), data);
    },
    list: (): Promise<BatchContactLogUploadType[]> => {
      return ajax.get(contactLogUrls.batchUploads.list());
    },
  },
  categories: {
    list: (): Promise<ContactLogCategoryChoice[]> => {
      return ajax.get(contactLogUrls.categories.list());
    },
  },
};
