import { rhapsodyCustomerApi } from "@ops-api/api/rhapsodyCustomerApi";
import { customerRefetch } from "@ops-redux/slices/customerSlice";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

export const useCustomerUpdateMutation = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: rhapsodyCustomerApi.customers.update,
    onSuccess: (_, { customerId }) => {
      dispatch(customerRefetch(customerId));
    },
  });

  return mutation;
};
