import { EnergyUsageGraphV2 } from "@common/components/EnergyUsageGraphV2/EnergyUsageGraphV2";
import { ChartType, GraphTemperatureValue } from "@common/types/usageTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoRadioInput } from "@ops-design-system/components/BoRadioInput/BoRadioInput";
import { Subtitle1 } from "@ops-design-system/components/Typography/Typography";
import {
  StyledBillBreakdownGraphContainer,
  StyledTemperaturesRadios,
} from "@ops/components/BillBreakdownGraph/BillBreakdownGraph.styled";
import { useInvoiceTemperatureQuery } from "@ops/hooks/queries/useInvoiceTemperature.query";
import { useInvoiceUsageQuery } from "@ops/hooks/queries/useInvoiceUsage.query";
import React, { useState } from "react";

interface BillBreakdownGraphBodyProps {
  invoiceId: string;
}

export const BillBreakdownGraphBody = (props: BillBreakdownGraphBodyProps) => {
  const { invoiceId } = props;

  const [temperature, setTemperature] = useState<GraphTemperatureValue>(
    GraphTemperatureValue.HighLow
  );

  const invoiceUsageQuery = useInvoiceUsageQuery({ invoiceId });

  const invoiceTemperatureQuery = useInvoiceTemperatureQuery({ invoiceId });

  if (invoiceUsageQuery.isPending || invoiceTemperatureQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (invoiceUsageQuery.isError || invoiceTemperatureQuery.isError) {
    return (
      <Subtitle1>There is no usage data to show for this invoice.</Subtitle1>
    );
  }

  return (
    <StyledBillBreakdownGraphContainer>
      <StyledTemperaturesRadios>
        <BoRadioInput
          checked={temperature === GraphTemperatureValue.HighLow}
          onClick={() => setTemperature(GraphTemperatureValue.HighLow)}
          label="High/Low Temperature"
        />
        <BoRadioInput
          checked={temperature === GraphTemperatureValue.Average}
          onClick={() => setTemperature(GraphTemperatureValue.Average)}
          label="Average Temperature"
        />
      </StyledTemperaturesRadios>
      <EnergyUsageGraphV2
        usageData={invoiceUsageQuery.data}
        chartType={ChartType.daily}
        chartDirection="horizontal"
        showGeneration={false}
        showConsumption
        showEarned={false}
        latestAvailableDate=""
        temperatures={invoiceTemperatureQuery.data.temperatures}
        selectedTemperatureValue={temperature}
      />
    </StyledBillBreakdownGraphContainer>
  );
};
