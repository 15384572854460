import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhapsodyProspectApi } from "@ops-api/api/rhapsodyProspectApi";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCheckboxInput } from "@ops-design-system/components/BoCheckbox/BoCheckbox";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { ProspectCreditCheckRequestType } from "@ops-models/types/prospectTypes";
import {
  WarningCard,
  WarningMessage,
} from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoStyledCards";
import { BillingFormValues } from "@ops/components/ProspectEditor/BillingInfoTab/BillingInfoTab";
import { existingCustomerFoundError } from "@ops/constants/errorCodes.constants";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import { useProspectInState } from "@ops/hooks/useProspectInState";
import React from "react";
import styled from "styled-components";

interface CheckboxLabelProps {
  $htmlFor?: string;
}

const CheckboxLabel = styled(Body2).attrs<CheckboxLabelProps>(
  ({ $htmlFor }) => ({
    as: "label",
    htmlFor: $htmlFor,
  })
)`
  align-self: center;
`;

interface CheckboxContainerProps {
  $shiftLeft: boolean;
}
const CheckboxContainer = styled.div<CheckboxContainerProps>`
  margin-left: ${({ $shiftLeft }) => ($shiftLeft ? "-" : "")}12px;
`;
const ButtonWrapper = styled.div`
  width: 150px;
`;

interface ExistingCustomerFoundCardProps {
  formValues: Pick<BillingFormValues, "nosRequested">;
  updateFormValues: (values: Partial<BillingFormValues>) => void;
}

export const ExistingCustomerFoundCard = ({
  updateFormValues,
  formValues,
}: ExistingCustomerFoundCardProps) => {
  const {
    prospect: {
      email,
      dateOfBirth,
      zipCode,
      addressLine1,
      firstName,
      lastName,
      phone,
      city,
      state,
      acquisitionMedium,
      id,
      unitNumber,
    },
    updateProspectState,
  } = useEnrollmentFlowContext();

  const flash = useRhFlash();

  const { fetchProspect } = useProspectInState(id);

  const runCreditCheck = () => {
    if (
      !firstName ||
      !lastName ||
      !dateOfBirth ||
      !phone ||
      !email ||
      !addressLine1 ||
      !city ||
      !state ||
      !zipCode
    ) {
      flash.error("Needs more prospect data to run credit check");
      return;
    }

    if (!fetchProspect) {
      return;
    }

    const creditCheckData: ProspectCreditCheckRequestType = {
      acquisitionMedium,
      addressLine1,
      city,
      dateOfBirth,
      email,
      firstName,
      lastName,
      phone,
      prospectId: id,
      state,
      unitNumber,
      zipCode,
    };

    rhapsodyProspectApi
      .creditScoreEvaluation(creditCheckData)
      .catch(() => {
        flash.error("Unable to complete credit check. Try again later");
      })
      .then(() => {
        return fetchProspect();
      })
      .then((prospect) => {
        if (prospect) {
          updateProspectState(prospect);
        }
      })
      .catch(() => flash.error("Unable to gather updated prospect data"));
  };

  return (
    <WarningCard>
      <WarningMessage>{existingCustomerFoundError}</WarningMessage>
      <BoDivider />
      <BoFlexBox>
        <CheckboxContainer $shiftLeft>
          <BoCheckboxInput
            name="nosOrBypass"
            value="nosRequested"
            checked={formValues.nosRequested ?? false}
            inputProps={{
              "aria-label": "nosOrBypass",
            }}
            onChange={() => {
              updateFormValues({ nosRequested: !formValues.nosRequested });
            }}
          />
          <CheckboxLabel>
            Customer will send in required documents and a NOS (New Occupancy
            Statement)
          </CheckboxLabel>
        </CheckboxContainer>
      </BoFlexBox>
      <ButtonWrapper>
        <BoButton variant="secondary" onClick={runCreditCheck}>
          Refetch Credit Status
        </BoButton>
      </ButtonWrapper>
    </WarningCard>
  );
};
