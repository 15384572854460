import { premiseApi } from "@common/api/premiseApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { ZuoraIdType } from "@common/types/apiTypes";
import { DELETE_PAYMENT_METHOD_DISABLE_AUTOPAY_ERROR } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { paymentMethodsFetch } from "@ops-redux/slices/paymentMethodsSlice";
import { PaymentMode } from "@ops/components/ManagePaymentMethods/ManagePaymentMethodsContextProvider";
import { usePaymentMethodContext } from "@ops/hooks/useManagePaymentContextHook";
import React from "react";
import { useDispatch } from "react-redux";

export const DeletePaymentMethodConfirmDialog = () => {
  const { setContextMode, paymentMethodToDelete, premiseId } =
    usePaymentMethodContext();

  const flash = useRhFlash();
  const dispatch = useDispatch();

  const [{ requestMonitor }, { setPending, setSuccess, setFailure }] =
    useAjaxState<boolean>();
  const deletePaymentMethod = (paymentMethodId: ZuoraIdType) => {
    setPending();
    premiseApi.paymentMethods
      .delete(premiseId, paymentMethodId)
      .then(() => {
        setSuccess(true);
        setContextMode(PaymentMode.listMode);
        dispatch(paymentMethodsFetch(premiseId));
      })
      .catch((error) => {
        setFailure(error);
        if (
          error.data.errorCode === DELETE_PAYMENT_METHOD_DISABLE_AUTOPAY_ERROR
        ) {
          flash.error(
            "Autopay must be turned off before this payment method can be deleted"
          );
        } else {
          flash.error("Could not delete this payment method");
        }
      });
  };

  if (paymentMethodToDelete === null) {
    return null;
  }

  return (
    <>
      <BoDialogHeader>Delete Payment Method</BoDialogHeader>
      <BoDialogBody>
        <div>
          <Subtitle2>Are you sure you want to delete</Subtitle2>
          <Subtitle2 $fontWeight="Bold">
            {paymentMethodToDelete.companyNameAndNumber}
          </Subtitle2>
        </div>
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText="Delete"
        cancelBtnText="Cancel"
        confirmDisabled={requestMonitor.isPending}
        onCancel={() => setContextMode(PaymentMode.listMode)}
        confirmButtonVariant="danger"
        onConfirm={() => {
          deletePaymentMethod(paymentMethodToDelete.id);
        }}
      />
    </>
  );
};
