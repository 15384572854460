import { IdType } from "@common/types/apiTypes";
import { rhapsodyCustomerApi } from "@ops-api/api/rhapsodyCustomerApi";
import { rhapsodyProspectApi } from "@ops-api/api/rhapsodyProspectApi";
import {
  ContactLogType,
  CreateContactLogType,
} from "@ops-models/types/contactLogTypes";
import { TargetType } from "@ops/components/SupportLogs/SupportLogs.types";

export class ContactLogService {
  private readonly targetType: TargetType;

  constructor(targetType: TargetType) {
    this.targetType = targetType;
  }

  private fetchApi: Record<
    TargetType,
    (id: IdType) => Promise<ContactLogType[]>
  > = {
    [TargetType.PROSPECT]: rhapsodyProspectApi.contactLogs.list,
    [TargetType.CUSTOMER]: rhapsodyCustomerApi.customers.contactLogs.list,
  };
  private createApi: Record<
    TargetType,
    (id: IdType, contactLog: CreateContactLogType) => Promise<void>
  > = {
    [TargetType.PROSPECT]: rhapsodyProspectApi.contactLogs.create,
    [TargetType.CUSTOMER]: rhapsodyCustomerApi.customers.contactLogs.create,
  };

  list(targetId: IdType) {
    return this.fetchApi[this.targetType](targetId);
  }

  create(targetId: IdType, contactLog: CreateContactLogType) {
    return this.createApi[this.targetType](targetId, contactLog);
  }
}
