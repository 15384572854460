import { billingUrls } from "@common/api/billingUrls";
import { ajax } from "@common/services/ajax";
import {
  PaginationEndpointResponseType,
  TriggerEdiResponseType,
} from "@common/types/apiResponseTypes";
import { IdType, ZuoraIdType } from "@common/types/apiTypes";
import { ValidInboundEDIType } from "@common/types/ediTypes";
import { OfferSnapshotType } from "@common/types/offerSnapshotTypes";
import {
  CreditCardRetrieveType,
  UpdatePaymentMethodType,
} from "@common/types/paymentMethodTypes";
import { TemperatureType, UsageType } from "@common/types/usageTypes";
import { rhapsodyUrls } from "@ops-api/api/rhapsodyUrls";
import {
  BillComparisonType,
  BillFactorsType,
} from "@ops-models/types/billBreakdownTypes";
import { InvoiceType } from "@ops-models/types/invoiceTypes";
import { OfferSnapshotSearchOptions } from "@ops-models/types/offerSnapshotTypes";
import {
  ProductContractCancelRequestType,
  ProductContractClaimType,
  ProductContractStatusFilter,
  ProductContractType,
} from "@ops-models/types/productContractTypes";
import {
  ProductCreateRequestType,
  ProductType,
} from "@ops-models/types/productTypes";

export const rhapsodyApi = {
  ediProvider: {
    getAllowableInboundEDIActions: (
      premiseId: IdType
    ): Promise<{ valid: ValidInboundEDIType[] }> => {
      return ajax.get(
        rhapsodyUrls.ediProvider.getAllowableInboundEDIActions(),
        {
          params: {
            premise_id: premiseId,
          },
        }
      );
    },
    triggerEDIGeneration: (
      premiseId: IdType,
      ediIntent: string
    ): Promise<TriggerEdiResponseType> => {
      return ajax.post(rhapsodyUrls.ediProvider.triggerEdiGeneration(), {
        ediIntent,
        premiseId,
      });
    },
  },
  invoices: {
    compare: (invoiceId: IdType) =>
      ajax.get<BillComparisonType>(rhapsodyUrls.invoices.compare(invoiceId)),
    factors: (invoiceId: IdType) =>
      ajax.get<BillFactorsType>(rhapsodyUrls.invoices.factors(invoiceId)),
    retrieve: (id: IdType) =>
      ajax.get<InvoiceType>(rhapsodyUrls.invoices.retrieve(id)),
    temperature: (invoiceId: IdType) =>
      ajax.get<{ temperatures: TemperatureType[] }>(
        rhapsodyUrls.invoices.temperature(invoiceId)
      ),
    usage: (invoiceId: IdType) =>
      ajax.get<UsageType[]>(rhapsodyUrls.invoices.usage(invoiceId)),
  },
  offerSnapshots: {
    list: (options: OfferSnapshotSearchOptions) => {
      return ajax.get<PaginationEndpointResponseType<OfferSnapshotType>>(
        rhapsodyUrls.offerSnapshots.list(),
        {
          params: options,
        }
      );
    },
    retrieve: (id: IdType) => {
      return ajax.get<OfferSnapshotType>(
        rhapsodyUrls.offerSnapshots.retrieve(id)
      );
    },
  },
  paymentMethods: {
    default: ({
      id,
      paymentMethodData,
    }: {
      id: ZuoraIdType;
      paymentMethodData: UpdatePaymentMethodType;
    }): Promise<CreditCardRetrieveType> => {
      return ajax.put(
        billingUrls.paymentMethods.default(id),
        paymentMethodData
      );
    },
    update: ({
      id,
      paymentMethodData,
    }: {
      id: ZuoraIdType;
      paymentMethodData: UpdatePaymentMethodType;
    }): Promise<CreditCardRetrieveType> => {
      return ajax.put(billingUrls.paymentMethods.update(id), paymentMethodData);
    },
  },
  productContractClaims: {
    list: (premiseId?: IdType): Promise<ProductContractClaimType[]> => {
      return ajax.get(rhapsodyUrls.productContractClaims.list(), {
        params: {
          ordering: "-stored_at",
          premise: premiseId,
        },
      });
    },
  },
  productContracts: {
    cancel: (data: ProductContractCancelRequestType): Promise<null> => {
      return ajax.patch(rhapsodyUrls.productContracts.cancel(), data);
    },
    create: (data: ProductCreateRequestType): Promise<null> => {
      return ajax.post(rhapsodyUrls.productContracts.create(), data);
    },
    list: (
      premiseId?: IdType,
      status?: ProductContractStatusFilter
    ): Promise<PaginationEndpointResponseType<ProductContractType>> => {
      return ajax.get(rhapsodyUrls.productContracts.list(), {
        params: {
          premise: premiseId,
          status,
        },
      });
    },
  },
  products: {
    availableForPremise: (
      premiseId: IdType
    ): Promise<PaginationEndpointResponseType<ProductType>> => {
      return ajax.get(rhapsodyUrls.products.availableForPremise(), {
        params: {
          availableForPremise: premiseId,
        },
      });
    },
    list: (): Promise<PaginationEndpointResponseType<ProductType>> =>
      ajax.get(rhapsodyUrls.products.list()),
  },
};
