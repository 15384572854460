import { green, red } from "@ops-design-system/styles/palette.colors";
import { DeviceStatus } from "@ops-models/types/deviceTypes";

export const deviceStatusColor = (status: DeviceStatus) => {
  if (status === DeviceStatus.Online) {
    return green[500];
  }

  return red.main;
};
