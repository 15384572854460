import { Address } from "@common/models/Address.model";
import { AddressForm } from "@common/models/AddressForm.model";
import { OfferSnapshot } from "@common/models/OfferSnapshot.model";
import { EnrollmentType } from "@common/types/customerTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { convertSnakeCaseToTitleCase } from "@common/utils/stringFormatters";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import {
  PremiseDetailsAutoPayWarningText,
  PremiseDetailsContainer,
  PremiseDetailsDetails,
  PremiseDetailsHeader,
  PremiseDetailsInfoColumn,
  PremiseDetailsInfoSubsection,
  PremiseDetailsPaperlessWarningText,
} from "@ops/components/PremiseDetails/PremiseDetails.styled";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import React, { PropsWithChildren } from "react";

interface InfoDetailsProps {
  inline?: boolean;
  label: string;
}

const InfoDetails = ({
  label,
  inline,
  children,
}: PropsWithChildren<InfoDetailsProps>) => {
  const as = inline ? "span" : "div";

  return (
    <div aria-label={label} role="region">
      <Body1 as={as} $fontWeight="SemiBold">
        {label}
      </Body1>
      <PremiseDetailsDetails as={as}>
        {inline && " "}
        {children}
      </PremiseDetailsDetails>
    </div>
  );
};

export enum PremiseDetailType {
  DEFAULT = "default",
  TRANSFER_FROM = "Transfer from",
  TRANSFER_TO = "Transfer to",
}

export type PremiseDetailsProps = {
  autopay?: boolean;
  enrollmentType?: EnrollmentType;
  esiId?: string | null;
  mailingAddress: AddressForm | Address;
  moveDate?: string | null;
  offerSnapshot: OfferSnapshot;
  orderEndDate?: string | null;
  orderStartDate: string;
  paperless: boolean;
  serviceAddress: AddressForm | Address;
  type?: PremiseDetailType;
  utilityName?: string | null;
};

export const PremiseDetails = ({
  type = PremiseDetailType.DEFAULT,
  serviceAddress,
  mailingAddress,
  offerSnapshot,
  orderStartDate,
  orderEndDate,
  paperless,
  autopay,
  moveDate,
  enrollmentType,
  esiId,
  utilityName,
}: PremiseDetailsProps) => {
  const showPaperlessWarning =
    type !== PremiseDetailType.TRANSFER_FROM &&
    offerSnapshot.isAutoPayPaperlessDiscountOffer &&
    !paperless;

  const showAutoPayWarning =
    type !== PremiseDetailType.TRANSFER_FROM &&
    !autopay &&
    offerSnapshot.isAutoPayPaperlessDiscountOffer;

  const labelQualifier = {
    [PremiseDetailType.TRANSFER_FROM]: "Current ",
    [PremiseDetailType.TRANSFER_TO]: "New ",
    [PremiseDetailType.DEFAULT]: "",
  };

  const termMonthsAndRate = `${offerSnapshot.termMonths} months, ${
    offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
  } Rate`;

  return (
    <div>
      {type !== PremiseDetailType.DEFAULT && (
        <FormRow>
          <PremiseDetailsHeader>{type}</PremiseDetailsHeader>
        </FormRow>
      )}
      <PremiseDetailsContainer>
        <PremiseDetailsInfoColumn>
          <PremiseDetailsInfoSubsection>
            <InfoDetails label={`${labelQualifier[type]}Address`}>
              {serviceAddress.line1}
              <br />
              {serviceAddress.line2}
            </InfoDetails>
            <InfoDetails label={`${labelQualifier[type]}Mailing Address`}>
              {mailingAddress.line1}
              <br />
              {mailingAddress.line2}
            </InfoDetails>
          </PremiseDetailsInfoSubsection>
          <PremiseDetailsInfoSubsection>
            <InfoDetails label={`${labelQualifier[type]}ESI ID`}>
              {esiId}
            </InfoDetails>
            <InfoDetails label={`${labelQualifier[type]}Utility`}>
              {utilityName}
            </InfoDetails>
          </PremiseDetailsInfoSubsection>
        </PremiseDetailsInfoColumn>
        <PremiseDetailsInfoColumn>
          <InfoDetails label={`${labelQualifier[type]}Plan`}>
            <div>Plan: {offerSnapshot.title}</div>
            <div>
              Energy Rate:{" "}
              {offerSnapshot.isTimeOfUse ? (
                <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
              ) : (
                formatEnergyRate(offerSnapshot.energyRate())
              )}
            </div>
            {offerSnapshot.solarEligible &&
            !offerSnapshot.isTimeOfUseGeneration ? (
              <div>
                Buyback Rate:{" "}
                {formatEnergyRate(offerSnapshot.solarCreditKwhRate)}
              </div>
            ) : null}
            <div>
              Avg Price (2000kWh):{" "}
              {formatEnergyRate(offerSnapshot.price2000Kwh)}
            </div>
            <div>{termMonthsAndRate}</div>
          </InfoDetails>
          <InfoDetails inline label="Start Date:">
            {formatMonthDayYear(orderStartDate)}
          </InfoDetails>
          <InfoDetails inline label="End Date:">
            {formatMonthDayYear(orderEndDate)}
          </InfoDetails>
        </PremiseDetailsInfoColumn>
        <PremiseDetailsInfoColumn>
          <InfoDetails inline label="Paperless:">
            {onOff(paperless)}
          </InfoDetails>
          {showPaperlessWarning ? (
            <PremiseDetailsPaperlessWarningText>
              Auto Pay and Paperless must be turned on for the Auto Pay and
              Paperless credit.
            </PremiseDetailsPaperlessWarningText>
          ) : null}
          <InfoDetails inline label="Autopay:">
            {onOff(autopay)}
          </InfoDetails>

          {showAutoPayWarning ? (
            <PremiseDetailsAutoPayWarningText>
              Auto Pay and Paperless must be turned on for the Auto Pay and
              Paperless credit.
            </PremiseDetailsAutoPayWarningText>
          ) : null}
        </PremiseDetailsInfoColumn>
        <PremiseDetailsInfoColumn>
          {type === PremiseDetailType.DEFAULT ? (
            <>
              <InfoDetails inline label="Start Date:">
                {formatMonthDayYear(orderStartDate)}
              </InfoDetails>
              <InfoDetails inline label="Type:">
                {convertSnakeCaseToTitleCase(enrollmentType ?? "")}
              </InfoDetails>
            </>
          ) : (
            <InfoDetails
              label={`Move ${
                type === PremiseDetailType.TRANSFER_FROM ? "Out" : "In"
              } Date:`}
            >
              {moveDate ? formatMonthDayYear(moveDate) : "Unknown"}
            </InfoDetails>
          )}
        </PremiseDetailsInfoColumn>
      </PremiseDetailsContainer>
    </div>
  );
};
