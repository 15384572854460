import { premiseApi } from "@common/api/premiseApi";
import { AuthGroupsContext } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { unmaskCurrency } from "@common/forms/currency.mask";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { Premise } from "@common/models/Premise.model";
import { isOps } from "@common/utils/authenticationHelpers";
import { formatCurrency } from "@common/utils/dataFormatters";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoToggleField } from "@ops-design-system/components/BoToggleField/BoToggleField";
import { premiseRefetch } from "@ops-redux/slices/premiseSlice";
import { BoCurrencyField } from "@ops/components/BoCurrencyField/BoCurrencyField";
import React, { useContext } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

import { StyledAverageBillingInput } from "./AverageBillingPreference.styled";

interface EditAverageBillingDialogProps {
  close: () => void;
  open: boolean;
  premise: Premise;
}

interface AverageBillingFormValues {
  averageBilling: boolean;
  averageBillingMonthlyCharge: string;
}
export const averageBillingFormValidators =
  generateValidationErrorCollector<AverageBillingFormValues>({
    averageBillingMonthlyCharge: [isRequired],
  });

export const EditAverageBillingDialog = ({
  premise,
  open,
  close,
}: EditAverageBillingDialogProps) => {
  const { groups } = useContext(AuthGroupsContext);
  const editDisabled = isOps(groups) !== true;
  const flash = useRhFlash();

  const dispatch = useDispatch();

  if (!premise) {
    return null;
  }

  const handleSubmit = (formValues: AverageBillingFormValues) => {
    const { averageBillingMonthlyCharge } = formValues;
    const { averageBilling } = formValues;

    if (!averageBilling) {
      return handleUpdateAverageBillingOnOff(averageBilling);
    } else if (averageBilling && averageBillingMonthlyCharge) {
      const averageBillingMonthlyChargeAmt = unmaskCurrency(
        averageBillingMonthlyCharge
      );

      return handleUpdateAverageBillingCharge(averageBillingMonthlyChargeAmt);
    }
  };

  const handleUpdateAverageBillingCharge = async (
    averageBillingMonthlyCharge: string
  ) => {
    const [error] = await handleAjaxCall(
      premiseApi.setAveragedBillingMonthlyCharge(
        premise.id,
        averageBillingMonthlyCharge
      )
    );

    if (error) {
      flash.error(
        "Encountered an error while updating this customer's average billing monthly charge."
      );
      return;
    }
    dispatch(premiseRefetch(premise.id));
    close();
  };
  const handleUpdateAverageBillingOnOff = async (averageBilling: boolean) => {
    const [error] = await handleAjaxCall(
      premiseApi.setAveragedBilling(premise.id, averageBilling)
    );

    if (error) {
      flash.error(
        "Encountered an error while updating this customer's average billing preferences."
      );
      return;
    }
    dispatch(premiseRefetch(premise.id));
    close();
  };

  return (
    <BoModal open={open} onClose={close}>
      <Form<AverageBillingFormValues>
        initialValues={{
          averageBilling: premise.averagedBilling,
          averageBillingMonthlyCharge: premise.averagedBillingMonthlyCharge
            ? formatCurrency(premise.averagedBillingMonthlyCharge)
            : "",
        }}
        onSubmit={handleSubmit}
        validate={averageBillingFormValidators}
        validateOnBlur
        render={({
          handleSubmit: formHandleSubmit,
          values,
          hasValidationErrors,
        }) => {
          return (
            <form noValidate onSubmit={formHandleSubmit}>
              <BoDialogHeader>Average Billing</BoDialogHeader>
              <BoDialogBody>
                <StyledAverageBillingInput>
                  <BoCurrencyField
                    name="averageBillingMonthlyCharge"
                    placeholder=""
                    disabled={editDisabled || !values.averageBilling}
                    autoFocus
                  >
                    Average Billing Per Month
                  </BoCurrencyField>
                  <BoToggleField name="averageBilling" withIndicator />
                </StyledAverageBillingInput>
              </BoDialogBody>
              <BoDialogButtonFooter
                cancelDisabled={false}
                confirmDisabled={
                  !premise.averagedBillingMonthlyCharge || hasValidationErrors
                }
                confirmBtnText="Update"
                cancelBtnText="Cancel"
                confirmBtnType="submit"
                onCancel={close}
              />
            </form>
          );
        }}
      />
    </BoModal>
  );
};
