import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { green, red } from "@design-system/theme/palette.colors";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { DeviceStatus } from "@ops-models/types/deviceTypes";
import styled from "styled-components";

export const StyledInfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(2)};
  padding-top: ${rhSpacingPx(2)};
  width: 100%;

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-flow: row nowrap;
  }
`;

export const SelectDeviceInfoColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: flex-start;
  text-transform: capitalize;
  width: 100%;

  > * {
    flex: 1;
  }

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-flow: column nowrap;
  }
`;

export const SelectDeviceStatus = styled(Body1)<{ $status: DeviceStatus }>`
  color: ${({ $status }) =>
    $status === DeviceStatus.Online ? green.main : red.main};
  font-weight: ${FontWeight.Semibold};
  text-transform: capitalize;
`;

export const DeviceBrandText = styled(Body1)`
  text-transform: capitalize;
`;
