import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface EditPaymentExtensionData extends EventLogBaseData {
  newAmountExtended: string;
  newExtendToDate: string;
  previousAmountExtended: string;
  previousExtendToDate: string;
}

export class EditPaymentExtension extends EventLog {
  public readonly previousExtendToDate: string;
  public readonly newExtendToDate: string;
  public readonly previousAmountExtended: string;
  public readonly newAmountExtended: string;

  constructor({
    previousExtendToDate,
    newExtendToDate,
    previousAmountExtended,
    newAmountExtended,
    ...baseEventData
  }: EditPaymentExtensionData) {
    super(baseEventData);

    this.previousExtendToDate = previousExtendToDate;
    this.newExtendToDate = newExtendToDate;
    this.previousAmountExtended = previousAmountExtended;
    this.newAmountExtended = newAmountExtended;
  }

  override get body() {
    return `Payment extension of ${formatCurrency(
      Number(this.previousAmountExtended)
    )} with extended date of ${formatMonthDayYear(
      this.previousExtendToDate
    )} was updated to ${formatCurrency(
      Number(this.newAmountExtended)
    )} with extended date of ${formatMonthDayYear(this.newExtendToDate)}`;
  }
}
