import { UUIDType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  Body3,
  Subtitle1,
} from "@ops-design-system/components/Typography/Typography";
import {
  darkPurple,
  grey,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import {
  rhOpsSpacingPx,
  visuallyHidden,
} from "@ops-design-system/utils/styleHelpers";
import { ProductTierType } from "@ops-models/types/productTypes";
import React from "react";
import { useField, useForm, useFormState } from "react-final-form";
import styled from "styled-components";

interface ProductSelectionProps {
  productTier: ProductTierType;
  selectionRequired?: boolean;
}

const StyledRate = styled(Subtitle1)`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
`;

const StyledFakeButton = styled.span`
  border-radius: ${rhOpsSpacingPx(2)};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(1)};
  text-align: center;
  width: ${rhOpsSpacingPx(14)};
`;

const StyledLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
  justify-content: center;
  max-width: 128px;
  padding: 0 ${rhOpsSpacingPx(2)} ${rhOpsSpacingPx(2)};
  &:hover {
    background-color: ${darkPurple["50"]};
  }
`;

const StyledAddButton = styled(StyledFakeButton)`
  background-color: ${darkPurple["500"]};
  color: ${trueWhite};
`;

const StyledRemoveButton = styled(StyledFakeButton)`
  background-color: ${trueWhite};
  color: ${darkPurple["500"]};
  outline: 1px solid ${darkPurple["500"]};
`;

const StyledRateContainer = styled.span`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;

const HiddenInput = styled.input`
  ${visuallyHidden}
  &:checked ~ ${StyledLabel} {
    border-radius: ${rhOpsSpacingPx(0.5)};
    outline: 1px solid ${darkPurple["500"]};
  }
`;

const StyledBody3 = styled(Body3)`
  color: ${grey["500"]};
`;

interface FormValues {
  productSelection: UUIDType;
}

export const ProductTierSelection = ({
  productTier,
  selectionRequired = true,
}: ProductSelectionProps) => {
  // use a validator if requested
  const required = selectionRequired
    ? (value: string) => (value ? undefined : "Required")
    : () => {};
  const { values: formValues } = useFormState<FormValues>();
  const { reset } = useForm();

  const { input } = useField("productSelection", {
    type: "radio",
    validate: required,
    value: productTier.prices[0].id,
  });

  // TODO maybe update for React 18?
  const id = `productTier-${productTier.id}`;

  return (
    <BoFlexBox
      $flexDirection="column"
      $justifyContent="start"
      $alignItems="center"
    >
      <HiddenInput
        onClick={(el) => {
          const target = el.target as HTMLInputElement;

          if (formValues.productSelection === target.value) {
            reset({});
          }
        }}
        {...input}
        id={id}
      />
      <StyledLabel htmlFor={id} aria-label={productTier.name}>
        <Subtitle1>{productTier.name}</Subtitle1>
        <StyledRateContainer>
          <StyledRate>
            {formatCurrency(productTier.prices[0].rate)}/mo
          </StyledRate>
          <StyledBody3>{productTier.description}</StyledBody3>
        </StyledRateContainer>
        {input.checked ? (
          <StyledRemoveButton>Remove</StyledRemoveButton>
        ) : (
          <StyledAddButton>Add On</StyledAddButton>
        )}
      </StyledLabel>
    </BoFlexBox>
  );
};
