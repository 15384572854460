import { formatCurrency } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CustomerSignUpCompletedData extends EventLogBaseData {
  depositAlternativeFeeAmount: string;
  depositAlternativePaid: boolean;
  enrollmentDepositAmount: string;
  enrollmentDepositPaid: boolean;
}

export class CustomerSignUpCompleted extends EventLog {
  public readonly enrollmentDepositAmount: string;
  public readonly enrollmentDepositPaid: boolean;
  public readonly depositAlternativePaid: boolean;
  public readonly depositAlternativeFeeAmount: string;

  constructor({
    enrollmentDepositAmount,
    enrollmentDepositPaid,
    depositAlternativeFeeAmount,
    depositAlternativePaid,
    ...baseEventData
  }: CustomerSignUpCompletedData) {
    super(baseEventData);

    this.enrollmentDepositAmount = enrollmentDepositAmount;
    this.enrollmentDepositPaid = enrollmentDepositPaid;
    this.depositAlternativePaid = depositAlternativePaid;
    this.depositAlternativeFeeAmount = depositAlternativeFeeAmount;
  }

  override get body() {
    if (this.depositAlternativePaid) {
      return `${this.name} - Deposit Alternative Paid: ${formatCurrency(
        this.depositAlternativeFeeAmount
      )}`;
    }
    if (this.enrollmentDepositPaid) {
      return `${this.name} - Enrollment Deposit Amount Paid: ${formatCurrency(
        this.enrollmentDepositAmount
      )}`;
    }

    return `${this.name}`;
  }
}
