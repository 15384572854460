import { PriceTierSnapshotType } from "@common/types/priceTierSnapshotType";
import {
  formatPercentage,
  formatTimeOfUseScheduleDescription,
} from "@common/utils/dataFormatters";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { formatEnergyRate } from "@ops/formatters";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import styled from "styled-components";

const StyledBoTable = styled(BoTable)`
  width: 100%;
`;

interface TOUPricingTableProps {
  priceTiers: PriceTierSnapshotType[];
}

export const TOUPricingTable = (props: TOUPricingTableProps) => {
  const columnHelper = createColumnHelper<PriceTierSnapshotType>();

  const columns = [
    columnHelper.accessor("name", { header: "Energy Charge Period" }),
    columnHelper.accessor("scheduleDescription", {
      cell: (descProps) =>
        formatTimeOfUseScheduleDescription(descProps.getValue()),
      header: "Energy Charge Hours",
    }),
    columnHelper.accessor("consumptionPricePerKwh", {
      cell: (priceProps) => formatEnergyRate(priceProps.getValue(), 3),
      header: () => (
        <>
          Energy Charge Rate
          <br />
          (¢ per kWh)
        </>
      ),
    }),
    columnHelper.accessor("expectedVolumePercentage", {
      cell: (volumeProps) =>
        formatPercentage({ locale: "en", value: volumeProps.getValue() }),
      header: "Expected Usage",
    }),
  ];

  const table = useReactTable({
    columns,
    data: props.priceTiers.sort(
      (a, b) =>
        Number(a.consumptionPricePerKwh) - Number(b.consumptionPricePerKwh)
    ),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <StyledBoTable>
      <BoTableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <BoTableRow key={headerGroup.id} aria-label="Header Row">
            {headerGroup.headers.map((header) => {
              return (
                <BoTableHeadCell key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </BoTableHeadCell>
              );
            })}
          </BoTableRow>
        ))}
      </BoTableHead>

      <BoTableBody>
        {table.getRowModel().rows.length > 0 ? (
          table.getRowModel().rows.map((row) => {
            return (
              <BoTableRow key={row.id} aria-label={`Row-${row.id}`}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <BoTableCell key={cell.id} $align="center">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </BoTableCell>
                  );
                })}
              </BoTableRow>
            );
          })
        ) : (
          <BoTableRow aria-label="No Data Row">
            <Body1>No Data Available</Body1>
          </BoTableRow>
        )}
      </BoTableBody>
    </StyledBoTable>
  );
};
