import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";
import { AdverseActionLogged } from "@ops/models/EventLogs/AdverseActionLogged.model";
import { AffiliateCreated } from "@ops/models/EventLogs/AffiliateCreated.model";
import { AffiliateMonthlyReport } from "@ops/models/EventLogs/AffiliateMonthlyReport.model";
import { AutopayPaperlessReminder } from "@ops/models/EventLogs/AutopayPaperlessReminder.model";
import { AutopayPreferenceUpdated } from "@ops/models/EventLogs/AutopayPreferenceUpdated.model";
import { AveragedBillingChanged } from "@ops/models/EventLogs/AveragedBillingChanged.model";
import { BackDatedMoveIn } from "@ops/models/EventLogs/BackDatedMoveIn.model";
import { BillSpike } from "@ops/models/EventLogs/BillSpike.model";
import { CancelRebill } from "@ops/models/EventLogs/CancelRebill.model";
import { CancelRebillInvoiceDeleted } from "@ops/models/EventLogs/CancelRebillInvoiceDeleted.model";
import { ContractExpiration } from "@ops/models/EventLogs/ContractExpiration.model";
import { CreditCardExpiringSoon } from "@ops/models/EventLogs/CreditCardExpiringSoon.model";
import { CSRContractSwap } from "@ops/models/EventLogs/CSRContractSwap.model";
import { CustomerClaimSubmitted } from "@ops/models/EventLogs/CustomerClaimSubmitted.model";
import { CustomerForgotPassword } from "@ops/models/EventLogs/CustomerForgotPassword.model";
import { CustomerSignUpCompleted } from "@ops/models/EventLogs/CustomerSignUpCompleted.model";
import { DeferredPaymentPlanContractGeneration } from "@ops/models/EventLogs/DeferredPaymentPlanContractGeneration.model";
import { DeferredPaymentPlanCreation } from "@ops/models/EventLogs/DeferredPaymentPlanCreation.model";
import { DeferredPaymentPlanDefaultSettlement } from "@ops/models/EventLogs/DeferredPaymentPlanDefaultSettlement.model";
import { DeferredPaymentPlanEarlySettlement } from "@ops/models/EventLogs/DeferredPaymentPlanEarlySettlement.model";
import { DeferredPaymentPlanMoveOutSettlement } from "@ops/models/EventLogs/DeferredPaymentPlanMoveOutSettlement.model";
import { DemandLetterSent } from "@ops/models/EventLogs/DemandLetterSent.model";
import { DisconnectionRequest } from "@ops/models/EventLogs/DisconnectionRequest.model";
import { DisconnectNoticeCreation } from "@ops/models/EventLogs/DisconnectNoticeCreation.model";
import { DisconnectNoticeFeeCreated } from "@ops/models/EventLogs/DisconnectNoticeFeeCreated.model";
import { DropNotification } from "@ops/models/EventLogs/DropNotification.model";
import { EbillPreferenceUpdated } from "@ops/models/EventLogs/EbillPreferenceUpdated.model";
import { EditPaymentExtension } from "@ops/models/EventLogs/EditPaymentExtension.model";
import { EmailDelivered } from "@ops/models/EventLogs/EmailDelivered.model";
import { EnergyContractDeleted } from "@ops/models/EventLogs/EnergyContractDeleted.model";
import { EnergyContractUpdated } from "@ops/models/EventLogs/EnergyContractUpdated.model";
import { InvoiceAvailable } from "@ops/models/EventLogs/InvoiceAvailable.model";
import { InvoiceDeleted } from "@ops/models/EventLogs/InvoiceDeleted.model";
import { InvoicePaid } from "@ops/models/EventLogs/InvoicePaid.model";
import { LateFeeApplied } from "@ops/models/EventLogs/LateFeeApplied.model";
import { LoadProfileChanged } from "@ops/models/EventLogs/LoadProfileChanged.model";
import { MailingAddressUpdated } from "@ops/models/EventLogs/MailingAddressUpdated.model";
import { OneClickRenewal1 } from "@ops/models/EventLogs/OneClickRenewal1.model";
import { OneClickRenewal2 } from "@ops/models/EventLogs/OneClickRenewal2.model";
import { OneClickRenewal3 } from "@ops/models/EventLogs/OneClickRenewal3.model";
import { OneClickRenewal4 } from "@ops/models/EventLogs/OneClickRenewal4.model";
import { OneClickRenewalContractExpiration } from "@ops/models/EventLogs/OneClickRenewalContractExpiration.model";
import { OneClickRenewalExpired1 } from "@ops/models/EventLogs/OneClickRenewalExpired1.model";
import { OneClickRenewalExpired2 } from "@ops/models/EventLogs/OneClickRenewalExpired2.model";
import { OpsContractUpdate } from "@ops/models/EventLogs/OpsContractUpdate.model";
import { PastDueInvoice } from "@ops/models/EventLogs/PastDueInvoice.model";
import { PastDueProductAddOnInvoice } from "@ops/models/EventLogs/PastDueProductAddOnInvoice.model";
import { PaymentExtensionCreated } from "@ops/models/EventLogs/PaymentExtensionCreated.model";
import { PaymentExtensionDue } from "@ops/models/EventLogs/PaymentExtensionDue.model";
import { PaymentExtensionDueTomorrow } from "@ops/models/EventLogs/PaymentExtensionDueTomorrow.model";
import { PaymentExtensionPaidOff } from "@ops/models/EventLogs/PaymentExtensionPaidOff.model";
import { PaymentFailed } from "@ops/models/EventLogs/PaymentFailed.model";
import { PaymentMade } from "@ops/models/EventLogs/PaymentMade.model";
import { PickYourOwnDueDate } from "@ops/models/EventLogs/PickYourOwnDueDate.model";
import { ProductContractCancelled } from "@ops/models/EventLogs/ProductContractCancelled.model";
import { ProductContractCreated } from "@ops/models/EventLogs/ProductContractCreated.model";
import { ProductContractExpirationNotice } from "@ops/models/EventLogs/ProductContractExpirationNotice.model";
import { QuickPayOnDemandTokenResent } from "@ops/models/EventLogs/QuickPayOnDemandTokenResent.model";
import { RAFRewardsAdded } from "@ops/models/EventLogs/RAFRewardsAdded.model";
import { ReconnectionCompleted } from "@ops/models/EventLogs/ReconnectionCompleted.model";
import { ReconnectionRequest } from "@ops/models/EventLogs/ReconnectionRequest.model";
import { ReleaseDeposit } from "@ops/models/EventLogs/ReleaseDeposit.model";
import { RenewalCompleted } from "@ops/models/EventLogs/RenewalCompleted.model";
import { ResubmitEnrollment } from "@ops/models/EventLogs/ResubmitEnrollment.model";
import { ReturnPayment } from "@ops/models/EventLogs/ReturnPayment.model";
import { ReturnPaymentFee } from "@ops/models/EventLogs/ReturnPaymentFee.model";
import { ServiceStartDateAssigned } from "@ops/models/EventLogs/ServiceStartDateAssigned.model";
import { ServiceStartDateUpdated } from "@ops/models/EventLogs/ServiceStartDateUpdated.model";
import { ServiceUnavailable } from "@ops/models/EventLogs/ServiceUnavailable.model";
import { SimplisafeKeyAssigned } from "@ops/models/EventLogs/SimplisafeKeyAssigned.model";
import { SMSDelivered } from "@ops/models/EventLogs/SmsDelivered.model";
import { StatusIndicatorChanged } from "@ops/models/EventLogs/StatusIndicatorChanged.model";
import { ThermostatConnected } from "@ops/models/EventLogs/ThermostatConnected.model";
import { ThermostatOfflineWarning } from "@ops/models/EventLogs/ThermostatOfflineWarning.model";
import { WeeklyRecap } from "@ops/models/EventLogs/WeeklyRecap.model";
import { Winback } from "@ops/models/EventLogs/Winback.model";
import { isProduction } from "@ops/utils/env.util";
import { pascalize } from "humps";

export class EventLogService {
  static definedEventsModels: Record<string, typeof EventLog> = {
    AdverseActionLogged,
    AffiliateCreated,
    AffiliateMonthlyReport,
    AutopayPaperlessReminder,
    AutopayPreferenceUpdated,
    AveragedBillingChanged,
    BackDatedMoveIn,
    BillSpike,
    CSRContractSwap,
    CancelRebill,
    CancelRebillInvoiceDeleted,
    ContractExpiration,
    ContractExpired1: OneClickRenewalExpired1, // Named differently in backend than what needs to be shown to Rhapsody users
    ContractExpired2: OneClickRenewalExpired2, // Named differently in backend than what needs to be shown to Rhapsody users
    CreditCardExpiringSoon,
    CustomerClaimSubmitted,
    CustomerForgotPassword,
    CustomerSignUpCompleted,
    DeferredPaymentPlanContractGeneration,
    DeferredPaymentPlanCreation,
    DeferredPaymentPlanDefaultSettlement,
    DeferredPaymentPlanEarlySettlement,
    DeferredPaymentPlanMoveOutSettlement,
    DemandLetterSent,
    DisconnectNoticeCreation,
    DisconnectNoticeFeeCreated,
    DisconnectionRequest,
    DropNotification,
    EbillPreferenceUpdated,
    EditPaymentExtension,
    EmailDelivered,
    EnergyContractDeleted,
    EnergyContractUpdated,
    InvoiceAvailable,
    InvoiceDeleted,
    InvoicePaid,
    LateFeeApplied,
    LoadProfileChanged,
    MailingAddressUpdated,
    OneClickRenewal1,
    OneClickRenewal2,
    OneClickRenewal3,
    OneClickRenewal4,
    OneClickRenewalContractExpiration,
    OpsContractUpdate,
    PastDueInvoice,
    PastDueProductAddOnInvoice,
    PaymentExtensionCreated,
    PaymentExtensionDue,
    PaymentExtensionDueTomorrow,
    PaymentExtensionPaidOff,
    PaymentFailed,
    PaymentMade,
    PickYourOwnDueDate,
    ProductContractCancelled,
    ProductContractCreated,
    ProductContractExpirationNotice,
    QuickPayOnDemandTokenResent,
    RAFRewardsAdded,
    ReconnectionCompleted,
    ReconnectionRequest,
    ReleaseDeposit,
    RenewalCompleted,
    ResubmitEnrollment,
    ReturnPayment,
    ReturnPaymentFee,
    SMSDelivered,
    ServiceStartDateAssigned,
    ServiceStartDateUpdated,
    ServiceUnavailable,
    SimplisafeKeyAssigned,
    StatusIndicatorChanged,
    ThermostatConnected,
    ThermostatOfflineWarning,
    WeeklyRecap,
    Winback,
  };

  static eventLogModel(eventData: EventLogBaseData) {
    const sanitizedEventType = pascalize(eventData?.event ?? "");
    const Model = EventLogService.definedEventsModels[sanitizedEventType];

    if (!Model) {
      if (!isProduction()) {
        // eslint-disable-next-line no-console
        console.warn(
          `EventLog model not found for type '${eventData?.event}'. The model may need to be created in '@ops/models/EventLogs/' and defined in the 'EventLogService'.`
        );
      }
      return;
    }
    return new Model(eventData);
  }
}
