import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired } from "@common/forms/validators";
import { AddressForm } from "@common/models/AddressForm.model";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FormRow } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AddPremiseWizardForm } from "@ops/components/AddPremiseWizard/AddPremiseWizardForm";
import {
  AddPremiseWizardComponentName,
  initialAddPremiseWizardState,
} from "@ops/components/AddPremiseWizard/AddPremiseWizardProvider";
import { ConfirmMeterField } from "@ops/components/ConfirmMeterField/ConfirmMeterField";
import { addressIsEqual } from "@ops/components/MeterSearchForms/addressFormHelpers";
import {
  ValidatedAddressType,
  useMeterSearchForms,
} from "@ops/components/MeterSearchForms/useMeterSearchForms";
import { useAddPremiseWizardContext } from "@ops/hooks/contexts/useAddPremiseWizardContext";
import React, { useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

interface AddPremiseFormValues {
  confirmValidatedAddress: boolean;
  mailingAddressSameAsServiceAddress: boolean;
}

const ValidationFormsContainer = styled.div`
  width: 480px;
  & > :last-child {
    padding-bottom: 0;
  }
`;
const AddressEsiIdDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const formValidators = generateValidationErrorCollector<AddPremiseFormValues>({
  confirmValidatedAddress: [isRequired],
});

export const AddressMeterForm = () => {
  const {
    updateWizardState,
    wizardState: { validatedAddress, mailingAddress, esiId },
    setActiveComponentName,
  } = useAddPremiseWizardContext();
  const [currentServiceAddress, setCurrentServiceAddress] =
    useState<AddressForm | null>(null);
  const [SearchByAddress, SearchByEsiId] = useMeterSearchForms();
  const flash = useRhFlash();
  const mailingAddressSameAsServiceAddress =
    validatedAddress?.serviceAddress &&
    addressIsEqual(
      new AddressForm(mailingAddress),
      new AddressForm(validatedAddress.serviceAddress)
    );

  const handleAddressEsiIdSuccess = (
    response?: ValidatedAddressType,
    error?: string
  ) => {
    setCurrentServiceAddress(
      response ? new AddressForm(response.serviceAddress) : null
    );

    if (error || !response) {
      flash.error(error);
      return;
    }

    updateWizardState({
      ...initialAddPremiseWizardState,
      offerSnapshotId: "",
      validatedAddress: response,
    });
  };

  const onSubmit = ({
    confirmValidatedAddress,
    mailingAddressSameAsServiceAddress: mailingSameAsService,
  }: AddPremiseFormValues) => {
    if (!confirmValidatedAddress || !validatedAddress) {
      return;
    }
    const { serviceAddress } = validatedAddress;
    const updatedMailingAddress = mailingSameAsService
      ? { mailingAddress: { ...serviceAddress } }
      : {};

    updateWizardState({
      ...validatedAddress,
      ...updatedMailingAddress,
    });

    if (!mailingSameAsService) {
      setActiveComponentName(AddPremiseWizardComponentName.mailingAddressForm);
    } else {
      setActiveComponentName(AddPremiseWizardComponentName.offerForm);
    }
  };

  return (
    <BoFlexBox>
      <ValidationFormsContainer>
        <FormRow>
          <Subtitle2 $fontWeight="Bold">New meter information</Subtitle2>
        </FormRow>

        <FormRow>
          <SearchByAddress
            onSubmit={handleAddressEsiIdSuccess}
            currentServiceAddress={currentServiceAddress}
          />
        </FormRow>
        <AddressEsiIdDivider />
        <FormRow>
          <SearchByEsiId onSubmit={handleAddressEsiIdSuccess} />
        </FormRow>
      </ValidationFormsContainer>
      {Boolean(validatedAddress) && (
        <Form<AddPremiseFormValues>
          initialValues={{
            confirmValidatedAddress: Boolean(esiId),
            mailingAddressSameAsServiceAddress,
          }}
          validate={formValidators}
          validateOnChange
          onSubmit={onSubmit}
          render={({ values: { confirmValidatedAddress } }) => {
            return (
              <AddPremiseWizardForm>
                <ConfirmMeterField
                  validatedAddress={validatedAddress}
                  confirmValidatedAddress={confirmValidatedAddress}
                />
              </AddPremiseWizardForm>
            );
          }}
        />
      )}
    </BoFlexBox>
  );
};
