import { IdType } from "@common/types/apiTypes";
import {
  ContactLogType,
  ContactMethodOptions,
  IContactLog,
} from "@ops-models/types/contactLogTypes";
import { Searchable, safeIncludes } from "@ops/models/searchable";

export class ContactLog implements IContactLog, Searchable {
  public readonly id: IdType;
  public readonly createdAt: string;
  public readonly contactMethod: ContactMethodOptions;
  public readonly category: string;
  public readonly type: string;
  public readonly reason?: string;
  public readonly notes: string;
  public readonly outboundContact: boolean;
  public readonly csrName: string;

  constructor(contactLog: ContactLogType) {
    this.id = contactLog.id;
    this.createdAt = contactLog.createdAt;
    this.contactMethod = contactLog.contactMethod;
    this.category = contactLog.category;
    this.type = contactLog.type;
    this.reason = contactLog.reason;
    this.notes = contactLog.notes;
    this.outboundContact = contactLog.outboundContact;
    this.csrName = contactLog.csrName;
  }

  matches(query: string): boolean {
    return (
      safeIncludes(this.category, query) ||
      safeIncludes(this.type, query) ||
      safeIncludes(this.reason, query) ||
      safeIncludes(this.notes, query) ||
      safeIncludes(this.contactMethod, query) ||
      safeIncludes(this.csrName, query)
    );
  }
}
