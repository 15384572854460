import { urls } from "@common/api/urls";
import { IdType } from "@common/types/apiTypes";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { AXIOS_BASE_URL, CDN_URL } from "@ops/settings/config";
import React, { MouseEventHandler, PropsWithChildren } from "react";

const baseDocumentLinkProps = {
  rel: "noopener noreferrer",
  target: "_blank",
};

interface DocumentLinkProps {
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
}

interface EFLLinkProps extends DocumentLinkProps {
  offerId: IdType;
}

interface TOSLinkProps extends DocumentLinkProps {
  tosUrl: string;
}

export const EFLLink = ({
  offerId,
  onClick,
  children,
}: PropsWithChildren<EFLLinkProps>) => (
  <BoLink
    {...baseDocumentLinkProps}
    href={`${AXIOS_BASE_URL}${urls.pricing.offerSnapshots.efl(offerId, "en")}`}
    onClick={onClick}
  >
    {children}
  </BoLink>
);

export const TOSLink = ({
  tosUrl,
  onClick,
  children,
}: PropsWithChildren<TOSLinkProps>) => (
  <BoLink {...baseDocumentLinkProps} href={tosUrl} onClick={onClick}>
    {children}
  </BoLink>
);

export const YRACLink = ({
  onClick,
  children,
}: PropsWithChildren<DocumentLinkProps>) => (
  <BoLink
    {...baseDocumentLinkProps}
    href={`${CDN_URL}/RhythmYRAC_en.pdf`}
    onClick={onClick}
  >
    {children}
  </BoLink>
);
