import { billingApi } from "@common/api/billingApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { ZuoraIdType } from "@common/types/apiTypes";
import { defaultPaymentFirst } from "@common/utils/paymentMethodHelpers";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { paymentMethodsFetch } from "@ops-redux/slices/paymentMethodsSlice";
import { AddBankAccountButton } from "@ops/components/AddBankAccount/AddBankAccountButton";
import { AddCreditCardButton } from "@ops/components/AddCreditCardForm/AddCreditCardButton";
import { PaymentMode } from "@ops/components/ManagePaymentMethods/ManagePaymentMethodsContextProvider";
import { PaymentMethodRow } from "@ops/components/ManagePaymentMethods/PaymentMethodRow";
import { usePaymentMethodContext } from "@ops/hooks/useManagePaymentContextHook";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { usePremise } from "@ops/hooks/usePremise";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const AddCardButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  width: fit-content;
`;

const EmptyMessageWrapper = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2)};
`;

export const ManagePaymentMethodsDialog = () => {
  const { closeModal, premiseId, setContextMode } = usePaymentMethodContext();
  const flash = useRhFlash();
  const { premise } = usePremise(premiseId);

  const { paymentMethods } = usePaymentMethods(premiseId);

  const dispatch = useDispatch();

  const [{ requestMonitor }, { setPending, setSuccess, setFailure }] =
    useAjaxState<boolean>();

  const showAddAch = !premise?.isNoCheckOrAch;

  const showAddCredit = !premise?.isNoCreditOrDebitCard;

  const showUnableToCollect =
    premise?.isNoCheckOrAch && premise?.isNoCreditOrDebitCard;

  const setDefaultPaymentMethod = (paymentMethodId: ZuoraIdType) => {
    setPending();
    billingApi.paymentMethods
      .default(paymentMethodId, { premiseId: String(premiseId) })
      .then(() => {
        setSuccess(true);
        dispatch(paymentMethodsFetch(premiseId));
      })
      .catch((error) => {
        setFailure(error);
        flash.error("Unable to set a default payment method.");
      });
  };

  const getBody = () => {
    if (requestMonitor.isPending) {
      return <BoCircularProgress />;
    }
    if (!paymentMethods?.length) {
      return (
        <EmptyMessageWrapper>
          <Subtitle2>No payment methods exist.</Subtitle2>
        </EmptyMessageWrapper>
      );
    }

    return (
      <BoGridColumn>
        {paymentMethods.sort(defaultPaymentFirst).map((pm) => {
          const allowDelete =
            !pm.defaultPaymentMethod || paymentMethods.length === 1;

          return (
            <PaymentMethodRow
              key={pm.id}
              paymentMethod={pm}
              setDefaultPaymentMethod={setDefaultPaymentMethod}
              allowDelete={allowDelete}
            />
          );
        })}
      </BoGridColumn>
    );
  };

  return (
    <>
      <BoDialogHeader>Manage Payment Methods</BoDialogHeader>
      <BoDialogBody>
        <BoGrid $variant={GridVariants.Grid1}>
          {getBody()}
          {showUnableToCollect ? (
            <EmptyMessageWrapper>
              <Subtitle2>
                Credit & Debit Cards and ACH payments have been disabled for
                this premise.
              </Subtitle2>
            </EmptyMessageWrapper>
          ) : (
            <AddCardButtonWrapper>
              {showAddCredit && (
                <AddCreditCardButton
                  onClick={() => setContextMode(PaymentMode.addCardMode)}
                />
              )}
              {showAddAch && (
                <AddBankAccountButton
                  onClick={() => setContextMode(PaymentMode.addBankAccountMode)}
                />
              )}
            </AddCardButtonWrapper>
          )}
        </BoGrid>
      </BoDialogBody>
      <BoDialogButtonFooter cancelBtnText="Close" onCancel={closeModal} />
    </>
  );
};
