import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { EventLogBaseData } from "@ops-models/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface ServiceUnavailableData extends EventLogBaseData {
  sentAt: string;
  zipCode: string;
}

export class ServiceUnavailable extends EventLog {
  public readonly sentAt: string;
  public readonly zipCode: string;

  constructor({ sentAt, zipCode, ...baseEventData }: ServiceUnavailableData) {
    super(baseEventData);

    this.sentAt = formatMonthDayYear(sentAt);
    this.zipCode = zipCode;
  }

  override get body() {
    return `${this.name} - Sent At: ${this.sentAt} - Zip Code: ${this.zipCode}`;
  }
}
