import { BillFactorsType } from "@ops-models/types/billBreakdownTypes";
import { StyledBillFactorsContainer } from "@ops/components/BillFactors/BillFactors.styled";
import { BillFactorBillingCycle } from "@ops/components/BillFactors/BillFactorsBillingCycle";
import { BillFactorsContractChanges } from "@ops/components/BillFactors/BillFactorsContractChanges";
import { BillFactorsPastDue } from "@ops/components/BillFactors/BillFactorsPastDue";
import { BillFactorsTDSPChange } from "@ops/components/BillFactors/BillFactorsTDSPChange";
import { BillFactorsUsage } from "@ops/components/BillFactors/BillFactorsUsage";
import { BillFactorsWeather } from "@ops/components/BillFactors/BillFactorsWeather";
import React from "react";

interface BillFactorsProps {
  billFactors: BillFactorsType;
}

export const BillFactors = ({ billFactors }: BillFactorsProps) => {
  const {
    pastDueBalance,
    usage,
    servicePeriod,
    weather,
    contractChanges,
    tdspMwhRateChange,
  } = billFactors;

  return (
    <StyledBillFactorsContainer>
      {weather ? (
        <BillFactorsWeather
          numberOfColdDays={weather.numberOfColdDays}
          numberOfHotDays={weather.numberOfHotDays}
        />
      ) : null}
      {usage ? (
        <BillFactorsUsage differenceInKwh={usage.differenceInKwh} />
      ) : null}
      {tdspMwhRateChange ? (
        <BillFactorsTDSPChange
          differencePercentage={tdspMwhRateChange.differencePercentage}
          utilityName={tdspMwhRateChange.utilityName}
        />
      ) : null}
      {servicePeriod ? (
        <BillFactorBillingCycle
          differenceInDays={servicePeriod.differenceInDays}
        />
      ) : null}
      {contractChanges ? (
        <BillFactorsContractChanges
          isVariableRate={contractChanges.isVariableRate}
          previousContractEndDate={contractChanges.previousContractEndDate}
        />
      ) : null}
      {pastDueBalance ? (
        <BillFactorsPastDue pastDueAmount={pastDueBalance.amount} />
      ) : null}
    </StyledBillFactorsContainer>
  );
};
