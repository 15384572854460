import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { IdType } from "@common/types/apiTypes";
import {
  CreatePaymentExtensionPayloadType,
  DisconnectNoticeType,
  PaymentExtensionType,
} from "@common/types/billingTypes";
import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoDialogBody,
  BoDialogButtonFooter,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { BoTextInput } from "@ops-design-system/components/BoTextInput/BoTextInput";
import { OpsCheckboxInput } from "@ops-design-system/components/OpsCheckboxInput/OpsCheckboxInput";
import {
  Body1,
  H3,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import {
  CreatePaymentDataType,
  PAYMENT_TRANSACTION_FEE,
} from "@ops-models/types/paymentTypes";
import { DownPaymentCalculator } from "@ops/components/DownPaymentCalculator/DownPaymentCalculator";
import { CalculatedFieldLabels } from "@ops/components/DownPaymentCalculator/DownPaymentCalculator.constants";
import { DownPaymentEquation } from "@ops/components/DownPaymentCalculator/DownPaymentEquation";
import { useDownPaymentCalculatorState } from "@ops/components/DownPaymentCalculator/useDownPaymentCalculatorState";
import { DownPaymentQuestionnaire } from "@ops/components/DownPaymentQuestionnaire/DownPaymentQuestionnaire";
import {
  ComputedFormFieldWrapper,
  CustomerBalance,
  FormFieldWrapper,
  PaymentContainer,
  PaymentTransactionFee,
  PaymentTransactionFeeInput,
  PaymentTransactionFeeNote,
  PaymentTransactionFeeRow,
  PurpleCalendarIcon,
  Subheader,
  TotalAmount,
} from "@ops/components/PaymentExtensionForm/PaymentExtensionForm.styled";
import { usePaymentExtensionFormState } from "@ops/components/PaymentExtensionForm/usePaymentExtensionFormState";
import { PaymentExtensionComponentType } from "@ops/components/PaymentExtensionWizardModal/PaymentExtensionComponentType";
import { usePaymentExtensionModalState } from "@ops/components/PaymentExtensionWizardModal/usePaymentExtensionModalStateProvider";
import { PaymentMethodSelector } from "@ops/components/PaymentMethodSelector/PaymentMethodSelector";
import { useCreatePaymentMutation } from "@ops/hooks/mutations/useCreatePayment.mutation";
import { useCreatePaymenExtensiontMutation } from "@ops/hooks/mutations/useCreatePaymentExtension.mutation";
import { usePaymentMethodOptions } from "@ops/hooks/usePaymentMethodOptions";
import { usePaymentMethods } from "@ops/hooks/usePaymentMethods";
import { usePremise } from "@ops/hooks/usePremise";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import React, { ChangeEventHandler, useEffect } from "react";

interface PaymentExtensionFormProps {
  closeModal: () => void;
  onSuccess: (
    paymentExtension: PaymentExtensionType,
    zuoraPaymentNumber?: string
  ) => void;
  pastDueBalance: number;
  premiseId: IdType;
}

export const PaymentExtensionForm = ({
  closeModal,
  pastDueBalance,
  premiseId,
  onSuccess,
}: PaymentExtensionFormProps) => {
  const { premise } = usePremise(premiseId);
  const {
    updateFormState,
    currentPaymentMethod,
    extendToDate,
    includePaymentTransactionFee,
    hasMadeDownPayment,
  } = usePaymentExtensionFormState();

  const { updateCalculatorState, totalAmountDue, downPayment } =
    useDownPaymentCalculatorState();

  const { updateModalState } = usePaymentExtensionModalState();

  const createPaymentMutation = useCreatePaymentMutation();
  const createPaymentExtensionMutation = useCreatePaymenExtensiontMutation();

  const { featureFlagClient } = useFeatureFlagClient();
  const { opsPaymentTransactionFee } = featureFlagClient.getFlags([
    ["opsPaymentTransactionFee", false],
  ]);

  const handlePaymentMethodSelection = (
    paymentMethodOption: BoSelectOptionType | null
  ) => {
    if (paymentMethodOption) {
      updateFormState({
        currentPaymentMethod: paymentMethodOption.value,
      });
    }
  };

  const handleQuestionnaireChange = (val: boolean) => {
    updateFormState({ hasMadeDownPayment: val });
  };

  const flash = useRhFlash();

  const { paymentMethods } = usePaymentMethods(premiseId);
  const { defaultPaymentMethod, paymentMethodOptions } =
    usePaymentMethodOptions(paymentMethods);

  const handleAddPayment = () => {
    updateModalState({
      activeComponent: PaymentExtensionComponentType.addPaymentMethod,
    });
  };

  useEffect(() => {
    if (
      paymentMethods?.length &&
      !currentPaymentMethod &&
      defaultPaymentMethod
    ) {
      updateFormState({ currentPaymentMethod: defaultPaymentMethod.id });
    }
  }, [
    updateFormState,
    paymentMethods,
    currentPaymentMethod,
    defaultPaymentMethod,
  ]);

  const handleDateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    updateFormState({ extendToDate: event.target.value });
  };

  const handlePaymentTransactionFeeChanged: ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    updateFormState({
      includePaymentTransactionFee: e.target.checked,
    });
  };

  const isFormValid =
    extendToDate !== "" &&
    dayjs().isBefore(extendToDate) &&
    hasMadeDownPayment !== undefined &&
    (hasMadeDownPayment || Boolean(currentPaymentMethod));

  useEffect(() => {
    updateCalculatorState({ totalAmountDue: pastDueBalance });
  }, [pastDueBalance]);

  const createPaymentExtension = (
    paymentExtensionData: CreatePaymentExtensionPayloadType,
    paymentNumber?: string
  ) => {
    return createPaymentExtensionMutation.mutate(
      { paymentExtensionData, premiseId },
      {
        onError: () =>
          flash.error(
            "There was an error trying to create payment extension. Try again later"
          ),
        onSuccess: (result) => {
          onSuccess(result, paymentNumber);
        },
      }
    );
  };

  const submitPaymentExtension = () => {
    const paymentExtensionData = {
      amountExtended: (totalAmountDue - downPayment).toFixed(2),
      amountPaid: downPayment.toFixed(2),
      extendToDate,
    };

    if (hasMadeDownPayment) {
      return createPaymentExtension(paymentExtensionData);
    }

    const paymentData: CreatePaymentDataType = {
      amount: totalAmount,
      paymentMethodId: `${currentPaymentMethod}`,
    };

    if (shouldShowTransactionFee && includePaymentTransactionFee) {
      paymentData.paymentTransactionFee = PAYMENT_TRANSACTION_FEE;
    }

    createPaymentMutation.mutate(
      {
        paymentData,
        premiseId,
      },
      {
        onError: () => {
          flash.error(
            "An error occurred while making the down payment. Try again later"
          );
        },
        onSuccess: (response) => {
          const { paymentNumber } = response;

          return createPaymentExtension(paymentExtensionData, paymentNumber);
        },
      }
    );
  };

  const disconnectNotice: DisconnectNoticeType = orderBy(
    premise?.disconnectNotices ?? [],
    "disconnectionDate",
    "desc"
  )[0];

  const disconnectDate = disconnectNotice?.disconnectionDate;

  const shouldShowTransactionFee =
    premise?.isPaymentTransactionFeeAllowed && opsPaymentTransactionFee.value;

  const totalAmount =
    shouldShowTransactionFee && includePaymentTransactionFee
      ? downPayment + PAYMENT_TRANSACTION_FEE
      : downPayment;

  return (
    <>
      <BoDialogHeader>Payment Extension</BoDialogHeader>
      <BoDialogBody>
        <BoGrid $variant={GridVariants.Grid2_1}>
          <BoGridColumn>
            <Subheader>
              <div>
                Past due balance:{" "}
                <CustomerBalance>
                  {formatCurrency(pastDueBalance)}
                </CustomerBalance>
              </div>
              {disconnectDate && (
                <div>
                  DNP Expiration Date: {formatMonthDayShortYear(disconnectDate)}
                </div>
              )}
            </Subheader>
            <BoDivider />
            <ComputedFormFieldWrapper>
              <DownPaymentEquation
                calculatedFieldLabel={CalculatedFieldLabels.paymentExtension}
              />
              <BoFlexBox>
                <FormFieldWrapper>
                  <BoTextInput
                    name="extendToDate"
                    type="date"
                    icon={PurpleCalendarIcon}
                    placeholder="YYYY-MM-DD"
                    onChange={handleDateChange}
                    value={extendToDate}
                  >
                    Extend to Date
                  </BoTextInput>
                </FormFieldWrapper>
              </BoFlexBox>
            </ComputedFormFieldWrapper>
            <BoDivider />
            <DownPaymentQuestionnaire
              handleChange={handleQuestionnaireChange}
              hasMadeDownPayment={hasMadeDownPayment}
            />
            {hasMadeDownPayment === false && (
              <PaymentContainer>
                {shouldShowTransactionFee ? (
                  <PaymentTransactionFee>
                    <PaymentTransactionFeeRow>
                      <PaymentTransactionFeeInput>
                        <OpsCheckboxInput
                          id="payment-fee"
                          checked={includePaymentTransactionFee}
                          onChange={handlePaymentTransactionFeeChanged}
                        />
                        <label htmlFor="payment-fee">
                          Payment Transaction Fee
                        </label>
                      </PaymentTransactionFeeInput>
                      <Body1>{formatCurrency(PAYMENT_TRANSACTION_FEE)}</Body1>
                    </PaymentTransactionFeeRow>
                    <PaymentTransactionFeeNote>
                      Note: A ${PAYMENT_TRANSACTION_FEE} Payment Transaction Fee
                      will be applied to process this payment
                    </PaymentTransactionFeeNote>
                  </PaymentTransactionFee>
                ) : null}
                <TotalAmount data-testid="total-amount">
                  <Subtitle2>Total Payment Amount</Subtitle2>
                  <H3 $fontWeight="Bold">{formatCurrency(totalAmount)}</H3>
                </TotalAmount>
                <PaymentMethodSelector
                  current={
                    currentPaymentMethod ?? defaultPaymentMethod?.id ?? null
                  }
                  options={paymentMethodOptions}
                  onChange={handlePaymentMethodSelection}
                  noCreditOrDebitCard={premise?.isNoCreditOrDebitCard ?? true}
                  handleAddPayment={handleAddPayment}
                />
              </PaymentContainer>
            )}
          </BoGridColumn>
          <BoGridColumn>
            <DownPaymentCalculator />
          </BoGridColumn>
        </BoGrid>
      </BoDialogBody>
      <BoDialogButtonFooter
        confirmBtnText={
          hasMadeDownPayment
            ? "Extend Payment"
            : "Extend Payment and Pay Down Payment"
        }
        cancelBtnText="Cancel"
        confirmDisabled={!isFormValid}
        onConfirm={submitPaymentExtension}
        onCancel={closeModal}
        confirmLoading={
          createPaymentExtensionMutation.isPending ||
          createPaymentMutation.isPending
        }
      />
    </>
  );
};
