import { affiliateApi } from "@common/api/affiliateApi";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { isRequired, isValidEmail } from "@common/forms/validators";
import { AffiliateType } from "@common/types/affiliateType";
import { RhApiError } from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { AFFILIATE_UNIQUE_EMAIL_ERROR_CODE } from "@ops/constants/affiliates.constants";
import { AffiliateFields } from "@ops/pages/NewAffiliatePage/NewAffiliateFields";
import { affiliatesPath } from "@ops/routes/routePaths";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export type AffiliateFormValues = Omit<
  AffiliateType,
  "id" | "referralCode" | "createdAt"
>;

export const affiliateFormValidator =
  generateValidationErrorCollector<AffiliateFormValues>({
    addressLine1: [],
    city: [],
    einTaxId: [],
    email: [isRequired, isValidEmail],
    firstName: [isRequired],
    lastName: [isRequired],
    organizationName: [],
    phone: [],
    state: [],
    unitNumber: [],
    zipCode: [],
  });

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};

  & > h1 {
    text-align: center;
  }
  & > form {
    width: 500px;
  }
`;

export const NewAffiliatePage = () => {
  const navigate = useNavigate();
  const flash = useRhFlash();

  const initialValues = Object.freeze<AffiliateFormValues>({
    addressLine1: "",
    city: "",
    einTaxId: "",
    email: "",
    firstName: "",
    lastName: "",
    organizationName: "",
    phone: "",
    state: "",
    unitNumber: "",
    zipCode: "",
  });

  const onSubmit = (affiliateData: AffiliateFormValues) => {
    return affiliateApi
      .create(affiliateData)
      .then(() => {
        navigate(affiliatesPath());
      })
      .catch((error: RhApiError) => {
        if (error.data?.errorCode === AFFILIATE_UNIQUE_EMAIL_ERROR_CODE) {
          return { email: "Affiliate Email must be unique." };
        } else {
          flash.error("Unable to create affiliate. Please try again.");
          return { [FORM_ERROR]: "Something went wrong" };
        }
      });
  };

  return (
    <Container>
      <H1>New Affiliate</H1>
      <Form<AffiliateFormValues>
        initialValues={initialValues}
        validate={affiliateFormValidator}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <AffiliateFields />
            <BoButton type="submit">Create Affiliate</BoButton>
          </form>
        )}
      />
    </Container>
  );
};
