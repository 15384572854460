import { rhapsodyPremiseApi } from "@ops-api/api/rhapsodyPremiseApi";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePremiseAutopayUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rhapsodyPremiseApi.setAutopay,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: PremiseKeys.PREMISE(premiseId),
      });
    },
  });
};
